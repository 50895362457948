/* tslint:disable */
/* eslint-disable */
/**
 * GCS API
 * Global Content Service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ActionAnswerPage
 */
export interface ActionAnswerPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof ActionAnswerPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<ActionAnswerResponse>}
     * @memberof ActionAnswerPage
     */
    'data': Array<ActionAnswerResponse>;
}
/**
 * 
 * @export
 * @interface ActionAnswerPageMeta
 */
export interface ActionAnswerPageMeta {
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'lastPage': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerPageMeta
     */
    'to': number;
}
/**
 * 
 * @export
 * @interface ActionAnswerResponse
 */
export interface ActionAnswerResponse {
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'actionInstanceId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'locationId': number;
    /**
     * 
     * @type {DbActionAnswerValue}
     * @memberof ActionAnswerResponse
     */
    'answer': DbActionAnswerValue;
    /**
     * 
     * @type {boolean}
     * @memberof ActionAnswerResponse
     */
    'completed': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ActionAnswerResponse
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof ActionAnswerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ActionAnswerResponse
     */
    'revertedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'score': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'revertedBy': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'totalScore': number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionAnswerResponse
     */
    'tenantName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'articleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActionAnswerResponse
     */
    'afterDeadline': boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerResponse
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof ActionAnswerResponse
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof ActionAnswerResponse
     */
    'currentUserReactions': Array<EmojiEnum>;
}
/**
 * 
 * @export
 * @interface ActionAnswerSummaryResponseSchema
 */
export interface ActionAnswerSummaryResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'actionId': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'totalAnswers': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'totalAnswersOnTime': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'totalAnswersAfterDeadline': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'averageScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'minScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'maxScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'totalAssignees': number;
    /**
     * 
     * @type {number}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'completionPercentage': number;
    /**
     * 
     * @type {Array<AnswerChoiceSummary>}
     * @memberof ActionAnswerSummaryResponseSchema
     */
    'choices': Array<AnswerChoiceSummary> | null;
}
/**
 * 
 * @export
 * @interface ActionChangeLog
 */
export interface ActionChangeLog {
    /**
     * 
     * @type {number}
     * @memberof ActionChangeLog
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ActionChangeLog
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ActionChangeLog
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ActionChangeLog
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ActionChangeLog
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionChangeLog
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActionChangeLog
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<CategoryChangelog>}
     * @memberof ActionChangeLog
     */
    'categories': Array<CategoryChangelog>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof ActionChangeLog
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof ActionChangeLog
     */
    'renderType': string | null;
}
/**
 * 
 * @export
 * @interface ActionChangeLogSchedule
 */
export interface ActionChangeLogSchedule {
    /**
     * 
     * @type {string}
     * @memberof ActionChangeLogSchedule
     */
    'frequency': ActionChangeLogScheduleFrequencyEnum;
    /**
     * 
     * @type {YearlyScheduleBoundary}
     * @memberof ActionChangeLogSchedule
     */
    'start': YearlyScheduleBoundary;
    /**
     * 
     * @type {YearlyScheduleBoundary}
     * @memberof ActionChangeLogSchedule
     */
    'end': YearlyScheduleBoundary;
    /**
     * 
     * @type {Array<number>}
     * @memberof ActionChangeLogSchedule
     */
    'exceptions': Array<number>;
}

export const ActionChangeLogScheduleFrequencyEnum = {
    Yearly: 'yearly'
} as const;

export type ActionChangeLogScheduleFrequencyEnum = typeof ActionChangeLogScheduleFrequencyEnum[keyof typeof ActionChangeLogScheduleFrequencyEnum];

/**
 * 
 * @export
 * @interface ActionChanged
 */
export interface ActionChanged {
    /**
     * 
     * @type {Array<ActionChangeLog>}
     * @memberof ActionChanged
     */
    'added': Array<ActionChangeLog> | null;
    /**
     * 
     * @type {Array<ActionChangeLog>}
     * @memberof ActionChanged
     */
    'removed': Array<ActionChangeLog> | null;
    /**
     * 
     * @type {Array<ChangedAction>}
     * @memberof ActionChanged
     */
    'changed': Array<ChangedAction>;
}
/**
 * 
 * @export
 * @interface ActionInstance
 */
export interface ActionInstance {
    /**
     * 
     * @type {number}
     * @memberof ActionInstance
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActionInstance
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof ActionInstance
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface ActionInstancePage
 */
export interface ActionInstancePage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof ActionInstancePage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<ActionInstanceResponse>}
     * @memberof ActionInstancePage
     */
    'data': Array<ActionInstanceResponse>;
}
/**
 * 
 * @export
 * @interface ActionInstanceResponse
 */
export interface ActionInstanceResponse {
    /**
     * 
     * @type {number}
     * @memberof ActionInstanceResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActionInstanceResponse
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof ActionInstanceResponse
     */
    'end': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionInstanceResponse
     */
    'completed': boolean;
    /**
     * 
     * @type {number}
     * @memberof ActionInstanceResponse
     */
    'completedBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionInstanceResponse
     */
    'completedAt': string | null;
}
/**
 * @type ActionSchedule
 * @export
 */
export type ActionSchedule = DailySchedule | MonthlySchedule | OnceSchedule | WeeklySchedule | YearlySchedule;

/**
 * 
 * @export
 * @enum {string}
 */

export const ActionTypeEnum = {
    MediaTask: 'media_task',
    SimpleTask: 'simple_task',
    MultiChoice: 'multi_choice',
    OpenQuestionTask: 'open_question_task',
    YesNoTask: 'yes_no_task',
    ScormTask: 'scorm_task'
} as const;

export type ActionTypeEnum = typeof ActionTypeEnum[keyof typeof ActionTypeEnum];


/**
 * 
 * @export
 * @interface AnswerChoiceLanguageSummary
 */
export interface AnswerChoiceLanguageSummary {
    /**
     * 
     * @type {string}
     * @memberof AnswerChoiceLanguageSummary
     */
    'language': string;
    /**
     * 
     * @type {number}
     * @memberof AnswerChoiceLanguageSummary
     */
    'percentage': number;
}
/**
 * 
 * @export
 * @interface AnswerChoiceSummary
 */
export interface AnswerChoiceSummary {
    /**
     * 
     * @type {number}
     * @memberof AnswerChoiceSummary
     */
    'choiceId': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerChoiceSummary
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerChoiceSummary
     */
    'choicePercentage': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnswerChoiceSummary
     */
    'userIds': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerChoiceSummary
     */
    'correct': boolean;
    /**
     * 
     * @type {Array<AnswerChoiceLanguageSummary>}
     * @memberof AnswerChoiceSummary
     */
    'languages': Array<AnswerChoiceLanguageSummary>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AnswerTypeEnum = {
    Numeric: 'numeric',
    Boolean: 'boolean',
    String: 'string'
} as const;

export type AnswerTypeEnum = typeof AnswerTypeEnum[keyof typeof AnswerTypeEnum];


/**
 * 
 * @export
 * @interface ArchiveArticlesRequest
 */
export interface ArchiveArticlesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ArchiveArticlesRequest
     */
    'articleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ArchiveArticlesResponse
 */
export interface ArchiveArticlesResponse extends Array<Article> {
}
/**
 * 
 * @export
 * @interface ArchiveCategoryParams
 */
export interface ArchiveCategoryParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof ArchiveCategoryParams
     */
    'categoryIds': Array<number>;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof Article
     */
    'variants': ArticleVariantsGet;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof Article
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {AudienceIds}
     * @memberof Article
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {LanguageList}
     * @memberof Article
     */
    'languages': LanguageList;
    /**
     * 
     * @type {AudienceResourceList}
     * @memberof Article
     */
    'audiencesResources'?: AudienceResourceList;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'sharingComment'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof Article
     */
    'channel': Channel | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'createdBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'updatedAt': string;
    /**
     * 
     * @type {ShortTenant}
     * @memberof Article
     */
    'originalTenant': ShortTenant | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'seen': number;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'seenByCurrentUser': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    'deadline': string | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'myProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'myMandatoryProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'myScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'myMandatoryScore': number | null;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof Article
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'isTemplate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'isReactingAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'isResharingAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof Article
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof Article
     */
    'currentUserReactions': Array<EmojiEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'publishedInstantly': boolean;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    'objectId': number | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof Article
     */
    'objectType'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleShownAs}
     * @memberof Article
     */
    'shownAs'?: ArticleShownAs;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    'answerSharing': boolean;
    /**
     * 
     * @type {Array<Block>}
     * @memberof Article
     */
    'blocks': Array<Block>;
    /**
     * 
     * @type {ArticleCollaborators}
     * @memberof Article
     */
    'users': ArticleCollaborators;
}


/**
 * 
 * @export
 * @interface ArticleChange
 */
export interface ArticleChange {
    /**
     * 
     * @type {VariantsChange}
     * @memberof ArticleChange
     */
    'variants'?: VariantsChange;
    /**
     * 
     * @type {BlockChanged}
     * @memberof ArticleChange
     */
    'blocks'?: BlockChanged;
    /**
     * 
     * @type {ActionChanged}
     * @memberof ArticleChange
     */
    'actions'?: ActionChanged;
    /**
     * 
     * @type {LanguageChange}
     * @memberof ArticleChange
     */
    'languages'?: LanguageChange;
    /**
     * 
     * @type {AudienceChange}
     * @memberof ArticleChange
     */
    'audiences'?: AudienceChange;
    /**
     * 
     * @type {UsersChange}
     * @memberof ArticleChange
     */
    'users'?: UsersChange;
    /**
     * 
     * @type {ObjectIdChanged}
     * @memberof ArticleChange
     */
    'objectId'?: ObjectIdChanged;
    /**
     * 
     * @type {ObjectTypeChanged}
     * @memberof ArticleChange
     */
    'objectType'?: ObjectTypeChanged;
    /**
     * 
     * @type {PublishAtChange}
     * @memberof ArticleChange
     */
    'publishAt'?: PublishAtChange;
    /**
     * 
     * @type {PublishedInstantlyChange}
     * @memberof ArticleChange
     */
    'publishedInstantly'?: PublishedInstantlyChange;
    /**
     * 
     * @type {StatusChange}
     * @memberof ArticleChange
     */
    'status'?: StatusChange;
    /**
     * 
     * @type {IsTemplateChange}
     * @memberof ArticleChange
     */
    'isTemplate'?: IsTemplateChange;
    /**
     * 
     * @type {ChannelIdChange}
     * @memberof ArticleChange
     */
    'channelId'?: ChannelIdChange;
    /**
     * 
     * @type {CategoryChanged}
     * @memberof ArticleChange
     */
    'categories'?: CategoryChanged;
}
/**
 * 
 * @export
 * @interface ArticleChangeLog
 */
export interface ArticleChangeLog {
    /**
     * 
     * @type {string}
     * @memberof ArticleChangeLog
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof ArticleChangeLog
     */
    'userId': number;
    /**
     * 
     * @type {ArticleChange}
     * @memberof ArticleChangeLog
     */
    'changes': ArticleChange;
}
/**
 * 
 * @export
 * @interface ArticleCollaborator
 */
export interface ArticleCollaborator {
    /**
     * 
     * @type {number}
     * @memberof ArticleCollaborator
     */
    'id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleCollaborator
     */
    'languages': Array<string>;
}
/**
 * 
 * @export
 * @interface ArticleCollaborators
 */
export interface ArticleCollaborators extends Array<ArticleCollaborator> {
}
/**
 * @type ArticleFileGet
 * @export
 */
export type ArticleFileGet = { type: 'external' } & ExternalFile | { type: 'internal' } & InternalFileGet;

/**
 * 
 * @export
 * @interface ArticleLinkBlock
 */
export interface ArticleLinkBlock {
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlock
     */
    'version': ArticleLinkBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkBlock
     */
    'type': ArticleLinkBlockTypeEnum;
    /**
     * 
     * @type {ArticleLinkBlockVariants}
     * @memberof ArticleLinkBlock
     */
    'variants': ArticleLinkBlockVariants;
}

export const ArticleLinkBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type ArticleLinkBlockVersionEnum = typeof ArticleLinkBlockVersionEnum[keyof typeof ArticleLinkBlockVersionEnum];
export const ArticleLinkBlockTypeEnum = {
    ArticleLink: 'article_link'
} as const;

export type ArticleLinkBlockTypeEnum = typeof ArticleLinkBlockTypeEnum[keyof typeof ArticleLinkBlockTypeEnum];

/**
 * 
 * @export
 * @interface ArticleLinkBlockV1
 */
export interface ArticleLinkBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'version': ArticleLinkBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkBlockV1
     */
    'type': ArticleLinkBlockV1TypeEnum;
    /**
     * 
     * @type {ArticleLinkBlockV1Variants}
     * @memberof ArticleLinkBlockV1
     */
    'variants': ArticleLinkBlockV1Variants;
}

export const ArticleLinkBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type ArticleLinkBlockV1VersionEnum = typeof ArticleLinkBlockV1VersionEnum[keyof typeof ArticleLinkBlockV1VersionEnum];
export const ArticleLinkBlockV1TypeEnum = {
    ArticleLink: 'article_link'
} as const;

export type ArticleLinkBlockV1TypeEnum = typeof ArticleLinkBlockV1TypeEnum[keyof typeof ArticleLinkBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface ArticleLinkBlockV1Variants
 */
export interface ArticleLinkBlockV1Variants {
    [key: string]: ArticleLinkBlockV1VariantsValue;

}
/**
 * @type ArticleLinkBlockV1VariantsValue
 * @export
 */
export type ArticleLinkBlockV1VariantsValue = ArticleLinkVariantUnavailable | GetArticleLinkVariant;

/**
 * 
 * @export
 * @interface ArticleLinkBlockVariants
 */
export interface ArticleLinkBlockVariants {
    [key: string]: ArticleLinkVariant;

}
/**
 * 
 * @export
 * @interface ArticleLinkVariant
 */
export interface ArticleLinkVariant {
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkVariant
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ArticleLinkVariantUnavailable
 */
export interface ArticleLinkVariantUnavailable {
    /**
     * 
     * @type {number}
     * @memberof ArticleLinkVariantUnavailable
     */
    'id': number;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof ArticleLinkVariantUnavailable
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleLinkVariantUnavailable
     */
    'message': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleObjectTypeEnum = {
    User: 'user',
    Category: 'category',
    Location: 'location'
} as const;

export type ArticleObjectTypeEnum = typeof ArticleObjectTypeEnum[keyof typeof ArticleObjectTypeEnum];


/**
 * 
 * @export
 * @interface ArticlePage
 */
export interface ArticlePage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof ArticlePage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<ListArticle>}
     * @memberof ArticlePage
     */
    'data': Array<ListArticle>;
}
/**
 * 
 * @export
 * @interface ArticleSharingStatus
 */
export interface ArticleSharingStatus {
    /**
     * 
     * @type {number}
     * @memberof ArticleSharingStatus
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'tenantName': string;
    /**
     * 
     * @type {number}
     * @memberof ArticleSharingStatus
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'tenantTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'tenantLogo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleSharingStatus
     */
    'originalTenantId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'originalTenantName': string | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleSharingStatus
     */
    'originalArticleId': number | null;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof ArticleSharingStatus
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'sharingComment': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleSharingStatus
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleSharingStatus
     */
    'answerSharing': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleShownAs = {
    Article: 'article',
    Checklist: 'checklist',
    Training: 'training'
} as const;

export type ArticleShownAs = typeof ArticleShownAs[keyof typeof ArticleShownAs];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived',
    Inbound: 'inbound',
    Rejected: 'rejected'
} as const;

export type ArticleStatusEnum = typeof ArticleStatusEnum[keyof typeof ArticleStatusEnum];


/**
 * 
 * @export
 * @interface ArticleVariant
 */
export interface ArticleVariant {
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ArticleVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleVariant
     */
    'title': string;
    /**
     * 
     * @type {CoverImageFile}
     * @memberof ArticleVariant
     */
    'coverImage': CoverImageFile;
}


/**
 * 
 * @export
 * @interface ArticleVariantGet
 */
export interface ArticleVariantGet {
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ArticleVariantGet
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleVariantGet
     */
    'title': string;
    /**
     * 
     * @type {CoverImageArticleFileGet}
     * @memberof ArticleVariantGet
     */
    'coverImage': CoverImageArticleFileGet;
}


/**
 * 
 * @export
 * @interface ArticleVariants
 */
export interface ArticleVariants {
    [key: string]: ArticleVariant;

}
/**
 * 
 * @export
 * @interface ArticleVariantsGet
 */
export interface ArticleVariantsGet {
    [key: string]: ArticleVariantGet;

}
/**
 * 
 * @export
 * @interface Audience
 */
export interface Audience {
    /**
     * 
     * @type {number}
     * @memberof Audience
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Audience
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface AudienceChange
 */
export interface AudienceChange {
    /**
     * 
     * @type {AudienceIds}
     * @memberof AudienceChange
     */
    'added': AudienceIds;
    /**
     * 
     * @type {AudienceIds}
     * @memberof AudienceChange
     */
    'removed': AudienceIds;
}
/**
 * 
 * @export
 * @interface AudienceIds
 */
export interface AudienceIds extends Array<number> {
}
/**
 * 
 * @export
 * @interface AudienceResourceList
 */
export interface AudienceResourceList extends Array<Audience> {
}
/**
 * 
 * @export
 * @interface Backlink
 */
export interface Backlink {
    /**
     * 
     * @type {number}
     * @memberof Backlink
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof Backlink
     */
    'variants': ArticleVariantsGet;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof Backlink
     */
    'status': ArticleStatusEnum;
}


/**
 * 
 * @export
 * @interface BacklinkPage
 */
export interface BacklinkPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof BacklinkPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<BacklinkPageDataInner>}
     * @memberof BacklinkPage
     */
    'data': Array<BacklinkPageDataInner>;
}
/**
 * @type BacklinkPageDataInner
 * @export
 */
export type BacklinkPageDataInner = Backlink | UnavailableBacklink;

/**
 * 
 * @export
 * @interface BasicArticleInfo
 */
export interface BasicArticleInfo {
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof BasicArticleInfo
     */
    'variants': ArticleVariantsGet;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof BasicArticleInfo
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {AudienceIds}
     * @memberof BasicArticleInfo
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {LanguageList}
     * @memberof BasicArticleInfo
     */
    'languages': LanguageList;
    /**
     * 
     * @type {AudienceResourceList}
     * @memberof BasicArticleInfo
     */
    'audiencesResources'?: AudienceResourceList;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'sharingComment'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof BasicArticleInfo
     */
    'channel': Channel | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'createdBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'updatedAt': string;
    /**
     * 
     * @type {ShortTenant}
     * @memberof BasicArticleInfo
     */
    'originalTenant': ShortTenant | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'seen': number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'seenByCurrentUser': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BasicArticleInfo
     */
    'deadline': string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'myProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'myMandatoryProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'myScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'myMandatoryScore': number | null;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof BasicArticleInfo
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'isTemplate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'isReactingAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'isResharingAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof BasicArticleInfo
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof BasicArticleInfo
     */
    'currentUserReactions': Array<EmojiEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'publishedInstantly': boolean;
    /**
     * 
     * @type {number}
     * @memberof BasicArticleInfo
     */
    'objectId': number | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof BasicArticleInfo
     */
    'objectType'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleShownAs}
     * @memberof BasicArticleInfo
     */
    'shownAs'?: ArticleShownAs;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicArticleInfo
     */
    'answerSharing': boolean;
}


/**
 * 
 * @export
 * @interface BasicChannelInfo
 */
export interface BasicChannelInfo {
    /**
     * 
     * @type {number}
     * @memberof BasicChannelInfo
     */
    'id': number;
    /**
     * 
     * @type {ChannelTitleTranslation}
     * @memberof BasicChannelInfo
     */
    'title': ChannelTitleTranslation;
    /**
     * 
     * @type {CoverImageChannelFileGet}
     * @memberof BasicChannelInfo
     */
    'coverImage': CoverImageChannelFileGet;
    /**
     * 
     * @type {boolean}
     * @memberof BasicChannelInfo
     */
    'pushNotification': boolean;
    /**
     * 
     * @type {number}
     * @memberof BasicChannelInfo
     */
    'ranking': number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicChannelInfo
     */
    'hideIfEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicChannelInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BasicChannelInfo
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof BasicChannelInfo
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicChannelInfo
     */
    'createdBy': number;
    /**
     * 
     * @type {number}
     * @memberof BasicChannelInfo
     */
    'updatedBy': number;
    /**
     * 
     * @type {ChannelShownAsEnum}
     * @memberof BasicChannelInfo
     */
    'shownAs': ChannelShownAsEnum;
    /**
     * 
     * @type {Array<GetFeedAudience>}
     * @memberof BasicChannelInfo
     */
    'feedAudiences': Array<GetFeedAudience>;
    /**
     * 
     * @type {boolean}
     * @memberof BasicChannelInfo
     */
    'easyAccess': boolean;
}


/**
 * 
 * @export
 * @interface BasicRelevantChannelInfo
 */
export interface BasicRelevantChannelInfo {
    /**
     * 
     * @type {number}
     * @memberof BasicRelevantChannelInfo
     */
    'id': number;
    /**
     * 
     * @type {ChannelTitleTranslation}
     * @memberof BasicRelevantChannelInfo
     */
    'title': ChannelTitleTranslation;
    /**
     * 
     * @type {CoverImageChannelFileGet}
     * @memberof BasicRelevantChannelInfo
     */
    'coverImage': CoverImageChannelFileGet;
    /**
     * 
     * @type {number}
     * @memberof BasicRelevantChannelInfo
     */
    'ranking': number;
    /**
     * 
     * @type {number}
     * @memberof BasicRelevantChannelInfo
     */
    'articleCount': number;
    /**
     * 
     * @type {number}
     * @memberof BasicRelevantChannelInfo
     */
    'seenArticleCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof BasicRelevantChannelInfo
     */
    'isCreator': boolean;
    /**
     * 
     * @type {ChannelShownAsEnum}
     * @memberof BasicRelevantChannelInfo
     */
    'shownAs': ChannelShownAsEnum;
}


/**
 * @type Block
 * @export
 */
export type Block = { type: 'article_link' } & ArticleLinkBlockV1 | { type: 'file' } & FileBlockV1 | { type: 'image' } & ImageBlockV1 | { type: 'media_task' } & MediaTaskBlockV1 | { type: 'multi_choice' } & MultiChoiceBlockV1 | { type: 'multi_file' } & MultiBlockV1 | { type: 'open_question_task' } & OpenQuestionTaskBlockV1 | { type: 'pdf' } & PdfBlockV1 | { type: 'scorm_task' } & ScormTaskBlockV1 | { type: 'simple_task' } & SimpleTaskBlockV1 | { type: 'text' } & TextBlockV1 | { type: 'url' } & UrlBlockV1 | { type: 'video' } & VideoBlockV1 | { type: 'yes_no_task' } & GetYesNoTaskBlockV1;

/**
 * 
 * @export
 * @interface BlockChanged
 */
export interface BlockChanged {
    /**
     * 
     * @type {Array<BlockChangelog>}
     * @memberof BlockChanged
     */
    'added': Array<BlockChangelog> | null;
    /**
     * 
     * @type {Array<BlockChangelog>}
     * @memberof BlockChanged
     */
    'removed': Array<BlockChangelog> | null;
    /**
     * 
     * @type {Array<ChangedBlock>}
     * @memberof BlockChanged
     */
    'changed': Array<ChangedBlock>;
}
/**
 * 
 * @export
 * @interface BlockChangelog
 */
export interface BlockChangelog {
    /**
     * 
     * @type {number}
     * @memberof BlockChangelog
     */
    'id': number;
    /**
     * 
     * @type {BlockVariants}
     * @memberof BlockChangelog
     */
    'variants': BlockVariants;
    /**
     * 
     * @type {number}
     * @memberof BlockChangelog
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof BlockChangelog
     */
    'type': string;
    /**
     * 
     * @type {Array<ChoiceChangelog>}
     * @memberof BlockChangelog
     */
    'choices'?: Array<ChoiceChangelog>;
}
/**
 * 
 * @export
 * @interface BlockVariants
 */
export interface BlockVariants {
    [key: string]: BlockVariantsValue;

}
/**
 * @type BlockVariantsValue
 * @export
 */
export type BlockVariantsValue = ArticleLinkVariant | FileVariant | MediaTaskVariant | MultiChoiceVariant | OpenQuestionTaskVariant | SimpleTaskVariant | TextBlockVariant | UrlVariant;

/**
 * 
 * @export
 * @interface CategoriesPage
 */
export interface CategoriesPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof CategoriesPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof CategoriesPage
     */
    'data': Array<GetCategory>;
}
/**
 * 
 * @export
 * @interface CategoryChanged
 */
export interface CategoryChanged {
    /**
     * 
     * @type {Array<CategoryChangelog>}
     * @memberof CategoryChanged
     */
    'added': Array<CategoryChangelog>;
    /**
     * 
     * @type {Array<CategoryChangelog>}
     * @memberof CategoryChanged
     */
    'removed': Array<CategoryChangelog>;
}
/**
 * 
 * @export
 * @interface CategoryChangelog
 */
export interface CategoryChangelog {
    /**
     * 
     * @type {number}
     * @memberof CategoryChangelog
     */
    'id'?: number;
    /**
     * 
     * @type {CategoryVariants}
     * @memberof CategoryChangelog
     */
    'variants': CategoryVariants;
    /**
     * 
     * @type {CategoryStatusEnum}
     * @memberof CategoryChangelog
     */
    'status': CategoryStatusEnum;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CategoryStatusEnum = {
    Active: 'active',
    Archived: 'archived'
} as const;

export type CategoryStatusEnum = typeof CategoryStatusEnum[keyof typeof CategoryStatusEnum];


/**
 * 
 * @export
 * @interface CategoryVariant
 */
export interface CategoryVariant {
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof CategoryVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryVariant
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryVariant
     */
    'isDefault': boolean;
}


/**
 * 
 * @export
 * @interface CategoryVariants
 */
export interface CategoryVariants {
    [key: string]: CategoryVariant;

}
/**
 * 
 * @export
 * @interface ChangedAction
 */
export interface ChangedAction {
    /**
     * 
     * @type {ActionChangeLog}
     * @memberof ChangedAction
     */
    'new': ActionChangeLog;
    /**
     * 
     * @type {ActionChangeLog}
     * @memberof ChangedAction
     */
    'old': ActionChangeLog;
}
/**
 * 
 * @export
 * @interface ChangedBlock
 */
export interface ChangedBlock {
    /**
     * 
     * @type {BlockChangelog}
     * @memberof ChangedBlock
     */
    'new': BlockChangelog;
    /**
     * 
     * @type {BlockChangelog}
     * @memberof ChangedBlock
     */
    'old': BlockChangelog;
}
/**
 * 
 * @export
 * @interface ChangedLanguage
 */
export interface ChangedLanguage {
    /**
     * 
     * @type {Language}
     * @memberof ChangedLanguage
     */
    'new': Language;
    /**
     * 
     * @type {Language}
     * @memberof ChangedLanguage
     */
    'old': Language;
}
/**
 * 
 * @export
 * @interface ChangedUser
 */
export interface ChangedUser {
    /**
     * 
     * @type {UserChange}
     * @memberof ChangedUser
     */
    'new': UserChange;
    /**
     * 
     * @type {UserChange}
     * @memberof ChangedUser
     */
    'old': UserChange;
}
/**
 * 
 * @export
 * @interface ChangedVariant
 */
export interface ChangedVariant {
    /**
     * 
     * @type {ArticleVariant}
     * @memberof ChangedVariant
     */
    'new': ArticleVariant;
    /**
     * 
     * @type {ArticleVariant}
     * @memberof ChangedVariant
     */
    'old': ArticleVariant;
}
/**
 * 
 * @export
 * @interface ChangedVariants
 */
export interface ChangedVariants {
    [key: string]: ChangedVariant;

}
/**
 * 
 * @export
 * @interface ChangelogChoiceVariants
 */
export interface ChangelogChoiceVariants {
    [key: string]: ChoiceVariant;

}
/**
 * 
 * @export
 * @interface ChangelogPage
 */
export interface ChangelogPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof ChangelogPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<ArticleChangeLog>}
     * @memberof ChangelogPage
     */
    'data': Array<ArticleChangeLog>;
}
/**
 * 
 * @export
 * @interface Channel
 */
export interface Channel {
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    'id': number;
    /**
     * 
     * @type {ChannelTitleTranslation}
     * @memberof Channel
     */
    'title': ChannelTitleTranslation;
    /**
     * 
     * @type {CoverImageChannelFileGet}
     * @memberof Channel
     */
    'coverImage': CoverImageChannelFileGet;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    'pushNotification': boolean;
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    'ranking': number;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    'hideIfEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    'createdBy': number;
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    'updatedBy': number;
    /**
     * 
     * @type {ChannelShownAsEnum}
     * @memberof Channel
     */
    'shownAs': ChannelShownAsEnum;
    /**
     * 
     * @type {Array<GetFeedAudience>}
     * @memberof Channel
     */
    'feedAudiences': Array<GetFeedAudience>;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    'isCreator': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    'easyAccess': boolean;
}


/**
 * 
 * @export
 * @interface ChannelIdChange
 */
export interface ChannelIdChange {
    /**
     * 
     * @type {number}
     * @memberof ChannelIdChange
     */
    'new': number | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelIdChange
     */
    'old': number | null;
}
/**
 * 
 * @export
 * @interface ChannelPage
 */
export interface ChannelPage {
    /**
     * 
     * @type {ChannelPageMeta}
     * @memberof ChannelPage
     */
    'meta': ChannelPageMeta;
    /**
     * 
     * @type {Array<BasicChannelInfo>}
     * @memberof ChannelPage
     */
    'data': Array<BasicChannelInfo>;
}
/**
 * 
 * @export
 * @interface ChannelPageMeta
 */
export interface ChannelPageMeta {
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'lastPage': number;
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof ChannelPageMeta
     */
    'to': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChannelShownAsEnum = {
    Feed: 'feed',
    Cards: 'cards'
} as const;

export type ChannelShownAsEnum = typeof ChannelShownAsEnum[keyof typeof ChannelShownAsEnum];


/**
 * 
 * @export
 * @interface ChannelTitle
 */
export interface ChannelTitle {
    /**
     * 
     * @type {string}
     * @memberof ChannelTitle
     */
    'title': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ChannelTitle
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelTitle
     */
    'isDefault': boolean;
}


/**
 * 
 * @export
 * @interface ChannelTitleTranslation
 */
export interface ChannelTitleTranslation {
    [key: string]: ChannelTitle;

}
/**
 * 
 * @export
 * @interface ChoiceChangelog
 */
export interface ChoiceChangelog {
    /**
     * 
     * @type {number}
     * @memberof ChoiceChangelog
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChoiceChangelog
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof ChoiceChangelog
     */
    'isDefault': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChoiceChangelog
     */
    'correct': boolean | null;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof ChoiceChangelog
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {ChangelogChoiceVariants}
     * @memberof ChoiceChangelog
     */
    'variants': ChangelogChoiceVariants;
}


/**
 * 
 * @export
 * @interface ChoiceSchema
 */
export interface ChoiceSchema {
    /**
     * 
     * @type {number}
     * @memberof ChoiceSchema
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChoiceSchema
     */
    'correct'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChoiceSchema
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChoiceSchema
     */
    'position': number;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof ChoiceSchema
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {ChoiceVariants}
     * @memberof ChoiceSchema
     */
    'variants': ChoiceVariants;
}


/**
 * 
 * @export
 * @interface ChoiceVariant
 */
export interface ChoiceVariant {
    /**
     * 
     * @type {Paragraph}
     * @memberof ChoiceVariant
     */
    'answer'?: Paragraph;
    /**
     * 
     * @type {InternalFile}
     * @memberof ChoiceVariant
     */
    'image'?: InternalFile;
}
/**
 * 
 * @export
 * @interface ChoiceVariants
 */
export interface ChoiceVariants {
    [key: string]: ChoiceVariant;

}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'id': number;
    /**
     * 
     * @type {CommentObjectTypeEnum}
     * @memberof Comment
     */
    'objectType': CommentObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'objectId': number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'parentCommentId'?: number | null;
    /**
     * 
     * @type {CommentVariants}
     * @memberof Comment
     */
    'variants': CommentVariants;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'defaultLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'updatedAt': string | null;
}


/**
 * 
 * @export
 * @interface CommentAndParentsResponse
 */
export interface CommentAndParentsResponse {
    /**
     * 
     * @type {Array<ExtendedComment>}
     * @memberof CommentAndParentsResponse
     */
    'data': Array<ExtendedComment>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommentObjectTypeEnum = {
    Article: 'article',
    ActionAnswer: 'action_answer'
} as const;

export type CommentObjectTypeEnum = typeof CommentObjectTypeEnum[keyof typeof CommentObjectTypeEnum];


/**
 * 
 * @export
 * @interface CommentPage
 */
export interface CommentPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof CommentPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<ExtendedComment>}
     * @memberof CommentPage
     */
    'data': Array<ExtendedComment>;
}
/**
 * 
 * @export
 * @interface CommentVariants
 */
export interface CommentVariants {
    [key: string]: Array<TextVariantItem>;

}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConcatEnum = {
    Prepend: 'prepend',
    Append: 'append'
} as const;

export type ConcatEnum = typeof ConcatEnum[keyof typeof ConcatEnum];


/**
 * 
 * @export
 * @interface ConvertArticleToHtmlRequest
 */
export interface ConvertArticleToHtmlRequest {
    /**
     * 
     * @type {NewArticle}
     * @memberof ConvertArticleToHtmlRequest
     */
    'article': NewArticle;
    /**
     * 
     * @type {string}
     * @memberof ConvertArticleToHtmlRequest
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface ConvertedArticleLinkBlock
 */
export interface ConvertedArticleLinkBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedArticleLinkBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedArticleLinkBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedArticleLinkBlock
     */
    'type': ConvertedArticleLinkBlockTypeEnum;
    /**
     * 
     * @type {ArticleLinkBlockV1Variants}
     * @memberof ConvertedArticleLinkBlock
     */
    'variants': ArticleLinkBlockV1Variants;
}

export const ConvertedArticleLinkBlockTypeEnum = {
    ArticleLink: 'article_link'
} as const;

export type ConvertedArticleLinkBlockTypeEnum = typeof ConvertedArticleLinkBlockTypeEnum[keyof typeof ConvertedArticleLinkBlockTypeEnum];

/**
 * @type ConvertedBlock
 * @export
 */
export type ConvertedBlock = { type: 'article_link' } & ConvertedArticleLinkBlock | { type: 'file' } & ConvertedFileBlock | { type: 'image' } & ConvertedImageBlock | { type: 'media_task' } & ConvertedMediaTaskBlock | { type: 'multi_choice' } & ConvertedMultiChoiceBlock | { type: 'open_question_task' } & ConvertedOpenQuestionTaskBlock | { type: 'pdf' } & ConvertedPdfBlock | { type: 'scorm_task' } & ConvertedScormTaskBlock | { type: 'simple_task' } & ConvertedSimpleTaskBlock | { type: 'text' } & ConvertedTextBlock | { type: 'url' } & ConvertedUrlBlock | { type: 'video' } & ConvertedVideoBlock | { type: 'yes_no_task' } & ConvertedYesNoTaskBlock;

/**
 * 
 * @export
 * @interface ConvertedFileBlock
 */
export interface ConvertedFileBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedFileBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedFileBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedFileBlock
     */
    'type': ConvertedFileBlockTypeEnum;
    /**
     * 
     * @type {FileBlockV1Variants}
     * @memberof ConvertedFileBlock
     */
    'variants': FileBlockV1Variants;
}

export const ConvertedFileBlockTypeEnum = {
    File: 'file'
} as const;

export type ConvertedFileBlockTypeEnum = typeof ConvertedFileBlockTypeEnum[keyof typeof ConvertedFileBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedImageBlock
 */
export interface ConvertedImageBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedImageBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedImageBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedImageBlock
     */
    'type': ConvertedImageBlockTypeEnum;
    /**
     * 
     * @type {ImageBlockV1Variants}
     * @memberof ConvertedImageBlock
     */
    'variants': ImageBlockV1Variants;
}

export const ConvertedImageBlockTypeEnum = {
    Image: 'image'
} as const;

export type ConvertedImageBlockTypeEnum = typeof ConvertedImageBlockTypeEnum[keyof typeof ConvertedImageBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedMediaTaskBlock
 */
export interface ConvertedMediaTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedMediaTaskBlock
     */
    'type': ConvertedMediaTaskBlockTypeEnum;
    /**
     * 
     * @type {MediaTaskBlockV1Variants}
     * @memberof ConvertedMediaTaskBlock
     */
    'variants': MediaTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMediaTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedMediaTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMediaTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedMediaTaskBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedMediaTaskBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedMediaTaskBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMediaTaskBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedMediaTaskBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedMediaTaskBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedMediaTaskBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMediaTaskBlock
     */
    'currentInstanceId': number | null;
}

export const ConvertedMediaTaskBlockTypeEnum = {
    MediaTask: 'media_task'
} as const;

export type ConvertedMediaTaskBlockTypeEnum = typeof ConvertedMediaTaskBlockTypeEnum[keyof typeof ConvertedMediaTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedMultiChoiceBlock
 */
export interface ConvertedMultiChoiceBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedMultiChoiceBlock
     */
    'type': ConvertedMultiChoiceBlockTypeEnum;
    /**
     * 
     * @type {GetMultiChoiceVariants}
     * @memberof ConvertedMultiChoiceBlock
     */
    'variants': GetMultiChoiceVariants;
    /**
     * 
     * @type {Array<ConvertedMultiChoiceBlockChoicesInner>}
     * @memberof ConvertedMultiChoiceBlock
     */
    'choices': Array<ConvertedMultiChoiceBlockChoicesInner>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMultiChoiceBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedMultiChoiceBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMultiChoiceBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedMultiChoiceBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedMultiChoiceBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedMultiChoiceBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMultiChoiceBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedMultiChoiceBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedMultiChoiceBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedMultiChoiceBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlock
     */
    'currentInstanceId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedMultiChoiceBlock
     */
    'renderType'?: ConvertedMultiChoiceBlockRenderTypeEnum;
}

export const ConvertedMultiChoiceBlockTypeEnum = {
    MultiChoice: 'multi_choice'
} as const;

export type ConvertedMultiChoiceBlockTypeEnum = typeof ConvertedMultiChoiceBlockTypeEnum[keyof typeof ConvertedMultiChoiceBlockTypeEnum];
export const ConvertedMultiChoiceBlockRenderTypeEnum = {
    Quiz: 'quiz',
    Poll: 'poll',
    RatingEmoji: 'rating-emoji',
    RatingStars: 'rating-stars',
    RatingSlider: 'rating-slider',
    YesNoNa: 'yes-no-na'
} as const;

export type ConvertedMultiChoiceBlockRenderTypeEnum = typeof ConvertedMultiChoiceBlockRenderTypeEnum[keyof typeof ConvertedMultiChoiceBlockRenderTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedMultiChoiceBlockChoicesInner
 */
export interface ConvertedMultiChoiceBlockChoicesInner {
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMultiChoiceBlockChoicesInner
     */
    'correct': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedMultiChoiceBlockChoicesInner
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConvertedMultiChoiceBlockChoicesInner
     */
    'position': number;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof ConvertedMultiChoiceBlockChoicesInner
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {GetChoiceVariants}
     * @memberof ConvertedMultiChoiceBlockChoicesInner
     */
    'variants': GetChoiceVariants;
}


/**
 * 
 * @export
 * @interface ConvertedOpenQuestionTaskBlock
 */
export interface ConvertedOpenQuestionTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'type': ConvertedOpenQuestionTaskBlockTypeEnum;
    /**
     * 
     * @type {OpenQuestionTaskBlockV1Variants}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'variants': OpenQuestionTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedOpenQuestionTaskBlock
     */
    'currentInstanceId': number | null;
}

export const ConvertedOpenQuestionTaskBlockTypeEnum = {
    OpenQuestionTask: 'open_question_task'
} as const;

export type ConvertedOpenQuestionTaskBlockTypeEnum = typeof ConvertedOpenQuestionTaskBlockTypeEnum[keyof typeof ConvertedOpenQuestionTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedPdfBlock
 */
export interface ConvertedPdfBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedPdfBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedPdfBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedPdfBlock
     */
    'type': ConvertedPdfBlockTypeEnum;
    /**
     * 
     * @type {PdfBlockV1Variants}
     * @memberof ConvertedPdfBlock
     */
    'variants': PdfBlockV1Variants;
}

export const ConvertedPdfBlockTypeEnum = {
    Pdf: 'pdf'
} as const;

export type ConvertedPdfBlockTypeEnum = typeof ConvertedPdfBlockTypeEnum[keyof typeof ConvertedPdfBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedScormTaskBlock
 */
export interface ConvertedScormTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedScormTaskBlock
     */
    'type': ConvertedScormTaskBlockTypeEnum;
    /**
     * 
     * @type {ScormTaskBlockV1Variants}
     * @memberof ConvertedScormTaskBlock
     */
    'variants': ScormTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedScormTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedScormTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedScormTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedScormTaskBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedScormTaskBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedScormTaskBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedScormTaskBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedScormTaskBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedScormTaskBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedScormTaskBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedScormTaskBlock
     */
    'currentInstanceId': number | null;
}

export const ConvertedScormTaskBlockTypeEnum = {
    ScormTask: 'scorm_task'
} as const;

export type ConvertedScormTaskBlockTypeEnum = typeof ConvertedScormTaskBlockTypeEnum[keyof typeof ConvertedScormTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedSimpleTaskBlock
 */
export interface ConvertedSimpleTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedSimpleTaskBlock
     */
    'type': ConvertedSimpleTaskBlockTypeEnum;
    /**
     * 
     * @type {SimpleTaskBlockV1Variants}
     * @memberof ConvertedSimpleTaskBlock
     */
    'variants': SimpleTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedSimpleTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedSimpleTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedSimpleTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedSimpleTaskBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedSimpleTaskBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedSimpleTaskBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedSimpleTaskBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedSimpleTaskBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedSimpleTaskBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedSimpleTaskBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedSimpleTaskBlock
     */
    'currentInstanceId': number | null;
}

export const ConvertedSimpleTaskBlockTypeEnum = {
    SimpleTask: 'simple_task'
} as const;

export type ConvertedSimpleTaskBlockTypeEnum = typeof ConvertedSimpleTaskBlockTypeEnum[keyof typeof ConvertedSimpleTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedTextBlock
 */
export interface ConvertedTextBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedTextBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedTextBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedTextBlock
     */
    'type': ConvertedTextBlockTypeEnum;
    /**
     * 
     * @type {TextBlockV1Variants}
     * @memberof ConvertedTextBlock
     */
    'variants': TextBlockV1Variants;
}

export const ConvertedTextBlockTypeEnum = {
    Text: 'text'
} as const;

export type ConvertedTextBlockTypeEnum = typeof ConvertedTextBlockTypeEnum[keyof typeof ConvertedTextBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedUrlBlock
 */
export interface ConvertedUrlBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedUrlBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedUrlBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedUrlBlock
     */
    'type': ConvertedUrlBlockTypeEnum;
    /**
     * 
     * @type {UrlBlockV1Variants}
     * @memberof ConvertedUrlBlock
     */
    'variants': UrlBlockV1Variants;
}

export const ConvertedUrlBlockTypeEnum = {
    Url: 'url'
} as const;

export type ConvertedUrlBlockTypeEnum = typeof ConvertedUrlBlockTypeEnum[keyof typeof ConvertedUrlBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedVideoBlock
 */
export interface ConvertedVideoBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedVideoBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedVideoBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedVideoBlock
     */
    'type': ConvertedVideoBlockTypeEnum;
    /**
     * 
     * @type {VideoBlockV1Variants}
     * @memberof ConvertedVideoBlock
     */
    'variants': VideoBlockV1Variants;
}

export const ConvertedVideoBlockTypeEnum = {
    Video: 'video'
} as const;

export type ConvertedVideoBlockTypeEnum = typeof ConvertedVideoBlockTypeEnum[keyof typeof ConvertedVideoBlockTypeEnum];

/**
 * 
 * @export
 * @interface ConvertedYesNoTaskBlock
 */
export interface ConvertedYesNoTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ConvertedYesNoTaskBlock
     */
    'type': ConvertedYesNoTaskBlockTypeEnum;
    /**
     * 
     * @type {GetYesNoTaskVariants}
     * @memberof ConvertedYesNoTaskBlock
     */
    'variants': GetYesNoTaskVariants;
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedYesNoTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ConvertedYesNoTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedYesNoTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ConvertedYesNoTaskBlock
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ConvertedYesNoTaskBlock
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ConvertedYesNoTaskBlock
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConvertedYesNoTaskBlock
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedYesNoTaskBlock
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ConvertedYesNoTaskBlock
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ConvertedYesNoTaskBlock
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ConvertedYesNoTaskBlock
     */
    'currentInstanceId': number | null;
}

export const ConvertedYesNoTaskBlockTypeEnum = {
    YesNoTask: 'yes_no_task'
} as const;

export type ConvertedYesNoTaskBlockTypeEnum = typeof ConvertedYesNoTaskBlockTypeEnum[keyof typeof ConvertedYesNoTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface CopyArticleRequest
 */
export interface CopyArticleRequest {
    /**
     * 
     * @type {number}
     * @memberof CopyArticleRequest
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof CopyArticleRequest
     */
    'newTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CopyArticleRequest
     */
    'newDeadline'?: string | null;
    /**
     * 
     * @type {ConcatEnum}
     * @memberof CopyArticleRequest
     */
    'concatTitle'?: ConcatEnum | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyArticleRequest
     */
    'objectIds'?: Array<number>;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof CopyArticleRequest
     */
    'objectType'?: ArticleObjectTypeEnum | null;
}


/**
 * 
 * @export
 * @interface CopyArticleResponse
 */
export interface CopyArticleResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof CopyArticleResponse
     */
    'articleIds': Array<number>;
}
/**
 * @type CoverImageArticleFileGet
 * @export
 */
export type CoverImageArticleFileGet = { type: 'external' } & CoverImageExternalFile | { type: 'internal' } & CoverImageInternalFileGet;

/**
 * @type CoverImageChannelFileGet
 * @export
 */
export type CoverImageChannelFileGet = { type: 'external' } & CoverImageExternalFile | { type: 'internal' } & CoverImageChannelInternalFileGet;

/**
 * 
 * @export
 * @interface CoverImageChannelInternalFileGet
 */
export interface CoverImageChannelInternalFileGet {
    /**
     * 
     * @type {string}
     * @memberof CoverImageChannelInternalFileGet
     */
    'type': CoverImageChannelInternalFileGetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CoverImageChannelInternalFileGet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CoverImageChannelInternalFileGet
     */
    'url': string;
    /**
     * 
     * @type {DirectImageUrls}
     * @memberof CoverImageChannelInternalFileGet
     */
    'directUrls'?: DirectImageUrls;
    /**
     * 
     * @type {string}
     * @memberof CoverImageChannelInternalFileGet
     */
    'id': string;
}

export const CoverImageChannelInternalFileGetTypeEnum = {
    Internal: 'internal'
} as const;

export type CoverImageChannelInternalFileGetTypeEnum = typeof CoverImageChannelInternalFileGetTypeEnum[keyof typeof CoverImageChannelInternalFileGetTypeEnum];

/**
 * 
 * @export
 * @interface CoverImageExternalFile
 */
export interface CoverImageExternalFile {
    /**
     * 
     * @type {string}
     * @memberof CoverImageExternalFile
     */
    'type': CoverImageExternalFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CoverImageExternalFile
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CoverImageExternalFile
     */
    'name': string;
}

export const CoverImageExternalFileTypeEnum = {
    External: 'external'
} as const;

export type CoverImageExternalFileTypeEnum = typeof CoverImageExternalFileTypeEnum[keyof typeof CoverImageExternalFileTypeEnum];

/**
 * @type CoverImageFile
 * @export
 */
export type CoverImageFile = { type: 'external' } & CoverImageExternalFile | { type: 'internal' } & CoverImageInternalFile;

/**
 * 
 * @export
 * @interface CoverImageInternalFile
 */
export interface CoverImageInternalFile {
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFile
     */
    'type': CoverImageInternalFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFile
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CoverImageInternalFile
     */
    'tenantId'?: number | null;
}

export const CoverImageInternalFileTypeEnum = {
    Internal: 'internal'
} as const;

export type CoverImageInternalFileTypeEnum = typeof CoverImageInternalFileTypeEnum[keyof typeof CoverImageInternalFileTypeEnum];

/**
 * 
 * @export
 * @interface CoverImageInternalFileGet
 */
export interface CoverImageInternalFileGet {
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFileGet
     */
    'type': CoverImageInternalFileGetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFileGet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFileGet
     */
    'url': string;
    /**
     * 
     * @type {DirectImageUrls}
     * @memberof CoverImageInternalFileGet
     */
    'directUrls'?: DirectImageUrls;
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFileGet
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CoverImageInternalFileGet
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoverImageInternalFileGet
     */
    'message'?: string | null;
}

export const CoverImageInternalFileGetTypeEnum = {
    Internal: 'internal'
} as const;

export type CoverImageInternalFileGetTypeEnum = typeof CoverImageInternalFileGetTypeEnum[keyof typeof CoverImageInternalFileGetTypeEnum];

/**
 * 
 * @export
 * @interface CreatedFileSlot
 */
export interface CreatedFileSlot {
    /**
     * 
     * @type {string}
     * @memberof CreatedFileSlot
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedFileSlot
     */
    'url': string;
    /**
     * 
     * @type {object}
     * @memberof CreatedFileSlot
     */
    'headers': object;
}
/**
 * 
 * @export
 * @interface CreatedSharingConnection
 */
export interface CreatedSharingConnection {
    /**
     * 
     * @type {number}
     * @memberof CreatedSharingConnection
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CreatedSharingConnection
     */
    'sourceTenant': number;
    /**
     * 
     * @type {number}
     * @memberof CreatedSharingConnection
     */
    'receiverTenant': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatedSharingConnection
     */
    'autoAccept': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatedSharingConnection
     */
    'autoCreatorUserId': number | null;
    /**
     * 
     * @type {number}
     * @memberof CreatedSharingConnection
     */
    'channelId': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatedSharingConnection
     */
    'categoryIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatedSharingConnection
     */
    'viewerAudienceIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatedSharingConnection
     */
    'assigneeAudienceIds': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CreatedSharingConnection
     */
    'defaultAnswerSharing': boolean;
}
/**
 * 
 * @export
 * @interface DailySchedule
 */
export interface DailySchedule {
    /**
     * 
     * @type {string}
     * @memberof DailySchedule
     */
    'frequency': DailyScheduleFrequencyEnum;
    /**
     * 
     * @type {DailyScheduleBoundary}
     * @memberof DailySchedule
     */
    'start': DailyScheduleBoundary;
    /**
     * 
     * @type {DailyScheduleBoundary}
     * @memberof DailySchedule
     */
    'end': DailyScheduleBoundary;
    /**
     * 
     * @type {Array<ScheduleWeekDay>}
     * @memberof DailySchedule
     */
    'exceptions': Array<ScheduleWeekDay>;
}

export const DailyScheduleFrequencyEnum = {
    Daily: 'daily'
} as const;

export type DailyScheduleFrequencyEnum = typeof DailyScheduleFrequencyEnum[keyof typeof DailyScheduleFrequencyEnum];

/**
 * 
 * @export
 * @interface DailyScheduleBoundary
 */
export interface DailyScheduleBoundary {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleBoundary
     */
    'time': string;
}
/**
 * @type DbActionAnswerValue
 * @export
 */
export type DbActionAnswerValue = DbMediaTaskAnswer | DbMultiChoiceAnswer | DbOpenQuestionTaskAnswer | DbYesNoTaskAnswer | string;

/**
 * 
 * @export
 * @interface DbChoiceAnswer
 */
export interface DbChoiceAnswer {
    /**
     * 
     * @type {number}
     * @memberof DbChoiceAnswer
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof DbChoiceAnswer
     */
    'correct'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DbMediaTaskAnswer
 */
export interface DbMediaTaskAnswer extends Array<string> {
}
/**
 * 
 * @export
 * @interface DbMultiChoiceAnswer
 */
export interface DbMultiChoiceAnswer extends Array<DbChoiceAnswer> {
}
/**
 * 
 * @export
 * @interface DbOpenQuestionTaskAnswer
 */
export interface DbOpenQuestionTaskAnswer {
    /**
     * 
     * @type {string}
     * @memberof DbOpenQuestionTaskAnswer
     */
    'type': DbOpenQuestionTaskAnswerTypeEnum;
    /**
     * 
     * @type {Array<TextOrLink>}
     * @memberof DbOpenQuestionTaskAnswer
     */
    'children': Array<TextOrLink>;
}

export const DbOpenQuestionTaskAnswerTypeEnum = {
    Paragraph: 'paragraph'
} as const;

export type DbOpenQuestionTaskAnswerTypeEnum = typeof DbOpenQuestionTaskAnswerTypeEnum[keyof typeof DbOpenQuestionTaskAnswerTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const DbYesNoTaskAnswer = {
    True: 'true',
    False: 'false',
    NA: 'n/a'
} as const;

export type DbYesNoTaskAnswer = typeof DbYesNoTaskAnswer[keyof typeof DbYesNoTaskAnswer];


/**
 * 
 * @export
 * @interface DeleteReactionByEmojiParams
 */
export interface DeleteReactionByEmojiParams {
    /**
     * 
     * @type {ReactionObjectTypeEnum}
     * @memberof DeleteReactionByEmojiParams
     */
    'objectType': ReactionObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DeleteReactionByEmojiParams
     */
    'objectId': number;
    /**
     * 
     * @type {EmojiEnum}
     * @memberof DeleteReactionByEmojiParams
     */
    'emoji': EmojiEnum;
}


/**
 * 
 * @export
 * @interface DeleteSharedArticleRequest
 */
export interface DeleteSharedArticleRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteSharedArticleRequest
     */
    'articleId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteSharedArticleRequest
     */
    'tenantIds': Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteSharedArticleResponse
 */
export interface DeleteSharedArticleResponse {
    /**
     * 
     * @type {Array<ShortTenant>}
     * @memberof DeleteSharedArticleResponse
     */
    'tenants': Array<ShortTenant>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteSharedArticleResponse
     */
    'articles': Array<number>;
}
/**
 * 
 * @export
 * @interface DirectImageUrls
 */
export interface DirectImageUrls {
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectImageUrls
     */
    'original': ImageSize;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectImageUrls
     */
    'small'?: ImageSize;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectImageUrls
     */
    'medium'?: ImageSize;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectImageUrls
     */
    'large'?: ImageSize;
}
/**
 * 
 * @export
 * @interface DirectUrls
 */
export interface DirectUrls {
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectUrls
     */
    'original': ImageSize;
    /**
     * 
     * @type {VideoEncoding}
     * @memberof DirectUrls
     */
    'encodings'?: VideoEncoding;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectUrls
     */
    'small'?: ImageSize;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectUrls
     */
    'medium'?: ImageSize;
    /**
     * 
     * @type {ImageSize}
     * @memberof DirectUrls
     */
    'large'?: ImageSize;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmojiEnum = {
    Reliable: 'reliable',
    Helpful: 'helpful',
    Productive: 'productive',
    Funny: 'funny',
    Creative: 'creative',
    Organized: 'organized',
    Motivating: 'motivating',
    Informative: 'informative',
    Dedicated: 'dedicated',
    Inspiring: 'inspiring',
    Effective: 'effective',
    Collaborative: 'collaborative',
    Insightful: 'insightful',
    Proactive: 'proactive'
} as const;

export type EmojiEnum = typeof EmojiEnum[keyof typeof EmojiEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventEnum = {
    Seen: 'seen'
} as const;

export type EventEnum = typeof EventEnum[keyof typeof EventEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventObjectTypeEnum = {
    Article: 'article'
} as const;

export type EventObjectTypeEnum = typeof EventObjectTypeEnum[keyof typeof EventObjectTypeEnum];


/**
 * 
 * @export
 * @interface ExtendedComment
 */
export interface ExtendedComment {
    /**
     * 
     * @type {number}
     * @memberof ExtendedComment
     */
    'id': number;
    /**
     * 
     * @type {CommentObjectTypeEnum}
     * @memberof ExtendedComment
     */
    'objectType': CommentObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ExtendedComment
     */
    'objectId': number;
    /**
     * 
     * @type {number}
     * @memberof ExtendedComment
     */
    'parentCommentId'?: number | null;
    /**
     * 
     * @type {CommentVariants}
     * @memberof ExtendedComment
     */
    'variants': CommentVariants;
    /**
     * 
     * @type {string}
     * @memberof ExtendedComment
     */
    'defaultLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedComment
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof ExtendedComment
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedComment
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtendedComment
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof ExtendedComment
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof ExtendedComment
     */
    'currentUserReactions': Array<EmojiEnum>;
}


/**
 * 
 * @export
 * @interface ExternalFile
 */
export interface ExternalFile {
    /**
     * 
     * @type {string}
     * @memberof ExternalFile
     */
    'type': ExternalFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExternalFile
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalFile
     */
    'name': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ExternalFile
     */
    'translationStatus': TranslationStatusEnum;
}

export const ExternalFileTypeEnum = {
    External: 'external'
} as const;

export type ExternalFileTypeEnum = typeof ExternalFileTypeEnum[keyof typeof ExternalFileTypeEnum];

/**
 * 
 * @export
 * @interface FeedAudienceNameVariant
 */
export interface FeedAudienceNameVariant {
    /**
     * 
     * @type {string}
     * @memberof FeedAudienceNameVariant
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeedAudienceNameVariant
     */
    'isDefault': boolean;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof FeedAudienceNameVariant
     */
    'translationStatus': TranslationStatusEnum;
}


/**
 * 
 * @export
 * @interface FeedAudienceNameVariants
 */
export interface FeedAudienceNameVariants {
    [key: string]: FeedAudienceNameVariant;

}
/**
 * 
 * @export
 * @interface FeedAudienceUpdate
 */
export interface FeedAudienceUpdate {
    /**
     * 
     * @type {number}
     * @memberof FeedAudienceUpdate
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedAudienceUpdate
     */
    'creatorAudiences': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedAudienceUpdate
     */
    'viewerAudiences': Array<number>;
    /**
     * 
     * @type {FeedAudienceNameVariants}
     * @memberof FeedAudienceUpdate
     */
    'variants'?: FeedAudienceNameVariants;
}
/**
 * 
 * @export
 * @interface FileBlock
 */
export interface FileBlock {
    /**
     * 
     * @type {number}
     * @memberof FileBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof FileBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof FileBlock
     */
    'version': FileBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof FileBlock
     */
    'type': FileBlockTypeEnum;
    /**
     * 
     * @type {FileBlockVariants}
     * @memberof FileBlock
     */
    'variants': FileBlockVariants;
}

export const FileBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type FileBlockVersionEnum = typeof FileBlockVersionEnum[keyof typeof FileBlockVersionEnum];
export const FileBlockTypeEnum = {
    File: 'file'
} as const;

export type FileBlockTypeEnum = typeof FileBlockTypeEnum[keyof typeof FileBlockTypeEnum];

/**
 * 
 * @export
 * @interface FileBlockV1
 */
export interface FileBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'version': FileBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof FileBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof FileBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FileBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FileBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof FileBlockV1
     */
    'type': FileBlockV1TypeEnum;
    /**
     * 
     * @type {FileBlockV1Variants}
     * @memberof FileBlockV1
     */
    'variants': FileBlockV1Variants;
}

export const FileBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type FileBlockV1VersionEnum = typeof FileBlockV1VersionEnum[keyof typeof FileBlockV1VersionEnum];
export const FileBlockV1TypeEnum = {
    File: 'file'
} as const;

export type FileBlockV1TypeEnum = typeof FileBlockV1TypeEnum[keyof typeof FileBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface FileBlockV1Variants
 */
export interface FileBlockV1Variants {
    [key: string]: ArticleFileGet;

}
/**
 * 
 * @export
 * @interface FileBlockVariants
 */
export interface FileBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface FileSlot
 */
export interface FileSlot {
    /**
     * 
     * @type {string}
     * @memberof FileSlot
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FileSlot
     */
    'mimeType': string;
}
/**
 * @type FileVariant
 * @export
 */
export type FileVariant = { type: 'external' } & ExternalFile | { type: 'internal' } & InternalFile;

/**
 * @type GetActionAnswer400Response
 * @export
 */
export type GetActionAnswer400Response = Array<GetActionAnswerSummary400ResponseOneOfInner> | ValidationIssues;

/**
 * @type GetActionAnswerSummary400Response
 * @export
 */
export type GetActionAnswerSummary400Response = Array<GetActionAnswerSummary400ResponseOneOfInner> | ValidationIssues;

/**
 * 
 * @export
 * @interface GetActionAnswerSummary400ResponseOneOfInner
 */
export interface GetActionAnswerSummary400ResponseOneOfInner {
    /**
     * 
     * @type {string}
     * @memberof GetActionAnswerSummary400ResponseOneOfInner
     */
    'message': string;
}
/**
 * @type GetActionInstance400Response
 * @export
 */
export type GetActionInstance400Response = Array<GetActionAnswerSummary400ResponseOneOfInner> | ValidationIssues;

/**
 * @type GetArticle400Response
 * @export
 */
export type GetArticle400Response = Array<GetActionAnswerSummary400ResponseOneOfInner> | ValidationIssues;

/**
 * 
 * @export
 * @interface GetArticleLinkVariant
 */
export interface GetArticleLinkVariant {
    /**
     * 
     * @type {number}
     * @memberof GetArticleLinkVariant
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof GetArticleLinkVariant
     */
    'variants': ArticleVariantsGet;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof GetArticleLinkVariant
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {LanguageList}
     * @memberof GetArticleLinkVariant
     */
    'languages': LanguageList;
}


/**
 * 
 * @export
 * @interface GetCategory
 */
export interface GetCategory {
    /**
     * 
     * @type {number}
     * @memberof GetCategory
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetCategory
     */
    'tenantId': number;
    /**
     * 
     * @type {CategoryVariants}
     * @memberof GetCategory
     */
    'variants': CategoryVariants;
    /**
     * 
     * @type {number}
     * @memberof GetCategory
     */
    'createdBy': number;
    /**
     * 
     * @type {string}
     * @memberof GetCategory
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetCategory
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCategory
     */
    'updatedAt': string;
    /**
     * 
     * @type {CategoryStatusEnum}
     * @memberof GetCategory
     */
    'status': CategoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetCategory
     */
    'archivedAt': string | null;
}


/**
 * 
 * @export
 * @interface GetChoiceSchema
 */
export interface GetChoiceSchema {
    /**
     * 
     * @type {number}
     * @memberof GetChoiceSchema
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetChoiceSchema
     */
    'correct': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetChoiceSchema
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetChoiceSchema
     */
    'position': number;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof GetChoiceSchema
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {GetChoiceVariants}
     * @memberof GetChoiceSchema
     */
    'variants': GetChoiceVariants;
}


/**
 * 
 * @export
 * @interface GetChoiceVariant
 */
export interface GetChoiceVariant {
    /**
     * 
     * @type {Paragraph}
     * @memberof GetChoiceVariant
     */
    'answer'?: Paragraph;
    /**
     * 
     * @type {InternalFileGet}
     * @memberof GetChoiceVariant
     */
    'image'?: InternalFileGet;
}
/**
 * 
 * @export
 * @interface GetChoiceVariants
 */
export interface GetChoiceVariants {
    [key: string]: GetChoiceVariant;

}
/**
 * 
 * @export
 * @interface GetFeedAudience
 */
export interface GetFeedAudience {
    /**
     * 
     * @type {number}
     * @memberof GetFeedAudience
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetFeedAudience
     */
    'channelId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetFeedAudience
     */
    'creatorAudiences': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetFeedAudience
     */
    'viewerAudiences': Array<number>;
    /**
     * 
     * @type {FeedAudienceNameVariants}
     * @memberof GetFeedAudience
     */
    'variants': FeedAudienceNameVariants;
}
/**
 * 
 * @export
 * @interface GetFile200Response
 */
export interface GetFile200Response {
    /**
     * 
     * @type {string}
     * @memberof GetFile200Response
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof GetFile200Response
     */
    'originalUrl'?: string;
    /**
     * 
     * @type {GetFile200ResponseEncodings}
     * @memberof GetFile200Response
     */
    'encodings'?: GetFile200ResponseEncodings;
}
/**
 * 
 * @export
 * @interface GetFile200ResponseEncodings
 */
export interface GetFile200ResponseEncodings {
    /**
     * 
     * @type {string}
     * @memberof GetFile200ResponseEncodings
     */
    'mpd': string;
    /**
     * 
     * @type {string}
     * @memberof GetFile200ResponseEncodings
     */
    'm3u8': string;
    /**
     * 
     * @type {string}
     * @memberof GetFile200ResponseEncodings
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface GetMultiChoiceVariants
 */
export interface GetMultiChoiceVariants {
    [key: string]: MultiChoiceVariant;

}
/**
 * 
 * @export
 * @interface GetScormTaskVariant
 */
export interface GetScormTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'courseLearningStandard'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'courseStatus': GetScormTaskVariantCourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {InternalFileGet}
     * @memberof GetScormTaskVariant
     */
    'image': InternalFileGet;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'jobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'message'?: string;
    /**
     * 
     * @type {InternalFileGet}
     * @memberof GetScormTaskVariant
     */
    'package': InternalFileGet;
    /**
     * 
     * @type {string}
     * @memberof GetScormTaskVariant
     */
    'title'?: string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof GetScormTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
}

export const GetScormTaskVariantCourseStatusEnum = {
    Running: 'RUNNING',
    Complete: 'COMPLETE',
    Error: 'ERROR',
    Timeout: 'TIMEOUT'
} as const;

export type GetScormTaskVariantCourseStatusEnum = typeof GetScormTaskVariantCourseStatusEnum[keyof typeof GetScormTaskVariantCourseStatusEnum];

/**
 * @type GetTranslationGlossaryEntities400Response
 * @export
 */
export type GetTranslationGlossaryEntities400Response = Array<GetActionAnswerSummary400ResponseOneOfInner> | ValidationIssues;

/**
 * @type GetTranslationGlossaryEntity400Response
 * @export
 */
export type GetTranslationGlossaryEntity400Response = Array<GetActionAnswerSummary400ResponseOneOfInner>;

/**
 * 
 * @export
 * @interface GetUserAnswer
 */
export interface GetUserAnswer {
    /**
     * 
     * @type {number}
     * @memberof GetUserAnswer
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetUserAnswer
     */
    'actionInstanceId': number | null;
    /**
     * 
     * @type {DbActionAnswerValue}
     * @memberof GetUserAnswer
     */
    'answer': DbActionAnswerValue;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserAnswer
     */
    'completed': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserAnswer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserAnswer
     */
    'revertedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserAnswer
     */
    'revertedBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserAnswer
     */
    'language': string;
    /**
     * 
     * @type {number}
     * @memberof GetUserAnswer
     */
    'score': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserAnswer
     */
    'completedBy': number;
}
/**
 * 
 * @export
 * @interface GetUserAnswers
 */
export interface GetUserAnswers extends Array<GetUserAnswer> {
}
/**
 * 
 * @export
 * @interface GetYesNoTaskBlockV1
 */
export interface GetYesNoTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'version': GetYesNoTaskBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof GetYesNoTaskBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetYesNoTaskBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetYesNoTaskBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetYesNoTaskBlockV1
     */
    'type': GetYesNoTaskBlockV1TypeEnum;
    /**
     * 
     * @type {GetYesNoTaskVariants}
     * @memberof GetYesNoTaskBlockV1
     */
    'variants': GetYesNoTaskVariants;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetYesNoTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof GetYesNoTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetYesNoTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof GetYesNoTaskBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof GetYesNoTaskBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof GetYesNoTaskBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetYesNoTaskBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof GetYesNoTaskBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof GetYesNoTaskBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof GetYesNoTaskBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof GetYesNoTaskBlockV1
     */
    'currentInstanceId': number | null;
}

export const GetYesNoTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type GetYesNoTaskBlockV1VersionEnum = typeof GetYesNoTaskBlockV1VersionEnum[keyof typeof GetYesNoTaskBlockV1VersionEnum];
export const GetYesNoTaskBlockV1TypeEnum = {
    YesNoTask: 'yes_no_task'
} as const;

export type GetYesNoTaskBlockV1TypeEnum = typeof GetYesNoTaskBlockV1TypeEnum[keyof typeof GetYesNoTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface GetYesNoTaskVariants
 */
export interface GetYesNoTaskVariants {
    [key: string]: YesNoTaskVariant;

}
/**
 * @type GlosarySyncResult
 * @export
 */
export type GlosarySyncResult = GlosarySyncResultOneOf | GlosarySyncResultOneOf1;

/**
 * 
 * @export
 * @interface GlosarySyncResultOneOf
 */
export interface GlosarySyncResultOneOf {
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf
     */
    'sourceLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf
     */
    'targetLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf
     */
    'system': string;
    /**
     * 
     * @type {boolean}
     * @memberof GlosarySyncResultOneOf
     */
    'success': boolean;
    /**
     * 
     * @type {number}
     * @memberof GlosarySyncResultOneOf
     */
    'entryCount'?: number;
}
/**
 * 
 * @export
 * @interface GlosarySyncResultOneOf1
 */
export interface GlosarySyncResultOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf1
     */
    'sourceLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf1
     */
    'targetLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf1
     */
    'system': string;
    /**
     * 
     * @type {boolean}
     * @memberof GlosarySyncResultOneOf1
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof GlosarySyncResultOneOf1
     */
    'errorMessage': string;
}
/**
 * 
 * @export
 * @interface Heading
 */
export interface Heading {
    /**
     * 
     * @type {string}
     * @memberof Heading
     */
    'type': HeadingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Heading
     */
    'level': number;
    /**
     * 
     * @type {Array<TextOrLink>}
     * @memberof Heading
     */
    'children': Array<TextOrLink>;
}

export const HeadingTypeEnum = {
    Heading: 'heading'
} as const;

export type HeadingTypeEnum = typeof HeadingTypeEnum[keyof typeof HeadingTypeEnum];

/**
 * 
 * @export
 * @interface ImageBlock
 */
export interface ImageBlock {
    /**
     * 
     * @type {number}
     * @memberof ImageBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ImageBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ImageBlock
     */
    'version': ImageBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof ImageBlock
     */
    'type': ImageBlockTypeEnum;
    /**
     * 
     * @type {ImageBlockVariants}
     * @memberof ImageBlock
     */
    'variants': ImageBlockVariants;
}

export const ImageBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type ImageBlockVersionEnum = typeof ImageBlockVersionEnum[keyof typeof ImageBlockVersionEnum];
export const ImageBlockTypeEnum = {
    Image: 'image'
} as const;

export type ImageBlockTypeEnum = typeof ImageBlockTypeEnum[keyof typeof ImageBlockTypeEnum];

/**
 * 
 * @export
 * @interface ImageBlockV1
 */
export interface ImageBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'version': ImageBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof ImageBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof ImageBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ImageBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ImageBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageBlockV1
     */
    'type': ImageBlockV1TypeEnum;
    /**
     * 
     * @type {ImageBlockV1Variants}
     * @memberof ImageBlockV1
     */
    'variants': ImageBlockV1Variants;
}

export const ImageBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type ImageBlockV1VersionEnum = typeof ImageBlockV1VersionEnum[keyof typeof ImageBlockV1VersionEnum];
export const ImageBlockV1TypeEnum = {
    Image: 'image'
} as const;

export type ImageBlockV1TypeEnum = typeof ImageBlockV1TypeEnum[keyof typeof ImageBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface ImageBlockV1Variants
 */
export interface ImageBlockV1Variants {
    [key: string]: ArticleFileGet;

}
/**
 * 
 * @export
 * @interface ImageBlockVariants
 */
export interface ImageBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface ImageSize
 */
export interface ImageSize {
    /**
     * 
     * @type {string}
     * @memberof ImageSize
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImageSize
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ImageSize
     */
    'mimeType': string;
}
/**
 * 
 * @export
 * @interface ImportBody
 */
export interface ImportBody {
    /**
     * 
     * @type {string}
     * @memberof ImportBody
     */
    'jobId': string;
    /**
     * 
     * @type {string}
     * @memberof ImportBody
     */
    'status': ImportBodyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportBody
     */
    'message': string;
    /**
     * 
     * @type {ImportJobResult}
     * @memberof ImportBody
     */
    'importResult': ImportJobResult;
}

export const ImportBodyStatusEnum = {
    Running: 'RUNNING',
    Complete: 'COMPLETE',
    Error: 'ERROR',
    Timeout: 'TIMEOUT'
} as const;

export type ImportBodyStatusEnum = typeof ImportBodyStatusEnum[keyof typeof ImportBodyStatusEnum];

/**
 * 
 * @export
 * @interface ImportJobResult
 */
export interface ImportJobResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportJobResult
     */
    'parserWarnings': Array<string>;
    /**
     * 
     * @type {ImportJobResultCourse}
     * @memberof ImportJobResult
     */
    'course': ImportJobResultCourse;
}
/**
 * 
 * @export
 * @interface ImportJobResultCourse
 */
export interface ImportJobResultCourse {
    /**
     * 
     * @type {string}
     * @memberof ImportJobResultCourse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportJobResultCourse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportJobResultCourse
     */
    'updated'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportJobResultCourse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportJobResultCourse
     */
    'courseLearningStandard'?: string;
}
/**
 * 
 * @export
 * @interface ImportJobStatusResponse
 */
export interface ImportJobStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportJobStatusResponse
     */
    'tenantName': string;
    /**
     * 
     * @type {ImportBody}
     * @memberof ImportJobStatusResponse
     */
    'body': ImportBody;
}
/**
 * 
 * @export
 * @interface InputArticleLanguage
 */
export interface InputArticleLanguage {
    /**
     * 
     * @type {string}
     * @memberof InputArticleLanguage
     */
    'language': string;
    /**
     * 
     * @type {boolean}
     * @memberof InputArticleLanguage
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface InputArticleLanguageList
 */
export interface InputArticleLanguageList extends Array<InputArticleLanguage> {
}
/**
 * 
 * @export
 * @interface InternalFile
 */
export interface InternalFile {
    /**
     * 
     * @type {string}
     * @memberof InternalFile
     */
    'type': InternalFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InternalFile
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof InternalFile
     */
    'tenantId'?: number | null;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof InternalFile
     */
    'translationStatus': TranslationStatusEnum;
}

export const InternalFileTypeEnum = {
    Internal: 'internal'
} as const;

export type InternalFileTypeEnum = typeof InternalFileTypeEnum[keyof typeof InternalFileTypeEnum];

/**
 * 
 * @export
 * @interface InternalFileGet
 */
export interface InternalFileGet {
    /**
     * 
     * @type {string}
     * @memberof InternalFileGet
     */
    'type': InternalFileGetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InternalFileGet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InternalFileGet
     */
    'url': string;
    /**
     * 
     * @type {DirectUrls}
     * @memberof InternalFileGet
     */
    'directUrls'?: DirectUrls;
    /**
     * 
     * @type {string}
     * @memberof InternalFileGet
     */
    'id': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof InternalFileGet
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InternalFileGet
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof InternalFileGet
     */
    'message'?: string | null;
}

export const InternalFileGetTypeEnum = {
    Internal: 'internal'
} as const;

export type InternalFileGetTypeEnum = typeof InternalFileGetTypeEnum[keyof typeof InternalFileGetTypeEnum];

/**
 * 
 * @export
 * @interface IsTemplateChange
 */
export interface IsTemplateChange {
    /**
     * 
     * @type {boolean}
     * @memberof IsTemplateChange
     */
    'new': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IsTemplateChange
     */
    'old': boolean;
}
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    'language': string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface LanguageChange
 */
export interface LanguageChange {
    /**
     * 
     * @type {LanguageList}
     * @memberof LanguageChange
     */
    'added': LanguageList;
    /**
     * 
     * @type {LanguageList}
     * @memberof LanguageChange
     */
    'removed': LanguageList;
    /**
     * 
     * @type {Array<ChangedLanguage>}
     * @memberof LanguageChange
     */
    'changed': Array<ChangedLanguage>;
}
/**
 * 
 * @export
 * @interface LanguageList
 */
export interface LanguageList extends Array<Language> {
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'type': LinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'url': string;
    /**
     * 
     * @type {Array<Text>}
     * @memberof Link
     */
    'children': Array<Text>;
}

export const LinkTypeEnum = {
    Link: 'link'
} as const;

export type LinkTypeEnum = typeof LinkTypeEnum[keyof typeof LinkTypeEnum];

/**
 * 
 * @export
 * @interface List
 */
export interface List {
    /**
     * 
     * @type {string}
     * @memberof List
     */
    'type': ListTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof List
     */
    'listType': ListListTypeEnum;
    /**
     * 
     * @type {Array<ListItem>}
     * @memberof List
     */
    'children': Array<ListItem>;
}

export const ListTypeEnum = {
    List: 'list'
} as const;

export type ListTypeEnum = typeof ListTypeEnum[keyof typeof ListTypeEnum];
export const ListListTypeEnum = {
    Bullet: 'bullet',
    Number: 'number'
} as const;

export type ListListTypeEnum = typeof ListListTypeEnum[keyof typeof ListListTypeEnum];

/**
 * 
 * @export
 * @interface ListArticle
 */
export interface ListArticle {
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof ListArticle
     */
    'variants': ArticleVariantsGet;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof ListArticle
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ListArticle
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {LanguageList}
     * @memberof ListArticle
     */
    'languages': LanguageList;
    /**
     * 
     * @type {AudienceResourceList}
     * @memberof ListArticle
     */
    'audiencesResources'?: AudienceResourceList;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'sharingComment'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof ListArticle
     */
    'channel': Channel | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'createdBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'updatedAt': string;
    /**
     * 
     * @type {ShortTenant}
     * @memberof ListArticle
     */
    'originalTenant': ShortTenant | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'seen': number;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'seenByCurrentUser': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'deadline': string | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'myProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'myMandatoryProgress': number | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'myScore': number | null;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'myMandatoryScore': number | null;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ListArticle
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'isTemplate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'isReactingAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'isResharingAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof ListArticle
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof ListArticle
     */
    'currentUserReactions': Array<EmojiEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'publishedInstantly': boolean;
    /**
     * 
     * @type {number}
     * @memberof ListArticle
     */
    'objectId': number | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof ListArticle
     */
    'objectType'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleShownAs}
     * @memberof ListArticle
     */
    'shownAs'?: ArticleShownAs;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'answerSharing': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ListArticle
     */
    'users': Array<number>;
    /**
     * 
     * @type {Array<Block>}
     * @memberof ListArticle
     */
    'blocks'?: Array<Block>;
    /**
     * 
     * @type {boolean}
     * @memberof ListArticle
     */
    'isRecurring'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListArticle
     */
    'lastSeenAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ListItem
 */
export interface ListItem {
    /**
     * 
     * @type {string}
     * @memberof ListItem
     */
    'type': ListItemTypeEnum;
    /**
     * 
     * @type {Array<TextOrLink>}
     * @memberof ListItem
     */
    'children': Array<TextOrLink>;
}

export const ListItemTypeEnum = {
    ListItem: 'list-item'
} as const;

export type ListItemTypeEnum = typeof ListItemTypeEnum[keyof typeof ListItemTypeEnum];

/**
 * 
 * @export
 * @interface ListedTranslationGlossaryEntities
 */
export interface ListedTranslationGlossaryEntities {
    /**
     * 
     * @type {Array<RetrievedGlossaryEntitySchema>}
     * @memberof ListedTranslationGlossaryEntities
     */
    'entities': Array<RetrievedGlossaryEntitySchema>;
    /**
     * 
     * @type {string}
     * @memberof ListedTranslationGlossaryEntities
     */
    'continuationToken'?: string;
}
/**
 * 
 * @export
 * @interface MediaTaskBlock
 */
export interface MediaTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'version': MediaTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlock
     */
    'type': MediaTaskBlockTypeEnum;
    /**
     * 
     * @type {MediaTaskBlockVariants}
     * @memberof MediaTaskBlock
     */
    'variants': MediaTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof MediaTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MediaTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof MediaTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlock
     */
    'deadline'?: string | null;
}

export const MediaTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type MediaTaskBlockVersionEnum = typeof MediaTaskBlockVersionEnum[keyof typeof MediaTaskBlockVersionEnum];
export const MediaTaskBlockTypeEnum = {
    MediaTask: 'media_task'
} as const;

export type MediaTaskBlockTypeEnum = typeof MediaTaskBlockTypeEnum[keyof typeof MediaTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface MediaTaskBlockV1
 */
export interface MediaTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'version': MediaTaskBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlockV1
     */
    'type': MediaTaskBlockV1TypeEnum;
    /**
     * 
     * @type {MediaTaskBlockV1Variants}
     * @memberof MediaTaskBlockV1
     */
    'variants': MediaTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof MediaTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MediaTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof MediaTaskBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof MediaTaskBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MediaTaskBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof MediaTaskBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof MediaTaskBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof MediaTaskBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof MediaTaskBlockV1
     */
    'currentInstanceId': number | null;
}

export const MediaTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type MediaTaskBlockV1VersionEnum = typeof MediaTaskBlockV1VersionEnum[keyof typeof MediaTaskBlockV1VersionEnum];
export const MediaTaskBlockV1TypeEnum = {
    MediaTask: 'media_task'
} as const;

export type MediaTaskBlockV1TypeEnum = typeof MediaTaskBlockV1TypeEnum[keyof typeof MediaTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface MediaTaskBlockV1Variants
 */
export interface MediaTaskBlockV1Variants {
    [key: string]: MediaTaskVariant;

}
/**
 * 
 * @export
 * @interface MediaTaskBlockVariants
 */
export interface MediaTaskBlockVariants {
    [key: string]: MediaTaskVariant;

}
/**
 * 
 * @export
 * @interface MediaTaskVariant
 */
export interface MediaTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof MediaTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof MediaTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MediaTaskVariant
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface MonthlySchedule
 */
export interface MonthlySchedule {
    /**
     * 
     * @type {string}
     * @memberof MonthlySchedule
     */
    'frequency': MonthlyScheduleFrequencyEnum;
    /**
     * 
     * @type {MonthlyScheduleBoundary}
     * @memberof MonthlySchedule
     */
    'start': MonthlyScheduleBoundary;
    /**
     * 
     * @type {MonthlyScheduleBoundary}
     * @memberof MonthlySchedule
     */
    'end': MonthlyScheduleBoundary;
    /**
     * 
     * @type {Array<ScheduleMonth>}
     * @memberof MonthlySchedule
     */
    'exceptions': Array<ScheduleMonth>;
}

export const MonthlyScheduleFrequencyEnum = {
    Monthly: 'monthly'
} as const;

export type MonthlyScheduleFrequencyEnum = typeof MonthlyScheduleFrequencyEnum[keyof typeof MonthlyScheduleFrequencyEnum];

/**
 * 
 * @export
 * @interface MonthlyScheduleBoundary
 */
export interface MonthlyScheduleBoundary {
    /**
     * 
     * @type {string}
     * @memberof MonthlyScheduleBoundary
     */
    'time': string;
    /**
     * 
     * @type {ScheduleMonthDay}
     * @memberof MonthlyScheduleBoundary
     */
    'day': ScheduleMonthDay;
}
/**
 * 
 * @export
 * @interface MultiBlockV1
 */
export interface MultiBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'version': MultiBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof MultiBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof MultiBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MultiBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MultiBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof MultiBlockV1
     */
    'type': MultiBlockV1TypeEnum;
    /**
     * 
     * @type {MultiFileBlockV1Variants}
     * @memberof MultiBlockV1
     */
    'variants': MultiFileBlockV1Variants;
}

export const MultiBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type MultiBlockV1VersionEnum = typeof MultiBlockV1VersionEnum[keyof typeof MultiBlockV1VersionEnum];
export const MultiBlockV1TypeEnum = {
    MultiFile: 'multi_file'
} as const;

export type MultiBlockV1TypeEnum = typeof MultiBlockV1TypeEnum[keyof typeof MultiBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface MultiChoiceBlock
 */
export interface MultiChoiceBlock {
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'version': MultiChoiceBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlock
     */
    'type': MultiChoiceBlockTypeEnum;
    /**
     * 
     * @type {MultiChoiceVariants}
     * @memberof MultiChoiceBlock
     */
    'variants': MultiChoiceVariants;
    /**
     * 
     * @type {Array<ChoiceSchema>}
     * @memberof MultiChoiceBlock
     */
    'choices': Array<ChoiceSchema>;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiChoiceBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof MultiChoiceBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiChoiceBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MultiChoiceBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof MultiChoiceBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlock
     */
    'deadline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlock
     */
    'renderType'?: MultiChoiceBlockRenderTypeEnum;
}

export const MultiChoiceBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type MultiChoiceBlockVersionEnum = typeof MultiChoiceBlockVersionEnum[keyof typeof MultiChoiceBlockVersionEnum];
export const MultiChoiceBlockTypeEnum = {
    MultiChoice: 'multi_choice'
} as const;

export type MultiChoiceBlockTypeEnum = typeof MultiChoiceBlockTypeEnum[keyof typeof MultiChoiceBlockTypeEnum];
export const MultiChoiceBlockRenderTypeEnum = {
    Quiz: 'quiz',
    Poll: 'poll',
    RatingEmoji: 'rating-emoji',
    RatingStars: 'rating-stars',
    RatingSlider: 'rating-slider',
    YesNoNa: 'yes-no-na'
} as const;

export type MultiChoiceBlockRenderTypeEnum = typeof MultiChoiceBlockRenderTypeEnum[keyof typeof MultiChoiceBlockRenderTypeEnum];

/**
 * 
 * @export
 * @interface MultiChoiceBlockV1
 */
export interface MultiChoiceBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'version': MultiChoiceBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'type': MultiChoiceBlockV1TypeEnum;
    /**
     * 
     * @type {GetMultiChoiceVariants}
     * @memberof MultiChoiceBlockV1
     */
    'variants': GetMultiChoiceVariants;
    /**
     * 
     * @type {Array<GetChoiceSchema>}
     * @memberof MultiChoiceBlockV1
     */
    'choices': Array<GetChoiceSchema>;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiChoiceBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof MultiChoiceBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiChoiceBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof MultiChoiceBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof MultiChoiceBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MultiChoiceBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof MultiChoiceBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof MultiChoiceBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof MultiChoiceBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof MultiChoiceBlockV1
     */
    'currentInstanceId': number | null;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceBlockV1
     */
    'renderType'?: MultiChoiceBlockV1RenderTypeEnum;
}

export const MultiChoiceBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type MultiChoiceBlockV1VersionEnum = typeof MultiChoiceBlockV1VersionEnum[keyof typeof MultiChoiceBlockV1VersionEnum];
export const MultiChoiceBlockV1TypeEnum = {
    MultiChoice: 'multi_choice'
} as const;

export type MultiChoiceBlockV1TypeEnum = typeof MultiChoiceBlockV1TypeEnum[keyof typeof MultiChoiceBlockV1TypeEnum];
export const MultiChoiceBlockV1RenderTypeEnum = {
    Quiz: 'quiz',
    Poll: 'poll',
    RatingEmoji: 'rating-emoji',
    RatingStars: 'rating-stars',
    RatingSlider: 'rating-slider',
    YesNoNa: 'yes-no-na'
} as const;

export type MultiChoiceBlockV1RenderTypeEnum = typeof MultiChoiceBlockV1RenderTypeEnum[keyof typeof MultiChoiceBlockV1RenderTypeEnum];

/**
 * 
 * @export
 * @interface MultiChoiceVariant
 */
export interface MultiChoiceVariant {
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof MultiChoiceVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceVariant
     */
    'explanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiChoiceVariant
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface MultiChoiceVariants
 */
export interface MultiChoiceVariants {
    [key: string]: MultiChoiceVariant;

}
/**
 * 
 * @export
 * @interface MultiFileBlock
 */
export interface MultiFileBlock {
    /**
     * 
     * @type {number}
     * @memberof MultiFileBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiFileBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof MultiFileBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof MultiFileBlock
     */
    'version': MultiFileBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof MultiFileBlock
     */
    'type': MultiFileBlockTypeEnum;
    /**
     * 
     * @type {MultiFileVariants}
     * @memberof MultiFileBlock
     */
    'variants': MultiFileVariants;
}

export const MultiFileBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type MultiFileBlockVersionEnum = typeof MultiFileBlockVersionEnum[keyof typeof MultiFileBlockVersionEnum];
export const MultiFileBlockTypeEnum = {
    MultiFile: 'multi_file'
} as const;

export type MultiFileBlockTypeEnum = typeof MultiFileBlockTypeEnum[keyof typeof MultiFileBlockTypeEnum];

/**
 * 
 * @export
 * @interface MultiFileBlockV1Variants
 */
export interface MultiFileBlockV1Variants {
    [key: string]: MultiFileInternalVariants;

}
/**
 * 
 * @export
 * @interface MultiFileInternalVariants
 */
export interface MultiFileInternalVariants {
    /**
     * 
     * @type {Array<ArticleFileGet>}
     * @memberof MultiFileInternalVariants
     */
    'files': Array<ArticleFileGet>;
    /**
     * 
     * @type {string}
     * @memberof MultiFileInternalVariants
     */
    'renderType': MultiFileInternalVariantsRenderTypeEnum;
}

export const MultiFileInternalVariantsRenderTypeEnum = {
    Image: 'image',
    Pdf: 'pdf',
    File: 'file',
    Video: 'video'
} as const;

export type MultiFileInternalVariantsRenderTypeEnum = typeof MultiFileInternalVariantsRenderTypeEnum[keyof typeof MultiFileInternalVariantsRenderTypeEnum];

/**
 * 
 * @export
 * @interface MultiFileVariant
 */
export interface MultiFileVariant {
    /**
     * 
     * @type {Array<FileVariant>}
     * @memberof MultiFileVariant
     */
    'files': Array<FileVariant>;
    /**
     * 
     * @type {string}
     * @memberof MultiFileVariant
     */
    'renderType': MultiFileVariantRenderTypeEnum;
}

export const MultiFileVariantRenderTypeEnum = {
    Image: 'image',
    Pdf: 'pdf',
    File: 'file',
    Video: 'video'
} as const;

export type MultiFileVariantRenderTypeEnum = typeof MultiFileVariantRenderTypeEnum[keyof typeof MultiFileVariantRenderTypeEnum];

/**
 * 
 * @export
 * @interface MultiFileVariants
 */
export interface MultiFileVariants {
    [key: string]: MultiFileVariant;

}
/**
 * 
 * @export
 * @interface NewArticle
 */
export interface NewArticle {
    /**
     * 
     * @type {ArticleVariants}
     * @memberof NewArticle
     */
    'variants': ArticleVariants;
    /**
     * 
     * @type {NewArticleStatus}
     * @memberof NewArticle
     */
    'status': NewArticleStatus;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof NewArticle
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {NewBlockList}
     * @memberof NewArticle
     */
    'blocks': NewBlockList;
    /**
     * 
     * @type {AudienceIds}
     * @memberof NewArticle
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {ArticleCollaborators}
     * @memberof NewArticle
     */
    'users': ArticleCollaborators;
    /**
     * 
     * @type {InputArticleLanguageList}
     * @memberof NewArticle
     */
    'languages': InputArticleLanguageList;
    /**
     * 
     * @type {number}
     * @memberof NewArticle
     */
    'channelId': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewArticle
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    'isReactingAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    'publishedInstantly'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewArticle
     */
    'objectId'?: number | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof NewArticle
     */
    'objectType'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleShownAs}
     * @memberof NewArticle
     */
    'shownAs'?: ArticleShownAs;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof NewArticle
     */
    'schedule'?: ActionSchedule;
    /**
     * 
     * @type {boolean}
     * @memberof NewArticle
     */
    'answerSharing'?: boolean;
}


/**
 * 
 * @export
 * @interface NewArticleLinkBlock
 */
export interface NewArticleLinkBlock {
    /**
     * 
     * @type {number}
     * @memberof NewArticleLinkBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewArticleLinkBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewArticleLinkBlock
     */
    'version': NewArticleLinkBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewArticleLinkBlock
     */
    'type': NewArticleLinkBlockTypeEnum;
    /**
     * 
     * @type {NewArticleLinkBlockVariants}
     * @memberof NewArticleLinkBlock
     */
    'variants': NewArticleLinkBlockVariants;
}

export const NewArticleLinkBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewArticleLinkBlockVersionEnum = typeof NewArticleLinkBlockVersionEnum[keyof typeof NewArticleLinkBlockVersionEnum];
export const NewArticleLinkBlockTypeEnum = {
    ArticleLink: 'article_link'
} as const;

export type NewArticleLinkBlockTypeEnum = typeof NewArticleLinkBlockTypeEnum[keyof typeof NewArticleLinkBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewArticleLinkBlockVariants
 */
export interface NewArticleLinkBlockVariants {
    [key: string]: ArticleLinkVariant;

}
/**
 * 
 * @export
 * @enum {string}
 */

export const NewArticleStatus = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived',
    Inbound: 'inbound'
} as const;

export type NewArticleStatus = typeof NewArticleStatus[keyof typeof NewArticleStatus];


/**
 * @type NewBlock
 * @export
 */
export type NewBlock = { type: 'article_link' } & NewArticleLinkBlock | { type: 'file' } & NewFileBlock | { type: 'image' } & NewImageBlock | { type: 'media_task' } & NewMediaTaskBlock | { type: 'multi_choice' } & NewMultiChoiceBlock | { type: 'multi_file' } & NewMultiFileBlock | { type: 'open_question_task' } & NewOpenQuestionTaskBlock | { type: 'pdf' } & NewPdfBlock | { type: 'scorm_task' } & PostScormTaskBlockV1 | { type: 'simple_task' } & NewSimpleTaskBlock | { type: 'text' } & NewTextBlock | { type: 'url' } & NewUrlBlock | { type: 'video' } & NewVideoBlock | { type: 'yes_no_task' } & PostYesNoTaskBlockV1;

/**
 * 
 * @export
 * @interface NewBlockList
 */
export interface NewBlockList extends Array<NewBlock> {
}
/**
 * 
 * @export
 * @interface NewCategory
 */
export interface NewCategory {
    /**
     * 
     * @type {CategoryVariants}
     * @memberof NewCategory
     */
    'variants': CategoryVariants;
}
/**
 * 
 * @export
 * @interface NewChannel
 */
export interface NewChannel {
    /**
     * 
     * @type {ChannelTitleTranslation}
     * @memberof NewChannel
     */
    'title': ChannelTitleTranslation;
    /**
     * 
     * @type {CoverImageFile}
     * @memberof NewChannel
     */
    'coverImage': CoverImageFile;
    /**
     * 
     * @type {boolean}
     * @memberof NewChannel
     */
    'pushNotification': boolean;
    /**
     * 
     * @type {number}
     * @memberof NewChannel
     */
    'ranking': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewChannel
     */
    'hideIfEmpty': boolean;
    /**
     * 
     * @type {ChannelShownAsEnum}
     * @memberof NewChannel
     */
    'shownAs'?: ChannelShownAsEnum;
    /**
     * 
     * @type {Array<PostFeedAudience>}
     * @memberof NewChannel
     */
    'feedAudiences'?: Array<PostFeedAudience>;
    /**
     * 
     * @type {boolean}
     * @memberof NewChannel
     */
    'easyAccess'?: boolean;
}
/**
 * 
 * @export
 * @interface NewChoiceSchema
 */
export interface NewChoiceSchema {
    /**
     * 
     * @type {boolean}
     * @memberof NewChoiceSchema
     */
    'correct'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewChoiceSchema
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewChoiceSchema
     */
    'position': number;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof NewChoiceSchema
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {NewChoiceVariants}
     * @memberof NewChoiceSchema
     */
    'variants': NewChoiceVariants;
}


/**
 * 
 * @export
 * @interface NewChoiceVariants
 */
export interface NewChoiceVariants {
    [key: string]: ChoiceVariant;

}
/**
 * 
 * @export
 * @interface NewComment
 */
export interface NewComment {
    /**
     * 
     * @type {ReactionObjectTypeEnum}
     * @memberof NewComment
     */
    'objectType': ReactionObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NewComment
     */
    'objectId': number;
    /**
     * 
     * @type {Array<TextVariantItem>}
     * @memberof NewComment
     */
    'comment': Array<TextVariantItem>;
    /**
     * 
     * @type {string}
     * @memberof NewComment
     */
    'language': string;
}


/**
 * 
 * @export
 * @interface NewEvent
 */
export interface NewEvent {
    /**
     * 
     * @type {number}
     * @memberof NewEvent
     */
    'objectId': number;
    /**
     * 
     * @type {EventObjectTypeEnum}
     * @memberof NewEvent
     */
    'objectType': EventObjectTypeEnum;
    /**
     * 
     * @type {EventEnum}
     * @memberof NewEvent
     */
    'event': EventEnum;
}


/**
 * 
 * @export
 * @interface NewFileBlock
 */
export interface NewFileBlock {
    /**
     * 
     * @type {number}
     * @memberof NewFileBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewFileBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewFileBlock
     */
    'version': NewFileBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewFileBlock
     */
    'type': NewFileBlockTypeEnum;
    /**
     * 
     * @type {NewFileBlockVariants}
     * @memberof NewFileBlock
     */
    'variants': NewFileBlockVariants;
}

export const NewFileBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewFileBlockVersionEnum = typeof NewFileBlockVersionEnum[keyof typeof NewFileBlockVersionEnum];
export const NewFileBlockTypeEnum = {
    File: 'file'
} as const;

export type NewFileBlockTypeEnum = typeof NewFileBlockTypeEnum[keyof typeof NewFileBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewFileBlockVariants
 */
export interface NewFileBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface NewImageBlock
 */
export interface NewImageBlock {
    /**
     * 
     * @type {number}
     * @memberof NewImageBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewImageBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewImageBlock
     */
    'version': NewImageBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewImageBlock
     */
    'type': NewImageBlockTypeEnum;
    /**
     * 
     * @type {NewImageBlockVariants}
     * @memberof NewImageBlock
     */
    'variants': NewImageBlockVariants;
}

export const NewImageBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewImageBlockVersionEnum = typeof NewImageBlockVersionEnum[keyof typeof NewImageBlockVersionEnum];
export const NewImageBlockTypeEnum = {
    Image: 'image'
} as const;

export type NewImageBlockTypeEnum = typeof NewImageBlockTypeEnum[keyof typeof NewImageBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewImageBlockVariants
 */
export interface NewImageBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface NewMediaTaskBlock
 */
export interface NewMediaTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'version': NewMediaTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewMediaTaskBlock
     */
    'type': NewMediaTaskBlockTypeEnum;
    /**
     * 
     * @type {NewMediaTaskBlockVariants}
     * @memberof NewMediaTaskBlock
     */
    'variants': NewMediaTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewMediaTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof NewMediaTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewMediaTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewMediaTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewMediaTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof NewMediaTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof NewMediaTaskBlock
     */
    'deadline'?: string | null;
}

export const NewMediaTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewMediaTaskBlockVersionEnum = typeof NewMediaTaskBlockVersionEnum[keyof typeof NewMediaTaskBlockVersionEnum];
export const NewMediaTaskBlockTypeEnum = {
    MediaTask: 'media_task'
} as const;

export type NewMediaTaskBlockTypeEnum = typeof NewMediaTaskBlockTypeEnum[keyof typeof NewMediaTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewMediaTaskBlockVariants
 */
export interface NewMediaTaskBlockVariants {
    [key: string]: MediaTaskVariant;

}
/**
 * 
 * @export
 * @interface NewMultiChoiceBlock
 */
export interface NewMultiChoiceBlock {
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'version': NewMultiChoiceBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewMultiChoiceBlock
     */
    'type': NewMultiChoiceBlockTypeEnum;
    /**
     * 
     * @type {NewMultiChoiceVariants}
     * @memberof NewMultiChoiceBlock
     */
    'variants': NewMultiChoiceVariants;
    /**
     * 
     * @type {Array<NewChoiceSchema>}
     * @memberof NewMultiChoiceBlock
     */
    'choices': Array<NewChoiceSchema>;
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewMultiChoiceBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof NewMultiChoiceBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewMultiChoiceBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewMultiChoiceBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewMultiChoiceBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof NewMultiChoiceBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof NewMultiChoiceBlock
     */
    'deadline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewMultiChoiceBlock
     */
    'renderType'?: NewMultiChoiceBlockRenderTypeEnum;
}

export const NewMultiChoiceBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewMultiChoiceBlockVersionEnum = typeof NewMultiChoiceBlockVersionEnum[keyof typeof NewMultiChoiceBlockVersionEnum];
export const NewMultiChoiceBlockTypeEnum = {
    MultiChoice: 'multi_choice'
} as const;

export type NewMultiChoiceBlockTypeEnum = typeof NewMultiChoiceBlockTypeEnum[keyof typeof NewMultiChoiceBlockTypeEnum];
export const NewMultiChoiceBlockRenderTypeEnum = {
    Quiz: 'quiz',
    Poll: 'poll',
    RatingEmoji: 'rating-emoji',
    RatingStars: 'rating-stars',
    RatingSlider: 'rating-slider',
    YesNoNa: 'yes-no-na'
} as const;

export type NewMultiChoiceBlockRenderTypeEnum = typeof NewMultiChoiceBlockRenderTypeEnum[keyof typeof NewMultiChoiceBlockRenderTypeEnum];

/**
 * 
 * @export
 * @interface NewMultiChoiceVariants
 */
export interface NewMultiChoiceVariants {
    [key: string]: MultiChoiceVariant;

}
/**
 * 
 * @export
 * @interface NewMultiFileBlock
 */
export interface NewMultiFileBlock {
    /**
     * 
     * @type {number}
     * @memberof NewMultiFileBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewMultiFileBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewMultiFileBlock
     */
    'version': NewMultiFileBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewMultiFileBlock
     */
    'type': NewMultiFileBlockTypeEnum;
    /**
     * 
     * @type {NewMultiFileBlockVariants}
     * @memberof NewMultiFileBlock
     */
    'variants': NewMultiFileBlockVariants;
}

export const NewMultiFileBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewMultiFileBlockVersionEnum = typeof NewMultiFileBlockVersionEnum[keyof typeof NewMultiFileBlockVersionEnum];
export const NewMultiFileBlockTypeEnum = {
    MultiFile: 'multi_file'
} as const;

export type NewMultiFileBlockTypeEnum = typeof NewMultiFileBlockTypeEnum[keyof typeof NewMultiFileBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewMultiFileBlockVariants
 */
export interface NewMultiFileBlockVariants {
    [key: string]: MultiFileVariant;

}
/**
 * 
 * @export
 * @interface NewOpenQuestionTaskBlock
 */
export interface NewOpenQuestionTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'version': NewOpenQuestionTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewOpenQuestionTaskBlock
     */
    'type': NewOpenQuestionTaskBlockTypeEnum;
    /**
     * 
     * @type {NewOpenQuestionTaskBlockVariants}
     * @memberof NewOpenQuestionTaskBlock
     */
    'variants': NewOpenQuestionTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewOpenQuestionTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof NewOpenQuestionTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewOpenQuestionTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewOpenQuestionTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewOpenQuestionTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof NewOpenQuestionTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof NewOpenQuestionTaskBlock
     */
    'deadline'?: string | null;
}

export const NewOpenQuestionTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewOpenQuestionTaskBlockVersionEnum = typeof NewOpenQuestionTaskBlockVersionEnum[keyof typeof NewOpenQuestionTaskBlockVersionEnum];
export const NewOpenQuestionTaskBlockTypeEnum = {
    OpenQuestionTask: 'open_question_task'
} as const;

export type NewOpenQuestionTaskBlockTypeEnum = typeof NewOpenQuestionTaskBlockTypeEnum[keyof typeof NewOpenQuestionTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewOpenQuestionTaskBlockVariants
 */
export interface NewOpenQuestionTaskBlockVariants {
    [key: string]: OpenQuestionTaskVariant;

}
/**
 * 
 * @export
 * @interface NewPdfBlock
 */
export interface NewPdfBlock {
    /**
     * 
     * @type {number}
     * @memberof NewPdfBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewPdfBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewPdfBlock
     */
    'version': NewPdfBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewPdfBlock
     */
    'type': NewPdfBlockTypeEnum;
    /**
     * 
     * @type {NewPdfBlockVariants}
     * @memberof NewPdfBlock
     */
    'variants': NewPdfBlockVariants;
}

export const NewPdfBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewPdfBlockVersionEnum = typeof NewPdfBlockVersionEnum[keyof typeof NewPdfBlockVersionEnum];
export const NewPdfBlockTypeEnum = {
    Pdf: 'pdf'
} as const;

export type NewPdfBlockTypeEnum = typeof NewPdfBlockTypeEnum[keyof typeof NewPdfBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewPdfBlockVariants
 */
export interface NewPdfBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface NewReaction
 */
export interface NewReaction {
    /**
     * 
     * @type {ReactionObjectTypeEnum}
     * @memberof NewReaction
     */
    'objectType': ReactionObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NewReaction
     */
    'objectId': number;
    /**
     * 
     * @type {EmojiEnum}
     * @memberof NewReaction
     */
    'emoji': EmojiEnum;
}


/**
 * 
 * @export
 * @interface NewSharingConnection
 */
export interface NewSharingConnection {
    /**
     * 
     * @type {Array<NewSharingTenantConfiguration>}
     * @memberof NewSharingConnection
     */
    'receiverTenants': Array<NewSharingTenantConfiguration>;
}
/**
 * 
 * @export
 * @interface NewSharingTenantConfiguration
 */
export interface NewSharingTenantConfiguration {
    /**
     * 
     * @type {number}
     * @memberof NewSharingTenantConfiguration
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewSharingTenantConfiguration
     */
    'autoAccept'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewSharingTenantConfiguration
     */
    'autoCreatorUserId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewSharingTenantConfiguration
     */
    'channelId': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewSharingTenantConfiguration
     */
    'categoryIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewSharingTenantConfiguration
     */
    'viewerAudienceIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewSharingTenantConfiguration
     */
    'assigneeAudienceIds': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof NewSharingTenantConfiguration
     */
    'defaultAnswerSharing'?: boolean;
}
/**
 * 
 * @export
 * @interface NewSimpleTaskBlock
 */
export interface NewSimpleTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'version': NewSimpleTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewSimpleTaskBlock
     */
    'type': NewSimpleTaskBlockTypeEnum;
    /**
     * 
     * @type {NewSimpleTaskBlockVariants}
     * @memberof NewSimpleTaskBlock
     */
    'variants': NewSimpleTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof NewSimpleTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof NewSimpleTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewSimpleTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewSimpleTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewSimpleTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof NewSimpleTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof NewSimpleTaskBlock
     */
    'deadline'?: string | null;
}

export const NewSimpleTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewSimpleTaskBlockVersionEnum = typeof NewSimpleTaskBlockVersionEnum[keyof typeof NewSimpleTaskBlockVersionEnum];
export const NewSimpleTaskBlockTypeEnum = {
    SimpleTask: 'simple_task'
} as const;

export type NewSimpleTaskBlockTypeEnum = typeof NewSimpleTaskBlockTypeEnum[keyof typeof NewSimpleTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewSimpleTaskBlockVariants
 */
export interface NewSimpleTaskBlockVariants {
    [key: string]: SimpleTaskVariant;

}
/**
 * 
 * @export
 * @interface NewTextBlock
 */
export interface NewTextBlock {
    /**
     * 
     * @type {number}
     * @memberof NewTextBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewTextBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewTextBlock
     */
    'version': NewTextBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewTextBlock
     */
    'type': NewTextBlockTypeEnum;
    /**
     * 
     * @type {NewTextBlockVariants}
     * @memberof NewTextBlock
     */
    'variants': NewTextBlockVariants;
}

export const NewTextBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewTextBlockVersionEnum = typeof NewTextBlockVersionEnum[keyof typeof NewTextBlockVersionEnum];
export const NewTextBlockTypeEnum = {
    Text: 'text'
} as const;

export type NewTextBlockTypeEnum = typeof NewTextBlockTypeEnum[keyof typeof NewTextBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewTextBlockVariants
 */
export interface NewTextBlockVariants {
    [key: string]: TextBlockVariant;

}
/**
 * 
 * @export
 * @interface NewUrlBlock
 */
export interface NewUrlBlock {
    /**
     * 
     * @type {number}
     * @memberof NewUrlBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewUrlBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewUrlBlock
     */
    'version': NewUrlBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewUrlBlock
     */
    'type': NewUrlBlockTypeEnum;
    /**
     * 
     * @type {NewUrlBlockVariants}
     * @memberof NewUrlBlock
     */
    'variants': NewUrlBlockVariants;
}

export const NewUrlBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewUrlBlockVersionEnum = typeof NewUrlBlockVersionEnum[keyof typeof NewUrlBlockVersionEnum];
export const NewUrlBlockTypeEnum = {
    Url: 'url'
} as const;

export type NewUrlBlockTypeEnum = typeof NewUrlBlockTypeEnum[keyof typeof NewUrlBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewUrlBlockVariants
 */
export interface NewUrlBlockVariants {
    [key: string]: UrlVariant;

}
/**
 * 
 * @export
 * @interface NewVideoBlock
 */
export interface NewVideoBlock {
    /**
     * 
     * @type {number}
     * @memberof NewVideoBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof NewVideoBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof NewVideoBlock
     */
    'version': NewVideoBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof NewVideoBlock
     */
    'type': NewVideoBlockTypeEnum;
    /**
     * 
     * @type {NewVideoBlockVariants}
     * @memberof NewVideoBlock
     */
    'variants': NewVideoBlockVariants;
}

export const NewVideoBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type NewVideoBlockVersionEnum = typeof NewVideoBlockVersionEnum[keyof typeof NewVideoBlockVersionEnum];
export const NewVideoBlockTypeEnum = {
    Video: 'video'
} as const;

export type NewVideoBlockTypeEnum = typeof NewVideoBlockTypeEnum[keyof typeof NewVideoBlockTypeEnum];

/**
 * 
 * @export
 * @interface NewVideoBlockVariants
 */
export interface NewVideoBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface ObjectIdChanged
 */
export interface ObjectIdChanged {
    /**
     * 
     * @type {number}
     * @memberof ObjectIdChanged
     */
    'new': number | null;
    /**
     * 
     * @type {number}
     * @memberof ObjectIdChanged
     */
    'old': number | null;
}
/**
 * 
 * @export
 * @interface ObjectTypeChanged
 */
export interface ObjectTypeChanged {
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof ObjectTypeChanged
     */
    'new'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof ObjectTypeChanged
     */
    'old'?: ArticleObjectTypeEnum | null;
}


/**
 * 
 * @export
 * @interface OnceSchedule
 */
export interface OnceSchedule {
    /**
     * 
     * @type {string}
     * @memberof OnceSchedule
     */
    'frequency': OnceScheduleFrequencyEnum;
    /**
     * 
     * @type {OnceScheduleBoundary}
     * @memberof OnceSchedule
     */
    'start': OnceScheduleBoundary;
    /**
     * 
     * @type {OnceScheduleBoundary}
     * @memberof OnceSchedule
     */
    'end': OnceScheduleBoundary;
    /**
     * 
     * @type {Array<number>}
     * @memberof OnceSchedule
     */
    'exceptions': Array<number>;
}

export const OnceScheduleFrequencyEnum = {
    Once: 'once'
} as const;

export type OnceScheduleFrequencyEnum = typeof OnceScheduleFrequencyEnum[keyof typeof OnceScheduleFrequencyEnum];

/**
 * 
 * @export
 * @interface OnceScheduleBoundary
 */
export interface OnceScheduleBoundary {
    /**
     * 
     * @type {string}
     * @memberof OnceScheduleBoundary
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface OpenQuestionTaskBlock
 */
export interface OpenQuestionTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'version': OpenQuestionTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlock
     */
    'type': OpenQuestionTaskBlockTypeEnum;
    /**
     * 
     * @type {OpenQuestionTaskBlockVariants}
     * @memberof OpenQuestionTaskBlock
     */
    'variants': OpenQuestionTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof OpenQuestionTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof OpenQuestionTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpenQuestionTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof OpenQuestionTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof OpenQuestionTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlock
     */
    'deadline'?: string | null;
}

export const OpenQuestionTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type OpenQuestionTaskBlockVersionEnum = typeof OpenQuestionTaskBlockVersionEnum[keyof typeof OpenQuestionTaskBlockVersionEnum];
export const OpenQuestionTaskBlockTypeEnum = {
    OpenQuestionTask: 'open_question_task'
} as const;

export type OpenQuestionTaskBlockTypeEnum = typeof OpenQuestionTaskBlockTypeEnum[keyof typeof OpenQuestionTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface OpenQuestionTaskBlockV1
 */
export interface OpenQuestionTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'version': OpenQuestionTaskBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlockV1
     */
    'type': OpenQuestionTaskBlockV1TypeEnum;
    /**
     * 
     * @type {OpenQuestionTaskBlockV1Variants}
     * @memberof OpenQuestionTaskBlockV1
     */
    'variants': OpenQuestionTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof OpenQuestionTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof OpenQuestionTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OpenQuestionTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof OpenQuestionTaskBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof OpenQuestionTaskBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OpenQuestionTaskBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof OpenQuestionTaskBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof OpenQuestionTaskBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof OpenQuestionTaskBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof OpenQuestionTaskBlockV1
     */
    'currentInstanceId': number | null;
}

export const OpenQuestionTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type OpenQuestionTaskBlockV1VersionEnum = typeof OpenQuestionTaskBlockV1VersionEnum[keyof typeof OpenQuestionTaskBlockV1VersionEnum];
export const OpenQuestionTaskBlockV1TypeEnum = {
    OpenQuestionTask: 'open_question_task'
} as const;

export type OpenQuestionTaskBlockV1TypeEnum = typeof OpenQuestionTaskBlockV1TypeEnum[keyof typeof OpenQuestionTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface OpenQuestionTaskBlockV1Variants
 */
export interface OpenQuestionTaskBlockV1Variants {
    [key: string]: OpenQuestionTaskVariant;

}
/**
 * 
 * @export
 * @interface OpenQuestionTaskBlockVariants
 */
export interface OpenQuestionTaskBlockVariants {
    [key: string]: OpenQuestionTaskVariant;

}
/**
 * 
 * @export
 * @interface OpenQuestionTaskVariant
 */
export interface OpenQuestionTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof OpenQuestionTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OpenQuestionTaskVariant
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface Paragraph
 */
export interface Paragraph {
    /**
     * 
     * @type {string}
     * @memberof Paragraph
     */
    'type': ParagraphTypeEnum;
    /**
     * 
     * @type {Array<TextOrLink>}
     * @memberof Paragraph
     */
    'children': Array<TextOrLink>;
}

export const ParagraphTypeEnum = {
    Paragraph: 'paragraph'
} as const;

export type ParagraphTypeEnum = typeof ParagraphTypeEnum[keyof typeof ParagraphTypeEnum];

/**
 * 
 * @export
 * @interface PdfBlock
 */
export interface PdfBlock {
    /**
     * 
     * @type {number}
     * @memberof PdfBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PdfBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof PdfBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PdfBlock
     */
    'version': PdfBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof PdfBlock
     */
    'type': PdfBlockTypeEnum;
    /**
     * 
     * @type {PdfBlockVariants}
     * @memberof PdfBlock
     */
    'variants': PdfBlockVariants;
}

export const PdfBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type PdfBlockVersionEnum = typeof PdfBlockVersionEnum[keyof typeof PdfBlockVersionEnum];
export const PdfBlockTypeEnum = {
    Pdf: 'pdf'
} as const;

export type PdfBlockTypeEnum = typeof PdfBlockTypeEnum[keyof typeof PdfBlockTypeEnum];

/**
 * 
 * @export
 * @interface PdfBlockV1
 */
export interface PdfBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'version': PdfBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof PdfBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof PdfBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PdfBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof PdfBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof PdfBlockV1
     */
    'type': PdfBlockV1TypeEnum;
    /**
     * 
     * @type {PdfBlockV1Variants}
     * @memberof PdfBlockV1
     */
    'variants': PdfBlockV1Variants;
}

export const PdfBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type PdfBlockV1VersionEnum = typeof PdfBlockV1VersionEnum[keyof typeof PdfBlockV1VersionEnum];
export const PdfBlockV1TypeEnum = {
    Pdf: 'pdf'
} as const;

export type PdfBlockV1TypeEnum = typeof PdfBlockV1TypeEnum[keyof typeof PdfBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface PdfBlockV1Variants
 */
export interface PdfBlockV1Variants {
    [key: string]: ArticleFileGet;

}
/**
 * 
 * @export
 * @interface PdfBlockVariants
 */
export interface PdfBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface PostActionAnswer
 */
export interface PostActionAnswer {
    /**
     * 
     * @type {number}
     * @memberof PostActionAnswer
     */
    'actionId': number;
    /**
     * 
     * @type {number}
     * @memberof PostActionAnswer
     */
    'actionInstanceId'?: number;
    /**
     * 
     * @type {PostAnswer}
     * @memberof PostActionAnswer
     */
    'answer': PostAnswer;
    /**
     * 
     * @type {string}
     * @memberof PostActionAnswer
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof PostActionAnswer
     */
    'timezone'?: string;
}
/**
 * @type PostAnswer
 * @export
 */
export type PostAnswer = PostMediaTaskAnswer | PostMultiChoiceAnswer | PostQuestionTaskAnswer | PostYesNoTaskAnswer | string;

/**
 * 
 * @export
 * @interface PostFeedAudience
 */
export interface PostFeedAudience {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostFeedAudience
     */
    'creatorAudiences': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostFeedAudience
     */
    'viewerAudiences': Array<number>;
    /**
     * 
     * @type {FeedAudienceNameVariants}
     * @memberof PostFeedAudience
     */
    'variants'?: FeedAudienceNameVariants;
}
/**
 * 
 * @export
 * @interface PostGlossaryEntitySchema
 */
export interface PostGlossaryEntitySchema {
    /**
     * 
     * @type {string}
     * @memberof PostGlossaryEntitySchema
     */
    'term': string;
    /**
     * 
     * @type {PostGlossaryEntitySchemaSourceLanguage}
     * @memberof PostGlossaryEntitySchema
     */
    'sourceLanguage': PostGlossaryEntitySchemaSourceLanguage;
    /**
     * 
     * @type {TermEquivalents}
     * @memberof PostGlossaryEntitySchema
     */
    'termEquivalents'?: TermEquivalents;
}
/**
 * @type PostGlossaryEntitySchemaSourceLanguage
 * @export
 */
export type PostGlossaryEntitySchemaSourceLanguage = string;

/**
 * 
 * @export
 * @interface PostMediaTaskAnswer
 */
export interface PostMediaTaskAnswer extends Array<string> {
}
/**
 * 
 * @export
 * @interface PostMultiChoiceAnswer
 */
export interface PostMultiChoiceAnswer extends Array<number> {
}
/**
 * 
 * @export
 * @interface PostQuestionTaskAnswer
 */
export interface PostQuestionTaskAnswer {
    /**
     * 
     * @type {string}
     * @memberof PostQuestionTaskAnswer
     */
    'type': PostQuestionTaskAnswerTypeEnum;
    /**
     * 
     * @type {Array<TextOrLink>}
     * @memberof PostQuestionTaskAnswer
     */
    'children': Array<TextOrLink>;
}

export const PostQuestionTaskAnswerTypeEnum = {
    Paragraph: 'paragraph'
} as const;

export type PostQuestionTaskAnswerTypeEnum = typeof PostQuestionTaskAnswerTypeEnum[keyof typeof PostQuestionTaskAnswerTypeEnum];

/**
 * 
 * @export
 * @interface PostScormTaskBlockV1
 */
export interface PostScormTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'version': PostScormTaskBlockV1VersionEnum;
    /**
     * 
     * @type {string}
     * @memberof PostScormTaskBlockV1
     */
    'type': PostScormTaskBlockV1TypeEnum;
    /**
     * 
     * @type {PostScormTaskVariants}
     * @memberof PostScormTaskBlockV1
     */
    'variants': PostScormTaskVariants;
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof PostScormTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof PostScormTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostScormTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostScormTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostScormTaskBlockV1
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof PostScormTaskBlockV1
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof PostScormTaskBlockV1
     */
    'deadline'?: string | null;
}

export const PostScormTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type PostScormTaskBlockV1VersionEnum = typeof PostScormTaskBlockV1VersionEnum[keyof typeof PostScormTaskBlockV1VersionEnum];
export const PostScormTaskBlockV1TypeEnum = {
    ScormTask: 'scorm_task'
} as const;

export type PostScormTaskBlockV1TypeEnum = typeof PostScormTaskBlockV1TypeEnum[keyof typeof PostScormTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface PostScormTaskVariants
 */
export interface PostScormTaskVariants {
    [key: string]: PostScormTaskVariantsValue;

}
/**
 * 
 * @export
 * @interface PostScormTaskVariantsValue
 */
export interface PostScormTaskVariantsValue {
    /**
     * 
     * @type {string}
     * @memberof PostScormTaskVariantsValue
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof PostScormTaskVariantsValue
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PostScormTaskVariantsValue
     */
    'title'?: string;
    /**
     * 
     * @type {InternalFile}
     * @memberof PostScormTaskVariantsValue
     */
    'package': InternalFile;
    /**
     * 
     * @type {InternalFile}
     * @memberof PostScormTaskVariantsValue
     */
    'image': InternalFile;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PostYesNoTaskAnswer = {
    True: 'true',
    False: 'false',
    NA: 'n/a'
} as const;

export type PostYesNoTaskAnswer = typeof PostYesNoTaskAnswer[keyof typeof PostYesNoTaskAnswer];


/**
 * 
 * @export
 * @interface PostYesNoTaskBlockV1
 */
export interface PostYesNoTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'version': PostYesNoTaskBlockV1VersionEnum;
    /**
     * 
     * @type {string}
     * @memberof PostYesNoTaskBlockV1
     */
    'type': PostYesNoTaskBlockV1TypeEnum;
    /**
     * 
     * @type {PostYesNoTaskVariants}
     * @memberof PostYesNoTaskBlockV1
     */
    'variants': PostYesNoTaskVariants;
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof PostYesNoTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof PostYesNoTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostYesNoTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostYesNoTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostYesNoTaskBlockV1
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof PostYesNoTaskBlockV1
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof PostYesNoTaskBlockV1
     */
    'deadline'?: string | null;
}

export const PostYesNoTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type PostYesNoTaskBlockV1VersionEnum = typeof PostYesNoTaskBlockV1VersionEnum[keyof typeof PostYesNoTaskBlockV1VersionEnum];
export const PostYesNoTaskBlockV1TypeEnum = {
    YesNoTask: 'yes_no_task'
} as const;

export type PostYesNoTaskBlockV1TypeEnum = typeof PostYesNoTaskBlockV1TypeEnum[keyof typeof PostYesNoTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface PostYesNoTaskVariants
 */
export interface PostYesNoTaskVariants {
    [key: string]: YesNoTaskVariant;

}
/**
 * 
 * @export
 * @interface PublishAtChange
 */
export interface PublishAtChange {
    /**
     * 
     * @type {string}
     * @memberof PublishAtChange
     */
    'new': string | null;
    /**
     * 
     * @type {string}
     * @memberof PublishAtChange
     */
    'old': string | null;
}
/**
 * 
 * @export
 * @interface PublishedInstantlyChange
 */
export interface PublishedInstantlyChange {
    /**
     * 
     * @type {boolean}
     * @memberof PublishedInstantlyChange
     */
    'new': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublishedInstantlyChange
     */
    'old': boolean;
}
/**
 * 
 * @export
 * @interface PutGlossaryEntitySchema
 */
export interface PutGlossaryEntitySchema {
    /**
     * 
     * @type {string}
     * @memberof PutGlossaryEntitySchema
     */
    'term': string;
    /**
     * 
     * @type {string}
     * @memberof PutGlossaryEntitySchema
     */
    'sourceLanguage': string;
    /**
     * 
     * @type {TermEquivalents}
     * @memberof PutGlossaryEntitySchema
     */
    'termEquivalents': TermEquivalents;
    /**
     * 
     * @type {string}
     * @memberof PutGlossaryEntitySchema
     */
    'updateMethod': PutGlossaryEntitySchemaUpdateMethodEnum;
}

export const PutGlossaryEntitySchemaUpdateMethodEnum = {
    Merge: 'Merge',
    Replace: 'Replace'
} as const;

export type PutGlossaryEntitySchemaUpdateMethodEnum = typeof PutGlossaryEntitySchemaUpdateMethodEnum[keyof typeof PutGlossaryEntitySchemaUpdateMethodEnum];

/**
 * 
 * @export
 * @interface Reaction
 */
export interface Reaction {
    /**
     * 
     * @type {number}
     * @memberof Reaction
     */
    'id': number;
    /**
     * 
     * @type {ReactionObjectTypeEnum}
     * @memberof Reaction
     */
    'objectType': ReactionObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Reaction
     */
    'objectId': number;
    /**
     * 
     * @type {EmojiEnum}
     * @memberof Reaction
     */
    'emoji': EmojiEnum;
    /**
     * 
     * @type {string}
     * @memberof Reaction
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof Reaction
     */
    'createdBy': number;
}


/**
 * 
 * @export
 * @interface ReactionCounts
 */
export interface ReactionCounts {
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'reliable'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'helpful'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'productive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'funny'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'creative'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'organized'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'motivating'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'informative'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'dedicated'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'inspiring'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'effective'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'collaborative'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'insightful'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactionCounts
     */
    'proactive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReactionObjectTypeEnum = {
    Article: 'article',
    ActionAnswer: 'action_answer',
    Comment: 'comment'
} as const;

export type ReactionObjectTypeEnum = typeof ReactionObjectTypeEnum[keyof typeof ReactionObjectTypeEnum];


/**
 * 
 * @export
 * @interface ReactionPage
 */
export interface ReactionPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof ReactionPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<Reaction>}
     * @memberof ReactionPage
     */
    'data': Array<Reaction>;
}
/**
 * 
 * @export
 * @interface ReactionSummary
 */
export interface ReactionSummary {
    /**
     * 
     * @type {ReactionObjectTypeEnum}
     * @memberof ReactionSummary
     */
    'objectType': ReactionObjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReactionSummary
     */
    'objectId': number;
    /**
     * 
     * @type {number}
     * @memberof ReactionSummary
     */
    'commentCount': number;
    /**
     * 
     * @type {ReactionCounts}
     * @memberof ReactionSummary
     */
    'reactionCounts': ReactionCounts;
    /**
     * 
     * @type {Array<EmojiEnum>}
     * @memberof ReactionSummary
     */
    'currentUserReactions': Array<EmojiEnum>;
}


/**
 * 
 * @export
 * @interface RelevantCategory
 */
export interface RelevantCategory {
    /**
     * 
     * @type {number}
     * @memberof RelevantCategory
     */
    'id': number;
    /**
     * 
     * @type {CategoryVariants}
     * @memberof RelevantCategory
     */
    'variants': CategoryVariants;
}
/**
 * 
 * @export
 * @interface RelevantCategoryPage
 */
export interface RelevantCategoryPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof RelevantCategoryPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<RelevantCategory>}
     * @memberof RelevantCategoryPage
     */
    'data': Array<RelevantCategory>;
}
/**
 * 
 * @export
 * @interface RelevantChannelPage
 */
export interface RelevantChannelPage {
    /**
     * 
     * @type {ChannelPageMeta}
     * @memberof RelevantChannelPage
     */
    'meta': ChannelPageMeta;
    /**
     * 
     * @type {Array<BasicRelevantChannelInfo>}
     * @memberof RelevantChannelPage
     */
    'data': Array<BasicRelevantChannelInfo>;
}
/**
 * 
 * @export
 * @interface RequestBulkDeleteArticle
 */
export interface RequestBulkDeleteArticle {
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestBulkDeleteArticle
     */
    'articleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface RequestConvertHtmlToArticleSchema
 */
export interface RequestConvertHtmlToArticleSchema {
    /**
     * 
     * @type {string}
     * @memberof RequestConvertHtmlToArticleSchema
     */
    'html': string;
    /**
     * 
     * @type {string}
     * @memberof RequestConvertHtmlToArticleSchema
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface RequiredScopeResponse
 */
export interface RequiredScopeResponse {
    /**
     * 
     * @type {string}
     * @memberof RequiredScopeResponse
     */
    'scope': RequiredScopeResponseScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequiredScopeResponse
     */
    'tenant': string;
}

export const RequiredScopeResponseScopeEnum = {
    Private: 'private',
    EasyAccess: 'easy-access'
} as const;

export type RequiredScopeResponseScopeEnum = typeof RequiredScopeResponseScopeEnum[keyof typeof RequiredScopeResponseScopeEnum];

/**
 * 
 * @export
 * @interface ResponseConvertHtmlToArticleSchema
 */
export interface ResponseConvertHtmlToArticleSchema {
    /**
     * 
     * @type {Array<ConvertedBlock>}
     * @memberof ResponseConvertHtmlToArticleSchema
     */
    'blocks': Array<ConvertedBlock>;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof ResponseConvertHtmlToArticleSchema
     */
    'variants'?: ArticleVariantsGet;
}
/**
 * 
 * @export
 * @interface RetrievedGlossaryEntitySchema
 */
export interface RetrievedGlossaryEntitySchema {
    /**
     * 
     * @type {string}
     * @memberof RetrievedGlossaryEntitySchema
     */
    'tenant': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedGlossaryEntitySchema
     */
    'term': string;
    /**
     * 
     * @type {string}
     * @memberof RetrievedGlossaryEntitySchema
     */
    'sourceLanguage': string;
    /**
     * 
     * @type {TermEquivalents}
     * @memberof RetrievedGlossaryEntitySchema
     */
    'termEquivalents'?: TermEquivalents;
    /**
     * 
     * @type {string}
     * @memberof RetrievedGlossaryEntitySchema
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface RevertAnswer
 */
export interface RevertAnswer {
    /**
     * 
     * @type {number}
     * @memberof RevertAnswer
     */
    'answerId': number;
    /**
     * 
     * @type {string}
     * @memberof RevertAnswer
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleMonth = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
} as const;

export type ScheduleMonth = typeof ScheduleMonth[keyof typeof ScheduleMonth];


/**
 * @type ScheduleMonthDay
 * @export
 */
export type ScheduleMonthDay = number | string;

/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleWeekDay = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'
} as const;

export type ScheduleWeekDay = typeof ScheduleWeekDay[keyof typeof ScheduleWeekDay];


/**
 * 
 * @export
 * @interface ScormRegistrationConflict
 */
export interface ScormRegistrationConflict {
    /**
     * 
     * @type {string}
     * @memberof ScormRegistrationConflict
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ScormRegistrationRequest
 */
export interface ScormRegistrationRequest {
    /**
     * 
     * @type {number}
     * @memberof ScormRegistrationRequest
     */
    'blockId': number;
    /**
     * 
     * @type {string}
     * @memberof ScormRegistrationRequest
     */
    'languageCode': string;
    /**
     * 
     * @type {string}
     * @memberof ScormRegistrationRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScormRegistrationRequest
     */
    'actionInstanceId'?: number;
}
/**
 * 
 * @export
 * @interface ScormTaskBlock
 */
export interface ScormTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'version': ScormTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlock
     */
    'type': ScormTaskBlockTypeEnum;
    /**
     * 
     * @type {ScormTaskBlockBVariants}
     * @memberof ScormTaskBlock
     */
    'variants': ScormTaskBlockBVariants;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ScormTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ScormTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScormTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScormTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof ScormTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlock
     */
    'deadline'?: string | null;
}

export const ScormTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type ScormTaskBlockVersionEnum = typeof ScormTaskBlockVersionEnum[keyof typeof ScormTaskBlockVersionEnum];
export const ScormTaskBlockTypeEnum = {
    ScormTask: 'scorm_task'
} as const;

export type ScormTaskBlockTypeEnum = typeof ScormTaskBlockTypeEnum[keyof typeof ScormTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface ScormTaskBlockBVariants
 */
export interface ScormTaskBlockBVariants {
    [key: string]: ScormTaskBlockBVariantsValue;

}
/**
 * 
 * @export
 * @interface ScormTaskBlockBVariantsValue
 */
export interface ScormTaskBlockBVariantsValue {
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'title'?: string;
    /**
     * 
     * @type {InternalFile}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'package': InternalFile;
    /**
     * 
     * @type {InternalFile}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'image': InternalFile;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'courseStatus'?: ScormTaskBlockBVariantsValueCourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'jobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockBVariantsValue
     */
    'courseLearningStandard'?: string;
}

export const ScormTaskBlockBVariantsValueCourseStatusEnum = {
    Running: 'RUNNING',
    Complete: 'COMPLETE',
    Error: 'ERROR',
    Timeout: 'TIMEOUT'
} as const;

export type ScormTaskBlockBVariantsValueCourseStatusEnum = typeof ScormTaskBlockBVariantsValueCourseStatusEnum[keyof typeof ScormTaskBlockBVariantsValueCourseStatusEnum];

/**
 * 
 * @export
 * @interface ScormTaskBlockV1
 */
export interface ScormTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'version': ScormTaskBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockV1
     */
    'type': ScormTaskBlockV1TypeEnum;
    /**
     * 
     * @type {ScormTaskBlockV1Variants}
     * @memberof ScormTaskBlockV1
     */
    'variants': ScormTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof ScormTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof ScormTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScormTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof ScormTaskBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof ScormTaskBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScormTaskBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ScormTaskBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof ScormTaskBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof ScormTaskBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof ScormTaskBlockV1
     */
    'currentInstanceId': number | null;
}

export const ScormTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type ScormTaskBlockV1VersionEnum = typeof ScormTaskBlockV1VersionEnum[keyof typeof ScormTaskBlockV1VersionEnum];
export const ScormTaskBlockV1TypeEnum = {
    ScormTask: 'scorm_task'
} as const;

export type ScormTaskBlockV1TypeEnum = typeof ScormTaskBlockV1TypeEnum[keyof typeof ScormTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface ScormTaskBlockV1Variants
 */
export interface ScormTaskBlockV1Variants {
    [key: string]: GetScormTaskVariant;

}
/**
 * 
 * @export
 * @interface ScormTaskVariant
 */
export interface ScormTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof ScormTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'title'?: string;
    /**
     * 
     * @type {InternalFile}
     * @memberof ScormTaskVariant
     */
    'package': InternalFile;
    /**
     * 
     * @type {InternalFile}
     * @memberof ScormTaskVariant
     */
    'image': InternalFile;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'courseStatus'?: ScormTaskVariantCourseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'jobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScormTaskVariant
     */
    'courseLearningStandard'?: string;
}

export const ScormTaskVariantCourseStatusEnum = {
    Running: 'RUNNING',
    Complete: 'COMPLETE',
    Error: 'ERROR',
    Timeout: 'TIMEOUT'
} as const;

export type ScormTaskVariantCourseStatusEnum = typeof ScormTaskVariantCourseStatusEnum[keyof typeof ScormTaskVariantCourseStatusEnum];

/**
 * 
 * @export
 * @interface ShareArticleRequest
 */
export interface ShareArticleRequest {
    /**
     * 
     * @type {number}
     * @memberof ShareArticleRequest
     */
    'articleId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShareArticleRequest
     */
    'receiverTenants': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ShareArticleRequest
     */
    'includeAssets': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShareArticleRequest
     */
    'allowResharing': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShareArticleRequest
     */
    'sharingComment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShareArticleRequest
     */
    'answerSharing'?: boolean;
}
/**
 * 
 * @export
 * @interface ShareArticleResponse
 */
export interface ShareArticleResponse extends Array<SharedArticle> {
}
/**
 * 
 * @export
 * @interface SharedArticle
 */
export interface SharedArticle {
    /**
     * 
     * @type {number}
     * @memberof SharedArticle
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof SharedArticle
     */
    'tenant': string;
}
/**
 * 
 * @export
 * @interface SharedTenants
 */
export interface SharedTenants {
    /**
     * 
     * @type {number}
     * @memberof SharedTenants
     */
    'sourceId': number;
    /**
     * 
     * @type {string}
     * @memberof SharedTenants
     */
    'sourceName': string;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof SharedTenants
     */
    'receivers': Array<Tenant> | null;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof SharedTenants
     */
    'senders': Array<Tenant> | null;
}
/**
 * 
 * @export
 * @interface SharingStatusOverviewResponse
 */
export interface SharingStatusOverviewResponse extends Array<ArticleSharingStatus> {
}
/**
 * 
 * @export
 * @interface ShortTenant
 */
export interface ShortTenant {
    /**
     * 
     * @type {number}
     * @memberof ShortTenant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ShortTenant
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SimpleTaskBlock
 */
export interface SimpleTaskBlock {
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'version': SimpleTaskBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlock
     */
    'type': SimpleTaskBlockTypeEnum;
    /**
     * 
     * @type {SimpleTaskBlockVariants}
     * @memberof SimpleTaskBlock
     */
    'variants': SimpleTaskBlockVariants;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleTaskBlock
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof SimpleTaskBlock
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlock
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleTaskBlock
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SimpleTaskBlock
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof SimpleTaskBlock
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlock
     */
    'deadline'?: string | null;
}

export const SimpleTaskBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type SimpleTaskBlockVersionEnum = typeof SimpleTaskBlockVersionEnum[keyof typeof SimpleTaskBlockVersionEnum];
export const SimpleTaskBlockTypeEnum = {
    SimpleTask: 'simple_task'
} as const;

export type SimpleTaskBlockTypeEnum = typeof SimpleTaskBlockTypeEnum[keyof typeof SimpleTaskBlockTypeEnum];

/**
 * 
 * @export
 * @interface SimpleTaskBlockV1
 */
export interface SimpleTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'version': SimpleTaskBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlockV1
     */
    'type': SimpleTaskBlockV1TypeEnum;
    /**
     * 
     * @type {SimpleTaskBlockV1Variants}
     * @memberof SimpleTaskBlockV1
     */
    'variants': SimpleTaskBlockV1Variants;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof SimpleTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof SimpleTaskBlockV1
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof SimpleTaskBlockV1
     */
    'schedule': ActionSchedule;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskBlockV1
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleTaskBlockV1
     */
    'completed': boolean;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof SimpleTaskBlockV1
     */
    'answers': GetUserAnswers;
    /**
     * 
     * @type {GetUserAnswers}
     * @memberof SimpleTaskBlockV1
     */
    'currentUserAnswers': GetUserAnswers;
    /**
     * 
     * @type {Array<ActionInstance>}
     * @memberof SimpleTaskBlockV1
     */
    'instances': Array<ActionInstance>;
    /**
     * 
     * @type {number}
     * @memberof SimpleTaskBlockV1
     */
    'currentInstanceId': number | null;
}

export const SimpleTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type SimpleTaskBlockV1VersionEnum = typeof SimpleTaskBlockV1VersionEnum[keyof typeof SimpleTaskBlockV1VersionEnum];
export const SimpleTaskBlockV1TypeEnum = {
    SimpleTask: 'simple_task'
} as const;

export type SimpleTaskBlockV1TypeEnum = typeof SimpleTaskBlockV1TypeEnum[keyof typeof SimpleTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface SimpleTaskBlockV1Variants
 */
export interface SimpleTaskBlockV1Variants {
    [key: string]: SimpleTaskVariant;

}
/**
 * 
 * @export
 * @interface SimpleTaskBlockVariants
 */
export interface SimpleTaskBlockVariants {
    [key: string]: SimpleTaskVariant;

}
/**
 * 
 * @export
 * @interface SimpleTaskVariant
 */
export interface SimpleTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof SimpleTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleTaskVariant
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type SortOrderEnum = typeof SortOrderEnum[keyof typeof SortOrderEnum];


/**
 * 
 * @export
 * @interface StatusChange
 */
export interface StatusChange {
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof StatusChange
     */
    'new': ArticleStatusEnum;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof StatusChange
     */
    'old': ArticleStatusEnum;
}


/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'sharingConnectionId': number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'logo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'autoAccept': boolean;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'autoCreatorUserId': number | null;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'channelId': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tenant
     */
    'viewerAudiences': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tenant
     */
    'assigneeAudiences': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Tenant
     */
    'categoryIds': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'defaultAnswerSharing': boolean;
}
/**
 * 
 * @export
 * @interface TermEquivalents
 */
export interface TermEquivalents {
    [key: string]: string;

}
/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    'type': TextTypeEnum;
    /**
     * 
     * @type {TextFormat}
     * @memberof Text
     */
    'format': TextFormat;
    /**
     * 
     * @type {string}
     * @memberof Text
     */
    'value': string;
}

export const TextTypeEnum = {
    Text: 'text'
} as const;

export type TextTypeEnum = typeof TextTypeEnum[keyof typeof TextTypeEnum];

/**
 * 
 * @export
 * @interface TextBlock
 */
export interface TextBlock {
    /**
     * 
     * @type {number}
     * @memberof TextBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TextBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof TextBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TextBlock
     */
    'version': TextBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof TextBlock
     */
    'type': TextBlockTypeEnum;
    /**
     * 
     * @type {TextBlockVariants}
     * @memberof TextBlock
     */
    'variants': TextBlockVariants;
}

export const TextBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type TextBlockVersionEnum = typeof TextBlockVersionEnum[keyof typeof TextBlockVersionEnum];
export const TextBlockTypeEnum = {
    Text: 'text'
} as const;

export type TextBlockTypeEnum = typeof TextBlockTypeEnum[keyof typeof TextBlockTypeEnum];

/**
 * 
 * @export
 * @interface TextBlockV1
 */
export interface TextBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'version': TextBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof TextBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof TextBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TextBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TextBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof TextBlockV1
     */
    'type': TextBlockV1TypeEnum;
    /**
     * 
     * @type {TextBlockV1Variants}
     * @memberof TextBlockV1
     */
    'variants': TextBlockV1Variants;
}

export const TextBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type TextBlockV1VersionEnum = typeof TextBlockV1VersionEnum[keyof typeof TextBlockV1VersionEnum];
export const TextBlockV1TypeEnum = {
    Text: 'text'
} as const;

export type TextBlockV1TypeEnum = typeof TextBlockV1TypeEnum[keyof typeof TextBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface TextBlockV1Variants
 */
export interface TextBlockV1Variants {
    [key: string]: TextBlockVariant;

}
/**
 * 
 * @export
 * @interface TextBlockVariant
 */
export interface TextBlockVariant {
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof TextBlockVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {Array<TextVariantItem>}
     * @memberof TextBlockVariant
     */
    'items': Array<TextVariantItem>;
}


/**
 * 
 * @export
 * @interface TextBlockVariants
 */
export interface TextBlockVariants {
    [key: string]: TextBlockVariant;

}
/**
 * 
 * @export
 * @interface TextFormat
 */
export interface TextFormat {
    /**
     * 
     * @type {boolean}
     * @memberof TextFormat
     */
    'bold'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TextFormat
     */
    'italic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TextFormat
     */
    'underline'?: boolean;
}
/**
 * @type TextOrLink
 * @export
 */
export type TextOrLink = { type: 'link' } & Link | { type: 'text' } & Text;

/**
 * @type TextVariantItem
 * @export
 */
export type TextVariantItem = { type: 'heading' } & Heading | { type: 'list' } & List | { type: 'paragraph' } & Paragraph;

/**
 * 
 * @export
 * @interface Translate
 */
export interface Translate {
    /**
     * 
     * @type {number}
     * @memberof Translate
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    'toLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    'live'?: TranslateLiveEnum;
    /**
     * 
     * @type {string}
     * @memberof Translate
     */
    'timezone'?: string;
}

export const TranslateLiveEnum = {
    _1: '1'
} as const;

export type TranslateLiveEnum = typeof TranslateLiveEnum[keyof typeof TranslateLiveEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TranslationStatusEnum = {
    Draft: 'draft',
    Approved: 'approved',
    Auto: 'auto'
} as const;

export type TranslationStatusEnum = typeof TranslationStatusEnum[keyof typeof TranslationStatusEnum];


/**
 * 
 * @export
 * @interface UnarchiveArticlesRequest
 */
export interface UnarchiveArticlesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof UnarchiveArticlesRequest
     */
    'articleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UnarchiveArticlesResponse
 */
export interface UnarchiveArticlesResponse extends Array<Article> {
}
/**
 * 
 * @export
 * @interface UnarchiveCategoriesRequest
 */
export interface UnarchiveCategoriesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof UnarchiveCategoriesRequest
     */
    'categoryIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UnarchiveCategoriesResponse
 */
export interface UnarchiveCategoriesResponse extends Array<GetCategory> {
}
/**
 * 
 * @export
 * @interface UnavailableBacklink
 */
export interface UnavailableBacklink {
    /**
     * 
     * @type {number}
     * @memberof UnavailableBacklink
     */
    'id': number;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof UnavailableBacklink
     */
    'status': ArticleStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UnavailableBacklink
     */
    'message': string;
}


/**
 * 
 * @export
 * @interface UncompletedAction
 */
export interface UncompletedAction {
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'id': number;
    /**
     * 
     * @type {ActionTypeEnum}
     * @memberof UncompletedAction
     */
    'type'?: ActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UncompletedAction
     */
    'renderType': UncompletedActionRenderTypeEnum;
    /**
     * 
     * @type {UncompletedActionVariants}
     * @memberof UncompletedAction
     */
    'variants': UncompletedActionVariants;
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'required': number;
    /**
     * 
     * @type {Array<GetCategory>}
     * @memberof UncompletedAction
     */
    'categories': Array<GetCategory>;
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'instanceId': number;
    /**
     * 
     * @type {string}
     * @memberof UncompletedAction
     */
    'deadline': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UncompletedAction
     */
    'mandatory': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UncompletedAction
     */
    'public': boolean;
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'articleId': number;
    /**
     * 
     * @type {ArticleVariantsGet}
     * @memberof UncompletedAction
     */
    'articleVariants': ArticleVariantsGet;
    /**
     * 
     * @type {string}
     * @memberof UncompletedAction
     */
    'createdAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UncompletedAction
     */
    'updatedAt': string | null;
    /**
     * 
     * @type {Array<UncompletedActionChoice>}
     * @memberof UncompletedAction
     */
    'choices': Array<UncompletedActionChoice>;
    /**
     * 
     * @type {LanguageList}
     * @memberof UncompletedAction
     */
    'languages': LanguageList;
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {number}
     * @memberof UncompletedAction
     */
    'minCorrectAnswers'?: number;
}

export const UncompletedActionRenderTypeEnum = {
    Quiz: 'quiz',
    Poll: 'poll',
    RatingEmoji: 'rating-emoji',
    RatingStars: 'rating-stars',
    RatingSlider: 'rating-slider',
    YesNoNa: 'yes-no-na'
} as const;

export type UncompletedActionRenderTypeEnum = typeof UncompletedActionRenderTypeEnum[keyof typeof UncompletedActionRenderTypeEnum];

/**
 * 
 * @export
 * @interface UncompletedActionChoice
 */
export interface UncompletedActionChoice {
    /**
     * 
     * @type {number}
     * @memberof UncompletedActionChoice
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UncompletedActionChoice
     */
    'position': number;
    /**
     * 
     * @type {boolean}
     * @memberof UncompletedActionChoice
     */
    'correct': boolean | null;
    /**
     * 
     * @type {AnswerTypeEnum}
     * @memberof UncompletedActionChoice
     */
    'answerType': AnswerTypeEnum;
    /**
     * 
     * @type {{ [key: string]: GetChoiceVariant; }}
     * @memberof UncompletedActionChoice
     */
    'variants': { [key: string]: GetChoiceVariant; };
}


/**
 * 
 * @export
 * @interface UncompletedActionVariants
 */
export interface UncompletedActionVariants {
    [key: string]: UncompletedActionVariantsValue;

}
/**
 * @type UncompletedActionVariantsValue
 * @export
 */
export type UncompletedActionVariantsValue = GetScormTaskVariant | MediaTaskVariant | MultiChoiceVariant | OpenQuestionTaskVariant | SimpleTaskVariant | YesNoTaskVariant;

/**
 * 
 * @export
 * @interface UncompletedActionsPage
 */
export interface UncompletedActionsPage {
    /**
     * 
     * @type {ActionAnswerPageMeta}
     * @memberof UncompletedActionsPage
     */
    'meta': ActionAnswerPageMeta;
    /**
     * 
     * @type {Array<UncompletedAction>}
     * @memberof UncompletedActionsPage
     */
    'data': Array<UncompletedAction>;
}
/**
 * 
 * @export
 * @interface UpdateYesNoTaskBlockV1
 */
export interface UpdateYesNoTaskBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'version': UpdateYesNoTaskBlockV1VersionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'type': UpdateYesNoTaskBlockV1TypeEnum;
    /**
     * 
     * @type {YesNoTaskVariants}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'variants': YesNoTaskVariants;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'required': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'public': boolean;
    /**
     * 
     * @type {AudienceIds}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'minCorrectAnswers'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'maxSelectedChoices'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {ActionChangeLogSchedule}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'schedule'?: ActionChangeLogSchedule;
    /**
     * 
     * @type {string}
     * @memberof UpdateYesNoTaskBlockV1
     */
    'deadline'?: string | null;
}

export const UpdateYesNoTaskBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type UpdateYesNoTaskBlockV1VersionEnum = typeof UpdateYesNoTaskBlockV1VersionEnum[keyof typeof UpdateYesNoTaskBlockV1VersionEnum];
export const UpdateYesNoTaskBlockV1TypeEnum = {
    YesNoTask: 'yes_no_task'
} as const;

export type UpdateYesNoTaskBlockV1TypeEnum = typeof UpdateYesNoTaskBlockV1TypeEnum[keyof typeof UpdateYesNoTaskBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface UpdatedArticle
 */
export interface UpdatedArticle {
    /**
     * 
     * @type {number}
     * @memberof UpdatedArticle
     */
    'id': number;
    /**
     * 
     * @type {ArticleVariants}
     * @memberof UpdatedArticle
     */
    'variants': ArticleVariants;
    /**
     * 
     * @type {UpdatedArticleStatus}
     * @memberof UpdatedArticle
     */
    'status': UpdatedArticleStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdatedArticle
     */
    'publishAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatedArticle
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatedArticle
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {UpdatedBlockList}
     * @memberof UpdatedArticle
     */
    'blocks': UpdatedBlockList;
    /**
     * 
     * @type {AudienceIds}
     * @memberof UpdatedArticle
     */
    'audiences': AudienceIds;
    /**
     * 
     * @type {ArticleCollaborators}
     * @memberof UpdatedArticle
     */
    'users': ArticleCollaborators;
    /**
     * 
     * @type {InputArticleLanguageList}
     * @memberof UpdatedArticle
     */
    'languages': InputArticleLanguageList;
    /**
     * 
     * @type {number}
     * @memberof UpdatedArticle
     */
    'channelId': number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdatedArticle
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedArticle
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedArticle
     */
    'isReactingAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedArticle
     */
    'publishedInstantly'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdatedArticle
     */
    'objectId'?: number | null;
    /**
     * 
     * @type {ArticleObjectTypeEnum}
     * @memberof UpdatedArticle
     */
    'objectType'?: ArticleObjectTypeEnum | null;
    /**
     * 
     * @type {ArticleShownAs}
     * @memberof UpdatedArticle
     */
    'shownAs'?: ArticleShownAs;
    /**
     * 
     * @type {ActionSchedule}
     * @memberof UpdatedArticle
     */
    'schedule'?: ActionSchedule;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedArticle
     */
    'answerSharing'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UpdatedArticleStatus = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived',
    Inbound: 'inbound',
    Rejected: 'rejected'
} as const;

export type UpdatedArticleStatus = typeof UpdatedArticleStatus[keyof typeof UpdatedArticleStatus];


/**
 * @type UpdatedBlock
 * @export
 */
export type UpdatedBlock = { type: 'article_link' } & ArticleLinkBlock | { type: 'file' } & FileBlock | { type: 'image' } & ImageBlock | { type: 'media_task' } & MediaTaskBlock | { type: 'multi_choice' } & MultiChoiceBlock | { type: 'multi_file' } & MultiFileBlock | { type: 'open_question_task' } & OpenQuestionTaskBlock | { type: 'pdf' } & PdfBlock | { type: 'scorm_task' } & ScormTaskBlock | { type: 'simple_task' } & SimpleTaskBlock | { type: 'text' } & TextBlock | { type: 'url' } & UrlBlock | { type: 'video' } & VideoBlock | { type: 'yes_no_task' } & UpdateYesNoTaskBlockV1;

/**
 * 
 * @export
 * @interface UpdatedBlockList
 */
export interface UpdatedBlockList extends Array<UpdatedBlock> {
}
/**
 * 
 * @export
 * @interface UpdatedCategory
 */
export interface UpdatedCategory {
    /**
     * 
     * @type {number}
     * @memberof UpdatedCategory
     */
    'id': number;
    /**
     * 
     * @type {CategoryVariants}
     * @memberof UpdatedCategory
     */
    'variants': CategoryVariants;
    /**
     * 
     * @type {CategoryStatusEnum}
     * @memberof UpdatedCategory
     */
    'status': CategoryStatusEnum;
}


/**
 * 
 * @export
 * @interface UpdatedChannel
 */
export interface UpdatedChannel {
    /**
     * 
     * @type {number}
     * @memberof UpdatedChannel
     */
    'id': number;
    /**
     * 
     * @type {ChannelTitleTranslation}
     * @memberof UpdatedChannel
     */
    'title': ChannelTitleTranslation;
    /**
     * 
     * @type {CoverImageFile}
     * @memberof UpdatedChannel
     */
    'coverImage': CoverImageFile;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedChannel
     */
    'pushNotification': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdatedChannel
     */
    'ranking': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedChannel
     */
    'hideIfEmpty': boolean;
    /**
     * 
     * @type {ChannelShownAsEnum}
     * @memberof UpdatedChannel
     */
    'shownAs'?: ChannelShownAsEnum;
    /**
     * 
     * @type {Array<FeedAudienceUpdate>}
     * @memberof UpdatedChannel
     */
    'feedAudiences'?: Array<FeedAudienceUpdate>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatedChannel
     */
    'easyAccess'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatedComment
 */
export interface UpdatedComment {
    /**
     * 
     * @type {Array<TextVariantItem>}
     * @memberof UpdatedComment
     */
    'comment': Array<TextVariantItem>;
    /**
     * 
     * @type {string}
     * @memberof UpdatedComment
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface UrlBlock
 */
export interface UrlBlock {
    /**
     * 
     * @type {number}
     * @memberof UrlBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UrlBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof UrlBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UrlBlock
     */
    'version': UrlBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof UrlBlock
     */
    'type': UrlBlockTypeEnum;
    /**
     * 
     * @type {UrlBlockVariants}
     * @memberof UrlBlock
     */
    'variants': UrlBlockVariants;
}

export const UrlBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type UrlBlockVersionEnum = typeof UrlBlockVersionEnum[keyof typeof UrlBlockVersionEnum];
export const UrlBlockTypeEnum = {
    Url: 'url'
} as const;

export type UrlBlockTypeEnum = typeof UrlBlockTypeEnum[keyof typeof UrlBlockTypeEnum];

/**
 * 
 * @export
 * @interface UrlBlockV1
 */
export interface UrlBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'version': UrlBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof UrlBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof UrlBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UrlBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof UrlBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof UrlBlockV1
     */
    'type': UrlBlockV1TypeEnum;
    /**
     * 
     * @type {UrlBlockV1Variants}
     * @memberof UrlBlockV1
     */
    'variants': UrlBlockV1Variants;
}

export const UrlBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type UrlBlockV1VersionEnum = typeof UrlBlockV1VersionEnum[keyof typeof UrlBlockV1VersionEnum];
export const UrlBlockV1TypeEnum = {
    Url: 'url'
} as const;

export type UrlBlockV1TypeEnum = typeof UrlBlockV1TypeEnum[keyof typeof UrlBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface UrlBlockV1Variants
 */
export interface UrlBlockV1Variants {
    [key: string]: UrlVariant;

}
/**
 * 
 * @export
 * @interface UrlBlockVariants
 */
export interface UrlBlockVariants {
    [key: string]: UrlVariant;

}
/**
 * 
 * @export
 * @interface UrlVariant
 */
export interface UrlVariant {
    /**
     * 
     * @type {string}
     * @memberof UrlVariant
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UrlVariant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UrlVariant
     */
    'openIn': UrlVariantOpenInEnum;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof UrlVariant
     */
    'translationStatus': TranslationStatusEnum;
}

export const UrlVariantOpenInEnum = {
    CurrentWindow: 'current-window',
    NewWindow: 'new-window'
} as const;

export type UrlVariantOpenInEnum = typeof UrlVariantOpenInEnum[keyof typeof UrlVariantOpenInEnum];

/**
 * 
 * @export
 * @interface UserAnswerResponse
 */
export interface UserAnswerResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'actionInstanceId': number | null;
    /**
     * 
     * @type {DbActionAnswerValue}
     * @memberof UserAnswerResponse
     */
    'answer': DbActionAnswerValue;
    /**
     * 
     * @type {boolean}
     * @memberof UserAnswerResponse
     */
    'completed': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerResponse
     */
    'revertedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'revertedBy': number | null;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerResponse
     */
    'language': string;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'score': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'totalScore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'totalMandatoryScore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'progress'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAnswerResponse
     */
    'mandatoryProgress'?: number;
}
/**
 * 
 * @export
 * @interface UserChange
 */
export interface UserChange {
    /**
     * 
     * @type {number}
     * @memberof UserChange
     */
    'userId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserChange
     */
    'languages': Array<string>;
}
/**
 * 
 * @export
 * @interface UsersChange
 */
export interface UsersChange {
    /**
     * 
     * @type {Array<UserChange>}
     * @memberof UsersChange
     */
    'added': Array<UserChange>;
    /**
     * 
     * @type {Array<UserChange>}
     * @memberof UsersChange
     */
    'removed': Array<UserChange>;
    /**
     * 
     * @type {Array<ChangedUser>}
     * @memberof UsersChange
     */
    'changed': Array<ChangedUser>;
}
/**
 * 
 * @export
 * @interface ValidationIssue
 */
export interface ValidationIssue {
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'message': string;
    /**
     * 
     * @type {Array<ValidationIssuePathInner>}
     * @memberof ValidationIssue
     */
    'path': Array<ValidationIssuePathInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'code': string;
}
/**
 * @type ValidationIssuePathInner
 * @export
 */
export type ValidationIssuePathInner = number | string;

/**
 * 
 * @export
 * @interface ValidationIssues
 */
export interface ValidationIssues extends Array<ValidationIssue> {
}
/**
 * 
 * @export
 * @interface VariantsChange
 */
export interface VariantsChange {
    /**
     * 
     * @type {ArticleVariants}
     * @memberof VariantsChange
     */
    'added': ArticleVariants;
    /**
     * 
     * @type {ArticleVariants}
     * @memberof VariantsChange
     */
    'removed': ArticleVariants;
    /**
     * 
     * @type {ChangedVariants}
     * @memberof VariantsChange
     */
    'changed': ChangedVariants;
}
/**
 * 
 * @export
 * @interface VideoBlock
 */
export interface VideoBlock {
    /**
     * 
     * @type {number}
     * @memberof VideoBlock
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoBlock
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof VideoBlock
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof VideoBlock
     */
    'version': VideoBlockVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoBlock
     */
    'type': VideoBlockTypeEnum;
    /**
     * 
     * @type {VideoBlockVariants}
     * @memberof VideoBlock
     */
    'variants': VideoBlockVariants;
}

export const VideoBlockVersionEnum = {
    NUMBER_1: 1
} as const;

export type VideoBlockVersionEnum = typeof VideoBlockVersionEnum[keyof typeof VideoBlockVersionEnum];
export const VideoBlockTypeEnum = {
    Video: 'video'
} as const;

export type VideoBlockTypeEnum = typeof VideoBlockTypeEnum[keyof typeof VideoBlockTypeEnum];

/**
 * 
 * @export
 * @interface VideoBlockV1
 */
export interface VideoBlockV1 {
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'position': number;
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'parentId': number | null;
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'originalBlockId': number | null;
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'version': VideoBlockV1VersionEnum;
    /**
     * 
     * @type {number}
     * @memberof VideoBlockV1
     */
    'articleId': number;
    /**
     * 
     * @type {string}
     * @memberof VideoBlockV1
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof VideoBlockV1
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof VideoBlockV1
     */
    'archiveAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoBlockV1
     */
    'type': VideoBlockV1TypeEnum;
    /**
     * 
     * @type {VideoBlockV1Variants}
     * @memberof VideoBlockV1
     */
    'variants': VideoBlockV1Variants;
}

export const VideoBlockV1VersionEnum = {
    NUMBER_1: 1
} as const;

export type VideoBlockV1VersionEnum = typeof VideoBlockV1VersionEnum[keyof typeof VideoBlockV1VersionEnum];
export const VideoBlockV1TypeEnum = {
    Video: 'video'
} as const;

export type VideoBlockV1TypeEnum = typeof VideoBlockV1TypeEnum[keyof typeof VideoBlockV1TypeEnum];

/**
 * 
 * @export
 * @interface VideoBlockV1Variants
 */
export interface VideoBlockV1Variants {
    [key: string]: ArticleFileGet;

}
/**
 * 
 * @export
 * @interface VideoBlockVariants
 */
export interface VideoBlockVariants {
    [key: string]: FileVariant;

}
/**
 * 
 * @export
 * @interface VideoEncoding
 */
export interface VideoEncoding {
    /**
     * 
     * @type {string}
     * @memberof VideoEncoding
     */
    'mpd': string;
    /**
     * 
     * @type {string}
     * @memberof VideoEncoding
     */
    'm3u8': string;
    /**
     * 
     * @type {string}
     * @memberof VideoEncoding
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface WeeklySchedule
 */
export interface WeeklySchedule {
    /**
     * 
     * @type {string}
     * @memberof WeeklySchedule
     */
    'frequency': WeeklyScheduleFrequencyEnum;
    /**
     * 
     * @type {WeeklyScheduleBoundary}
     * @memberof WeeklySchedule
     */
    'start': WeeklyScheduleBoundary;
    /**
     * 
     * @type {WeeklyScheduleBoundary}
     * @memberof WeeklySchedule
     */
    'end': WeeklyScheduleBoundary;
    /**
     * 
     * @type {Array<string>}
     * @memberof WeeklySchedule
     */
    'exceptions': Array<WeeklyScheduleExceptionsEnum>;
}

export const WeeklyScheduleFrequencyEnum = {
    Weekly: 'weekly'
} as const;

export type WeeklyScheduleFrequencyEnum = typeof WeeklyScheduleFrequencyEnum[keyof typeof WeeklyScheduleFrequencyEnum];
export const WeeklyScheduleExceptionsEnum = {
    First: 'first',
    Second: 'second',
    Third: 'third',
    Fourth: 'fourth',
    Last: 'last'
} as const;

export type WeeklyScheduleExceptionsEnum = typeof WeeklyScheduleExceptionsEnum[keyof typeof WeeklyScheduleExceptionsEnum];

/**
 * 
 * @export
 * @interface WeeklyScheduleBoundary
 */
export interface WeeklyScheduleBoundary {
    /**
     * 
     * @type {string}
     * @memberof WeeklyScheduleBoundary
     */
    'time': string;
    /**
     * 
     * @type {ScheduleWeekDay}
     * @memberof WeeklyScheduleBoundary
     */
    'day': ScheduleWeekDay;
}


/**
 * 
 * @export
 * @interface YearlySchedule
 */
export interface YearlySchedule {
    /**
     * 
     * @type {string}
     * @memberof YearlySchedule
     */
    'frequency': YearlyScheduleFrequencyEnum;
    /**
     * 
     * @type {YearlyScheduleBoundary}
     * @memberof YearlySchedule
     */
    'start': YearlyScheduleBoundary;
    /**
     * 
     * @type {YearlyScheduleBoundary}
     * @memberof YearlySchedule
     */
    'end': YearlyScheduleBoundary;
    /**
     * 
     * @type {Array<number>}
     * @memberof YearlySchedule
     */
    'exceptions': Array<number>;
}

export const YearlyScheduleFrequencyEnum = {
    Yearly: 'yearly'
} as const;

export type YearlyScheduleFrequencyEnum = typeof YearlyScheduleFrequencyEnum[keyof typeof YearlyScheduleFrequencyEnum];

/**
 * 
 * @export
 * @interface YearlyScheduleBoundary
 */
export interface YearlyScheduleBoundary {
    /**
     * 
     * @type {string}
     * @memberof YearlyScheduleBoundary
     */
    'time': string;
    /**
     * 
     * @type {ScheduleMonthDay}
     * @memberof YearlyScheduleBoundary
     */
    'day': ScheduleMonthDay;
    /**
     * 
     * @type {ScheduleMonth}
     * @memberof YearlyScheduleBoundary
     */
    'month': ScheduleMonth;
}


/**
 * 
 * @export
 * @interface YesNoTaskVariant
 */
export interface YesNoTaskVariant {
    /**
     * 
     * @type {string}
     * @memberof YesNoTaskVariant
     */
    'description': string;
    /**
     * 
     * @type {TranslationStatusEnum}
     * @memberof YesNoTaskVariant
     */
    'translationStatus': TranslationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof YesNoTaskVariant
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface YesNoTaskVariants
 */
export interface YesNoTaskVariants {
    [key: string]: YesNoTaskVariant;

}

/**
 * ActionAnswerApi - axios parameter creator
 * @export
 */
export const ActionAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get action answers
         * @param {number} actionId 
         * @param {number} [originalArticleId] 
         * @param {GetActionAnswerIncludeSharedEnum} [includeShared] 
         * @param {string} [tenants] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [score] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {number} [actionInstanceId] 
         * @param {string} [locationId] 
         * @param {string} [userId] 
         * @param {string} [answerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionAnswer: async (actionId: number, originalArticleId?: number, includeShared?: GetActionAnswerIncludeSharedEnum, tenants?: string, page?: number | null, perPage?: number, score?: SortOrderEnum, createdAt?: SortOrderEnum, actionInstanceId?: number, locationId?: string, userId?: string, answerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('getActionAnswer', 'actionId', actionId)
            const localVarPath = `/action/answer/{actionId}`
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (originalArticleId !== undefined) {
                localVarQueryParameter['originalArticleId'] = originalArticleId;
            }

            if (includeShared !== undefined) {
                localVarQueryParameter['includeShared'] = includeShared;
            }

            if (tenants !== undefined) {
                localVarQueryParameter['tenants'] = tenants;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (score !== undefined) {
                localVarQueryParameter['score'] = score;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }

            if (actionInstanceId !== undefined) {
                localVarQueryParameter['actionInstanceId'] = actionInstanceId;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (answerId !== undefined) {
                localVarQueryParameter['answerId'] = answerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit action answer
         * @param {PostActionAnswer} [postActionAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionAnswer: async (postActionAnswer?: PostActionAnswer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/action/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postActionAnswer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionAnswerApi - functional programming interface
 * @export
 */
export const ActionAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get action answers
         * @param {number} actionId 
         * @param {number} [originalArticleId] 
         * @param {GetActionAnswerIncludeSharedEnum} [includeShared] 
         * @param {string} [tenants] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [score] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {number} [actionInstanceId] 
         * @param {string} [locationId] 
         * @param {string} [userId] 
         * @param {string} [answerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionAnswer(actionId: number, originalArticleId?: number, includeShared?: GetActionAnswerIncludeSharedEnum, tenants?: string, page?: number | null, perPage?: number, score?: SortOrderEnum, createdAt?: SortOrderEnum, actionInstanceId?: number, locationId?: string, userId?: string, answerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionAnswerPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionAnswer(actionId, originalArticleId, includeShared, tenants, page, perPage, score, createdAt, actionInstanceId, locationId, userId, answerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActionAnswerApi.getActionAnswer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Submit action answer
         * @param {PostActionAnswer} [postActionAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActionAnswer(postActionAnswer?: PostActionAnswer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAnswerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActionAnswer(postActionAnswer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActionAnswerApi.postActionAnswer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ActionAnswerApi - factory interface
 * @export
 */
export const ActionAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionAnswerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get action answers
         * @param {number} actionId 
         * @param {number} [originalArticleId] 
         * @param {GetActionAnswerIncludeSharedEnum} [includeShared] 
         * @param {string} [tenants] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [score] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {number} [actionInstanceId] 
         * @param {string} [locationId] 
         * @param {string} [userId] 
         * @param {string} [answerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionAnswer(actionId: number, originalArticleId?: number, includeShared?: GetActionAnswerIncludeSharedEnum, tenants?: string, page?: number | null, perPage?: number, score?: SortOrderEnum, createdAt?: SortOrderEnum, actionInstanceId?: number, locationId?: string, userId?: string, answerId?: string, options?: any): AxiosPromise<ActionAnswerPage> {
            return localVarFp.getActionAnswer(actionId, originalArticleId, includeShared, tenants, page, perPage, score, createdAt, actionInstanceId, locationId, userId, answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit action answer
         * @param {PostActionAnswer} [postActionAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionAnswer(postActionAnswer?: PostActionAnswer, options?: any): AxiosPromise<UserAnswerResponse> {
            return localVarFp.postActionAnswer(postActionAnswer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionAnswerApi - object-oriented interface
 * @export
 * @class ActionAnswerApi
 * @extends {BaseAPI}
 */
export class ActionAnswerApi extends BaseAPI {
    /**
     * 
     * @summary Get action answers
     * @param {number} actionId 
     * @param {number} [originalArticleId] 
     * @param {GetActionAnswerIncludeSharedEnum} [includeShared] 
     * @param {string} [tenants] 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {SortOrderEnum} [score] 
     * @param {SortOrderEnum} [createdAt] 
     * @param {number} [actionInstanceId] 
     * @param {string} [locationId] 
     * @param {string} [userId] 
     * @param {string} [answerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionAnswerApi
     */
    public getActionAnswer(actionId: number, originalArticleId?: number, includeShared?: GetActionAnswerIncludeSharedEnum, tenants?: string, page?: number | null, perPage?: number, score?: SortOrderEnum, createdAt?: SortOrderEnum, actionInstanceId?: number, locationId?: string, userId?: string, answerId?: string, options?: AxiosRequestConfig) {
        return ActionAnswerApiFp(this.configuration).getActionAnswer(actionId, originalArticleId, includeShared, tenants, page, perPage, score, createdAt, actionInstanceId, locationId, userId, answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit action answer
     * @param {PostActionAnswer} [postActionAnswer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionAnswerApi
     */
    public postActionAnswer(postActionAnswer?: PostActionAnswer, options?: AxiosRequestConfig) {
        return ActionAnswerApiFp(this.configuration).postActionAnswer(postActionAnswer, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetActionAnswerIncludeSharedEnum = {
    _1: '1'
} as const;
export type GetActionAnswerIncludeSharedEnum = typeof GetActionAnswerIncludeSharedEnum[keyof typeof GetActionAnswerIncludeSharedEnum];


/**
 * ActionAnswerSummaryApi - axios parameter creator
 * @export
 */
export const ActionAnswerSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get action answers summary
         * @param {number} actionId 
         * @param {number} [actionInstanceId] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionAnswerSummary: async (actionId: number, actionInstanceId?: number, originalArticleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('getActionAnswerSummary', 'actionId', actionId)
            const localVarPath = `/action-answer-summary/{actionId}`
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (actionInstanceId !== undefined) {
                localVarQueryParameter['actionInstanceId'] = actionInstanceId;
            }

            if (originalArticleId !== undefined) {
                localVarQueryParameter['originalArticleId'] = originalArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionAnswerSummaryApi - functional programming interface
 * @export
 */
export const ActionAnswerSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionAnswerSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get action answers summary
         * @param {number} actionId 
         * @param {number} [actionInstanceId] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionAnswerSummary(actionId: number, actionInstanceId?: number, originalArticleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionAnswerSummaryResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionAnswerSummary(actionId, actionInstanceId, originalArticleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActionAnswerSummaryApi.getActionAnswerSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ActionAnswerSummaryApi - factory interface
 * @export
 */
export const ActionAnswerSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionAnswerSummaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get action answers summary
         * @param {number} actionId 
         * @param {number} [actionInstanceId] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionAnswerSummary(actionId: number, actionInstanceId?: number, originalArticleId?: number, options?: any): AxiosPromise<ActionAnswerSummaryResponseSchema> {
            return localVarFp.getActionAnswerSummary(actionId, actionInstanceId, originalArticleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionAnswerSummaryApi - object-oriented interface
 * @export
 * @class ActionAnswerSummaryApi
 * @extends {BaseAPI}
 */
export class ActionAnswerSummaryApi extends BaseAPI {
    /**
     * 
     * @summary Get action answers summary
     * @param {number} actionId 
     * @param {number} [actionInstanceId] 
     * @param {number} [originalArticleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionAnswerSummaryApi
     */
    public getActionAnswerSummary(actionId: number, actionInstanceId?: number, originalArticleId?: number, options?: AxiosRequestConfig) {
        return ActionAnswerSummaryApiFp(this.configuration).getActionAnswerSummary(actionId, actionInstanceId, originalArticleId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActionInstanceApi - axios parameter creator
 * @export
 */
export const ActionInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get action instances
         * @param {number} actionId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [start] 
         * @param {string | null} [startFrom] 
         * @param {string | null} [startTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionInstance: async (actionId: number, page?: number | null, perPage?: number, start?: SortOrderEnum, startFrom?: string | null, startTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('getActionInstance', 'actionId', actionId)
            const localVarPath = `/action/instance/{actionId}`
                .replace(`{${"actionId"}}`, encodeURIComponent(String(actionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (startFrom !== undefined) {
                localVarQueryParameter['startFrom'] = startFrom;
            }

            if (startTo !== undefined) {
                localVarQueryParameter['startTo'] = startTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionInstanceApi - functional programming interface
 * @export
 */
export const ActionInstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionInstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get action instances
         * @param {number} actionId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [start] 
         * @param {string | null} [startFrom] 
         * @param {string | null} [startTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionInstance(actionId: number, page?: number | null, perPage?: number, start?: SortOrderEnum, startFrom?: string | null, startTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionInstancePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionInstance(actionId, page, perPage, start, startFrom, startTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActionInstanceApi.getActionInstance']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ActionInstanceApi - factory interface
 * @export
 */
export const ActionInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionInstanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get action instances
         * @param {number} actionId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [start] 
         * @param {string | null} [startFrom] 
         * @param {string | null} [startTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionInstance(actionId: number, page?: number | null, perPage?: number, start?: SortOrderEnum, startFrom?: string | null, startTo?: string | null, options?: any): AxiosPromise<ActionInstancePage> {
            return localVarFp.getActionInstance(actionId, page, perPage, start, startFrom, startTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionInstanceApi - object-oriented interface
 * @export
 * @class ActionInstanceApi
 * @extends {BaseAPI}
 */
export class ActionInstanceApi extends BaseAPI {
    /**
     * 
     * @summary Get action instances
     * @param {number} actionId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {SortOrderEnum} [start] 
     * @param {string | null} [startFrom] 
     * @param {string | null} [startTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionInstanceApi
     */
    public getActionInstance(actionId: number, page?: number | null, perPage?: number, start?: SortOrderEnum, startFrom?: string | null, startTo?: string | null, options?: AxiosRequestConfig) {
        return ActionInstanceApiFp(this.configuration).getActionInstance(actionId, page, perPage, start, startFrom, startTo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArchiveArticleApi - axios parameter creator
 * @export
 */
export const ArchiveArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive articles
         * @param {ArchiveArticlesRequest} [archiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveArticle: async (archiveArticlesRequest?: ArchiveArticlesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/archive-article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveArticlesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchiveArticleApi - functional programming interface
 * @export
 */
export const ArchiveArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchiveArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive articles
         * @param {ArchiveArticlesRequest} [archiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveArticle(archiveArticlesRequest?: ArchiveArticlesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArchiveArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveArticle(archiveArticlesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArchiveArticleApi.archiveArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ArchiveArticleApi - factory interface
 * @export
 */
export const ArchiveArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchiveArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive articles
         * @param {ArchiveArticlesRequest} [archiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveArticle(archiveArticlesRequest?: ArchiveArticlesRequest, options?: any): AxiosPromise<ArchiveArticlesResponse> {
            return localVarFp.archiveArticle(archiveArticlesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchiveArticleApi - object-oriented interface
 * @export
 * @class ArchiveArticleApi
 * @extends {BaseAPI}
 */
export class ArchiveArticleApi extends BaseAPI {
    /**
     * 
     * @summary Archive articles
     * @param {ArchiveArticlesRequest} [archiveArticlesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchiveArticleApi
     */
    public archiveArticle(archiveArticlesRequest?: ArchiveArticlesRequest, options?: AxiosRequestConfig) {
        return ArchiveArticleApiFp(this.configuration).archiveArticle(archiveArticlesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete articles
         * @param {RequestBulkDeleteArticle} [requestBulkDeleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeleteArticles: async (requestBulkDeleteArticle?: RequestBulkDeleteArticle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBulkDeleteArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create article
         * @param {NewArticle} [newArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewArticle: async (newArticle?: NewArticle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete single article
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle: async (articleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('deleteArticle', 'articleId', articleId)
            const localVarPath = `/article/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get article
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {string} [timezone] 
         * @param {GetArticleLiveEnum} [live] 
         * @param {string} [language] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (articleId: number, include?: string, timezone?: string, live?: GetArticleLiveEnum, language?: string, originalArticleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticle', 'articleId', articleId)
            const localVarPath = `/article/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (originalArticleId !== undefined) {
                localVarQueryParameter['originalArticleId'] = originalArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get articles
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [title] 
         * @param {GetArticlePageStatusEnum} [status] 
         * @param {GetArticlePageRealStatusEnum} [realStatus] 
         * @param {number} [createdBy] 
         * @param {string} [channelId] 
         * @param {GetArticlePageLiveEnum} [live] 
         * @param {GetArticlePageUnseenEnum} [unseen] 
         * @param {GetArticlePageInprogressEnum} [inprogress] 
         * @param {GetArticlePageCompletedEnum} [completed] 
         * @param {GetArticlePageExcludeFeedPostsEnum} [excludeFeedPosts] 
         * @param {string} [timezone] 
         * @param {string} [categoryIds] 
         * @param {GetArticlePageShownAsEnum} [shownAs] 
         * @param {SortOrderEnum} [deadline] 
         * @param {SortOrderEnum} [archiveAt] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [lastSeenAt] 
         * @param {SortOrderEnum} [publishAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {SortOrderEnum} [progress] 
         * @param {string} [include] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlePage: async (page?: number | null, perPage?: number, title?: string, status?: GetArticlePageStatusEnum, realStatus?: GetArticlePageRealStatusEnum, createdBy?: number, channelId?: string, live?: GetArticlePageLiveEnum, unseen?: GetArticlePageUnseenEnum, inprogress?: GetArticlePageInprogressEnum, completed?: GetArticlePageCompletedEnum, excludeFeedPosts?: GetArticlePageExcludeFeedPostsEnum, timezone?: string, categoryIds?: string, shownAs?: GetArticlePageShownAsEnum, deadline?: SortOrderEnum, archiveAt?: SortOrderEnum, createdAt?: SortOrderEnum, lastSeenAt?: SortOrderEnum, publishAt?: SortOrderEnum, updatedAt?: SortOrderEnum, progress?: SortOrderEnum, include?: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (realStatus !== undefined) {
                localVarQueryParameter['realStatus'] = realStatus;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }

            if (unseen !== undefined) {
                localVarQueryParameter['unseen'] = unseen;
            }

            if (inprogress !== undefined) {
                localVarQueryParameter['inprogress'] = inprogress;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (excludeFeedPosts !== undefined) {
                localVarQueryParameter['excludeFeedPosts'] = excludeFeedPosts;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (categoryIds !== undefined) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }

            if (shownAs !== undefined) {
                localVarQueryParameter['shownAs'] = shownAs;
            }

            if (deadline !== undefined) {
                localVarQueryParameter['deadline'] = deadline;
            }

            if (archiveAt !== undefined) {
                localVarQueryParameter['archiveAt'] = archiveAt;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }

            if (lastSeenAt !== undefined) {
                localVarQueryParameter['lastSeenAt'] = lastSeenAt;
            }

            if (publishAt !== undefined) {
                localVarQueryParameter['publishAt'] = publishAt;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updatedAt'] = updatedAt;
            }

            if (progress !== undefined) {
                localVarQueryParameter['progress'] = progress;
            }

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update article
         * @param {UpdatedArticle} [updatedArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle: async (updatedArticle?: UpdatedArticle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedArticle, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete articles
         * @param {RequestBulkDeleteArticle} [requestBulkDeleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkDeleteArticles(requestBulkDeleteArticle?: RequestBulkDeleteArticle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkDeleteArticles(requestBulkDeleteArticle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.bulkDeleteArticles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create article
         * @param {NewArticle} [newArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewArticle(newArticle?: NewArticle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewArticle(newArticle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.createNewArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete single article
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArticle(articleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticle(articleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.deleteArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get article
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {string} [timezone] 
         * @param {GetArticleLiveEnum} [live] 
         * @param {string} [language] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(articleId: number, include?: string, timezone?: string, live?: GetArticleLiveEnum, language?: string, originalArticleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(articleId, include, timezone, live, language, originalArticleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.getArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get articles
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [title] 
         * @param {GetArticlePageStatusEnum} [status] 
         * @param {GetArticlePageRealStatusEnum} [realStatus] 
         * @param {number} [createdBy] 
         * @param {string} [channelId] 
         * @param {GetArticlePageLiveEnum} [live] 
         * @param {GetArticlePageUnseenEnum} [unseen] 
         * @param {GetArticlePageInprogressEnum} [inprogress] 
         * @param {GetArticlePageCompletedEnum} [completed] 
         * @param {GetArticlePageExcludeFeedPostsEnum} [excludeFeedPosts] 
         * @param {string} [timezone] 
         * @param {string} [categoryIds] 
         * @param {GetArticlePageShownAsEnum} [shownAs] 
         * @param {SortOrderEnum} [deadline] 
         * @param {SortOrderEnum} [archiveAt] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [lastSeenAt] 
         * @param {SortOrderEnum} [publishAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {SortOrderEnum} [progress] 
         * @param {string} [include] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticlePage(page?: number | null, perPage?: number, title?: string, status?: GetArticlePageStatusEnum, realStatus?: GetArticlePageRealStatusEnum, createdBy?: number, channelId?: string, live?: GetArticlePageLiveEnum, unseen?: GetArticlePageUnseenEnum, inprogress?: GetArticlePageInprogressEnum, completed?: GetArticlePageCompletedEnum, excludeFeedPosts?: GetArticlePageExcludeFeedPostsEnum, timezone?: string, categoryIds?: string, shownAs?: GetArticlePageShownAsEnum, deadline?: SortOrderEnum, archiveAt?: SortOrderEnum, createdAt?: SortOrderEnum, lastSeenAt?: SortOrderEnum, publishAt?: SortOrderEnum, updatedAt?: SortOrderEnum, progress?: SortOrderEnum, include?: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticlePage(page, perPage, title, status, realStatus, createdBy, channelId, live, unseen, inprogress, completed, excludeFeedPosts, timezone, categoryIds, shownAs, deadline, archiveAt, createdAt, lastSeenAt, publishAt, updatedAt, progress, include, language, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.getArticlePage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update article
         * @param {UpdatedArticle} [updatedArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticle(updatedArticle?: UpdatedArticle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticle(updatedArticle, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleApi.updateArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete articles
         * @param {RequestBulkDeleteArticle} [requestBulkDeleteArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkDeleteArticles(requestBulkDeleteArticle?: RequestBulkDeleteArticle, options?: any): AxiosPromise<string> {
            return localVarFp.bulkDeleteArticles(requestBulkDeleteArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create article
         * @param {NewArticle} [newArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewArticle(newArticle?: NewArticle, options?: any): AxiosPromise<Article> {
            return localVarFp.createNewArticle(newArticle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete single article
         * @param {number} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticle(articleId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteArticle(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get article
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {string} [timezone] 
         * @param {GetArticleLiveEnum} [live] 
         * @param {string} [language] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(articleId: number, include?: string, timezone?: string, live?: GetArticleLiveEnum, language?: string, originalArticleId?: number, options?: any): AxiosPromise<Article> {
            return localVarFp.getArticle(articleId, include, timezone, live, language, originalArticleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get articles
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [title] 
         * @param {GetArticlePageStatusEnum} [status] 
         * @param {GetArticlePageRealStatusEnum} [realStatus] 
         * @param {number} [createdBy] 
         * @param {string} [channelId] 
         * @param {GetArticlePageLiveEnum} [live] 
         * @param {GetArticlePageUnseenEnum} [unseen] 
         * @param {GetArticlePageInprogressEnum} [inprogress] 
         * @param {GetArticlePageCompletedEnum} [completed] 
         * @param {GetArticlePageExcludeFeedPostsEnum} [excludeFeedPosts] 
         * @param {string} [timezone] 
         * @param {string} [categoryIds] 
         * @param {GetArticlePageShownAsEnum} [shownAs] 
         * @param {SortOrderEnum} [deadline] 
         * @param {SortOrderEnum} [archiveAt] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [lastSeenAt] 
         * @param {SortOrderEnum} [publishAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {SortOrderEnum} [progress] 
         * @param {string} [include] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlePage(page?: number | null, perPage?: number, title?: string, status?: GetArticlePageStatusEnum, realStatus?: GetArticlePageRealStatusEnum, createdBy?: number, channelId?: string, live?: GetArticlePageLiveEnum, unseen?: GetArticlePageUnseenEnum, inprogress?: GetArticlePageInprogressEnum, completed?: GetArticlePageCompletedEnum, excludeFeedPosts?: GetArticlePageExcludeFeedPostsEnum, timezone?: string, categoryIds?: string, shownAs?: GetArticlePageShownAsEnum, deadline?: SortOrderEnum, archiveAt?: SortOrderEnum, createdAt?: SortOrderEnum, lastSeenAt?: SortOrderEnum, publishAt?: SortOrderEnum, updatedAt?: SortOrderEnum, progress?: SortOrderEnum, include?: string, language?: string, options?: any): AxiosPromise<ArticlePage> {
            return localVarFp.getArticlePage(page, perPage, title, status, realStatus, createdBy, channelId, live, unseen, inprogress, completed, excludeFeedPosts, timezone, categoryIds, shownAs, deadline, archiveAt, createdAt, lastSeenAt, publishAt, updatedAt, progress, include, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update article
         * @param {UpdatedArticle} [updatedArticle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle(updatedArticle?: UpdatedArticle, options?: any): AxiosPromise<Article> {
            return localVarFp.updateArticle(updatedArticle, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @summary Delete articles
     * @param {RequestBulkDeleteArticle} [requestBulkDeleteArticle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public bulkDeleteArticles(requestBulkDeleteArticle?: RequestBulkDeleteArticle, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).bulkDeleteArticles(requestBulkDeleteArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create article
     * @param {NewArticle} [newArticle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public createNewArticle(newArticle?: NewArticle, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).createNewArticle(newArticle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete single article
     * @param {number} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public deleteArticle(articleId: number, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).deleteArticle(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get article
     * @param {number} articleId 
     * @param {string} [include] 
     * @param {string} [timezone] 
     * @param {GetArticleLiveEnum} [live] 
     * @param {string} [language] 
     * @param {number} [originalArticleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticle(articleId: number, include?: string, timezone?: string, live?: GetArticleLiveEnum, language?: string, originalArticleId?: number, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticle(articleId, include, timezone, live, language, originalArticleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get articles
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {string} [title] 
     * @param {GetArticlePageStatusEnum} [status] 
     * @param {GetArticlePageRealStatusEnum} [realStatus] 
     * @param {number} [createdBy] 
     * @param {string} [channelId] 
     * @param {GetArticlePageLiveEnum} [live] 
     * @param {GetArticlePageUnseenEnum} [unseen] 
     * @param {GetArticlePageInprogressEnum} [inprogress] 
     * @param {GetArticlePageCompletedEnum} [completed] 
     * @param {GetArticlePageExcludeFeedPostsEnum} [excludeFeedPosts] 
     * @param {string} [timezone] 
     * @param {string} [categoryIds] 
     * @param {GetArticlePageShownAsEnum} [shownAs] 
     * @param {SortOrderEnum} [deadline] 
     * @param {SortOrderEnum} [archiveAt] 
     * @param {SortOrderEnum} [createdAt] 
     * @param {SortOrderEnum} [lastSeenAt] 
     * @param {SortOrderEnum} [publishAt] 
     * @param {SortOrderEnum} [updatedAt] 
     * @param {SortOrderEnum} [progress] 
     * @param {string} [include] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticlePage(page?: number | null, perPage?: number, title?: string, status?: GetArticlePageStatusEnum, realStatus?: GetArticlePageRealStatusEnum, createdBy?: number, channelId?: string, live?: GetArticlePageLiveEnum, unseen?: GetArticlePageUnseenEnum, inprogress?: GetArticlePageInprogressEnum, completed?: GetArticlePageCompletedEnum, excludeFeedPosts?: GetArticlePageExcludeFeedPostsEnum, timezone?: string, categoryIds?: string, shownAs?: GetArticlePageShownAsEnum, deadline?: SortOrderEnum, archiveAt?: SortOrderEnum, createdAt?: SortOrderEnum, lastSeenAt?: SortOrderEnum, publishAt?: SortOrderEnum, updatedAt?: SortOrderEnum, progress?: SortOrderEnum, include?: string, language?: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticlePage(page, perPage, title, status, realStatus, createdBy, channelId, live, unseen, inprogress, completed, excludeFeedPosts, timezone, categoryIds, shownAs, deadline, archiveAt, createdAt, lastSeenAt, publishAt, updatedAt, progress, include, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update article
     * @param {UpdatedArticle} [updatedArticle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public updateArticle(updatedArticle?: UpdatedArticle, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).updateArticle(updatedArticle, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetArticleLiveEnum = {
    _1: '1'
} as const;
export type GetArticleLiveEnum = typeof GetArticleLiveEnum[keyof typeof GetArticleLiveEnum];
/**
 * @export
 */
export const GetArticlePageStatusEnum = {
    Draft: 'draft',
    Published: 'published',
    Archived: 'archived',
    Inbound: 'inbound',
    Rejected: 'rejected'
} as const;
export type GetArticlePageStatusEnum = typeof GetArticlePageStatusEnum[keyof typeof GetArticlePageStatusEnum];
/**
 * @export
 */
export const GetArticlePageRealStatusEnum = {
    Archived: 'archived',
    Draft: 'draft',
    Live: 'live',
    Scheduled: 'scheduled',
    Template: 'template'
} as const;
export type GetArticlePageRealStatusEnum = typeof GetArticlePageRealStatusEnum[keyof typeof GetArticlePageRealStatusEnum];
/**
 * @export
 */
export const GetArticlePageLiveEnum = {
    _1: '1'
} as const;
export type GetArticlePageLiveEnum = typeof GetArticlePageLiveEnum[keyof typeof GetArticlePageLiveEnum];
/**
 * @export
 */
export const GetArticlePageUnseenEnum = {
    _1: '1'
} as const;
export type GetArticlePageUnseenEnum = typeof GetArticlePageUnseenEnum[keyof typeof GetArticlePageUnseenEnum];
/**
 * @export
 */
export const GetArticlePageInprogressEnum = {
    _1: '1'
} as const;
export type GetArticlePageInprogressEnum = typeof GetArticlePageInprogressEnum[keyof typeof GetArticlePageInprogressEnum];
/**
 * @export
 */
export const GetArticlePageCompletedEnum = {
    _1: '1'
} as const;
export type GetArticlePageCompletedEnum = typeof GetArticlePageCompletedEnum[keyof typeof GetArticlePageCompletedEnum];
/**
 * @export
 */
export const GetArticlePageExcludeFeedPostsEnum = {
    _1: '1'
} as const;
export type GetArticlePageExcludeFeedPostsEnum = typeof GetArticlePageExcludeFeedPostsEnum[keyof typeof GetArticlePageExcludeFeedPostsEnum];
/**
 * @export
 */
export const GetArticlePageShownAsEnum = {
    Article: 'article',
    Checklist: 'checklist',
    Training: 'training'
} as const;
export type GetArticlePageShownAsEnum = typeof GetArticlePageShownAsEnum[keyof typeof GetArticlePageShownAsEnum];


/**
 * ArticleBlockApi - axios parameter creator
 * @export
 */
export const ArticleBlockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get article block
         * @param {number} articleId 
         * @param {number} blockId 
         * @param {string} [timezone] 
         * @param {GetArticleBlockLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleBlock: async (articleId: number, blockId: number, timezone?: string, live?: GetArticleBlockLiveEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticleBlock', 'articleId', articleId)
            // verify required parameter 'blockId' is not null or undefined
            assertParamExists('getArticleBlock', 'blockId', blockId)
            const localVarPath = `/article/{articleId}/block/{blockId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"blockId"}}`, encodeURIComponent(String(blockId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleBlockApi - functional programming interface
 * @export
 */
export const ArticleBlockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleBlockApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get article block
         * @param {number} articleId 
         * @param {number} blockId 
         * @param {string} [timezone] 
         * @param {GetArticleBlockLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleBlock(articleId: number, blockId: number, timezone?: string, live?: GetArticleBlockLiveEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleBlock(articleId, blockId, timezone, live, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ArticleBlockApi.getArticleBlock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ArticleBlockApi - factory interface
 * @export
 */
export const ArticleBlockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleBlockApiFp(configuration)
    return {
        /**
         * 
         * @summary Get article block
         * @param {number} articleId 
         * @param {number} blockId 
         * @param {string} [timezone] 
         * @param {GetArticleBlockLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleBlock(articleId: number, blockId: number, timezone?: string, live?: GetArticleBlockLiveEnum, options?: any): AxiosPromise<any> {
            return localVarFp.getArticleBlock(articleId, blockId, timezone, live, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleBlockApi - object-oriented interface
 * @export
 * @class ArticleBlockApi
 * @extends {BaseAPI}
 */
export class ArticleBlockApi extends BaseAPI {
    /**
     * 
     * @summary Get article block
     * @param {number} articleId 
     * @param {number} blockId 
     * @param {string} [timezone] 
     * @param {GetArticleBlockLiveEnum} [live] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleBlockApi
     */
    public getArticleBlock(articleId: number, blockId: number, timezone?: string, live?: GetArticleBlockLiveEnum, options?: AxiosRequestConfig) {
        return ArticleBlockApiFp(this.configuration).getArticleBlock(articleId, blockId, timezone, live, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetArticleBlockLiveEnum = {
    _1: '1'
} as const;
export type GetArticleBlockLiveEnum = typeof GetArticleBlockLiveEnum[keyof typeof GetArticleBlockLiveEnum];


/**
 * BacklinksApi - axios parameter creator
 * @export
 */
export const BacklinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get backlinks for an article
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {GetBacklinksLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklinks: async (articleId: number, page?: number | null, perPage?: number, timezone?: string, live?: GetBacklinksLiveEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getBacklinks', 'articleId', articleId)
            const localVarPath = `/backlinks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (articleId !== undefined) {
                localVarQueryParameter['articleId'] = articleId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BacklinksApi - functional programming interface
 * @export
 */
export const BacklinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BacklinksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get backlinks for an article
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {GetBacklinksLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBacklinks(articleId: number, page?: number | null, perPage?: number, timezone?: string, live?: GetBacklinksLiveEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BacklinkPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBacklinks(articleId, page, perPage, timezone, live, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BacklinksApi.getBacklinks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BacklinksApi - factory interface
 * @export
 */
export const BacklinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BacklinksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get backlinks for an article
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {GetBacklinksLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBacklinks(articleId: number, page?: number | null, perPage?: number, timezone?: string, live?: GetBacklinksLiveEnum, options?: any): AxiosPromise<BacklinkPage> {
            return localVarFp.getBacklinks(articleId, page, perPage, timezone, live, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BacklinksApi - object-oriented interface
 * @export
 * @class BacklinksApi
 * @extends {BaseAPI}
 */
export class BacklinksApi extends BaseAPI {
    /**
     * 
     * @summary Get backlinks for an article
     * @param {number} articleId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {string} [timezone] 
     * @param {GetBacklinksLiveEnum} [live] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BacklinksApi
     */
    public getBacklinks(articleId: number, page?: number | null, perPage?: number, timezone?: string, live?: GetBacklinksLiveEnum, options?: AxiosRequestConfig) {
        return BacklinksApiFp(this.configuration).getBacklinks(articleId, page, perPage, timezone, live, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetBacklinksLiveEnum = {
    _1: '1'
} as const;
export type GetBacklinksLiveEnum = typeof GetBacklinksLiveEnum[keyof typeof GetBacklinksLiveEnum];


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary archive categories
         * @param {ArchiveCategoryParams} [archiveCategoryParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCategories: async (archiveCategoryParams?: ArchiveCategoryParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archiveCategoryParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create category
         * @param {NewCategory} [newCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCategory: async (newCategory?: NewCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategory', 'categoryId', categoryId)
            const localVarPath = `/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get categories
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {CategoryStatusEnum} [status] 
         * @param {string} [language] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryPage: async (page?: number | null, perPage?: number, name?: string, status?: CategoryStatusEnum, language?: string, createdAt?: SortOrderEnum, updatedAt?: SortOrderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updatedAt'] = updatedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update category
         * @param {UpdatedCategory} [updatedCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (updatedCategory?: UpdatedCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary archive categories
         * @param {ArchiveCategoryParams} [archiveCategoryParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveCategories(archiveCategoryParams?: ArchiveCategoryParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCategories(archiveCategoryParams, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryApi.archiveCategories']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create category
         * @param {NewCategory} [newCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewCategory(newCategory?: NewCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewCategory(newCategory, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryApi.createNewCategory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(categoryId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryApi.getCategory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get categories
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {CategoryStatusEnum} [status] 
         * @param {string} [language] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryPage(page?: number | null, perPage?: number, name?: string, status?: CategoryStatusEnum, language?: string, createdAt?: SortOrderEnum, updatedAt?: SortOrderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryPage(page, perPage, name, status, language, createdAt, updatedAt, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryApi.getCategoryPage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update category
         * @param {UpdatedCategory} [updatedCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(updatedCategory?: UpdatedCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(updatedCategory, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryApi.updateCategory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary archive categories
         * @param {ArchiveCategoryParams} [archiveCategoryParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveCategories(archiveCategoryParams?: ArchiveCategoryParams, options?: any): AxiosPromise<string> {
            return localVarFp.archiveCategories(archiveCategoryParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create category
         * @param {NewCategory} [newCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewCategory(newCategory?: NewCategory, options?: any): AxiosPromise<GetCategory> {
            return localVarFp.createNewCategory(newCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get category
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(categoryId: number, options?: any): AxiosPromise<GetCategory> {
            return localVarFp.getCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get categories
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {CategoryStatusEnum} [status] 
         * @param {string} [language] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {SortOrderEnum} [updatedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryPage(page?: number | null, perPage?: number, name?: string, status?: CategoryStatusEnum, language?: string, createdAt?: SortOrderEnum, updatedAt?: SortOrderEnum, options?: any): AxiosPromise<CategoriesPage> {
            return localVarFp.getCategoryPage(page, perPage, name, status, language, createdAt, updatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update category
         * @param {UpdatedCategory} [updatedCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(updatedCategory?: UpdatedCategory, options?: any): AxiosPromise<GetCategory> {
            return localVarFp.updateCategory(updatedCategory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @summary archive categories
     * @param {ArchiveCategoryParams} [archiveCategoryParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public archiveCategories(archiveCategoryParams?: ArchiveCategoryParams, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).archiveCategories(archiveCategoryParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create category
     * @param {NewCategory} [newCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public createNewCategory(newCategory?: NewCategory, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).createNewCategory(newCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get category
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategory(categoryId: number, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get categories
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {string} [name] 
     * @param {CategoryStatusEnum} [status] 
     * @param {string} [language] 
     * @param {SortOrderEnum} [createdAt] 
     * @param {SortOrderEnum} [updatedAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategoryPage(page?: number | null, perPage?: number, name?: string, status?: CategoryStatusEnum, language?: string, createdAt?: SortOrderEnum, updatedAt?: SortOrderEnum, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getCategoryPage(page, perPage, name, status, language, createdAt, updatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update category
     * @param {UpdatedCategory} [updatedCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public updateCategory(updatedCategory?: UpdatedCategory, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).updateCategory(updatedCategory, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CategoryUnarchiveApi - axios parameter creator
 * @export
 */
export const CategoryUnarchiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unarchive categories
         * @param {UnarchiveCategoriesRequest} [unarchiveCategoriesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCategory: async (unarchiveCategoriesRequest?: UnarchiveCategoriesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/unarchive-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unarchiveCategoriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryUnarchiveApi - functional programming interface
 * @export
 */
export const CategoryUnarchiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryUnarchiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unarchive categories
         * @param {UnarchiveCategoriesRequest} [unarchiveCategoriesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveCategory(unarchiveCategoriesRequest?: UnarchiveCategoriesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnarchiveCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveCategory(unarchiveCategoriesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CategoryUnarchiveApi.unarchiveCategory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CategoryUnarchiveApi - factory interface
 * @export
 */
export const CategoryUnarchiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryUnarchiveApiFp(configuration)
    return {
        /**
         * 
         * @summary Unarchive categories
         * @param {UnarchiveCategoriesRequest} [unarchiveCategoriesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveCategory(unarchiveCategoriesRequest?: UnarchiveCategoriesRequest, options?: any): AxiosPromise<UnarchiveCategoriesResponse> {
            return localVarFp.unarchiveCategory(unarchiveCategoriesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryUnarchiveApi - object-oriented interface
 * @export
 * @class CategoryUnarchiveApi
 * @extends {BaseAPI}
 */
export class CategoryUnarchiveApi extends BaseAPI {
    /**
     * 
     * @summary Unarchive categories
     * @param {UnarchiveCategoriesRequest} [unarchiveCategoriesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryUnarchiveApi
     */
    public unarchiveCategory(unarchiveCategoriesRequest?: UnarchiveCategoriesRequest, options?: AxiosRequestConfig) {
        return CategoryUnarchiveApiFp(this.configuration).unarchiveCategory(unarchiveCategoriesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChangeLogApi - axios parameter creator
 * @export
 */
export const ChangeLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Changelog
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {number} [userId] 
         * @param {SortOrderEnum} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeLog: async (articleId: number, page?: number | null, perPage?: number, userId?: number, timestamp?: SortOrderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getChangeLog', 'articleId', articleId)
            const localVarPath = `/changelog/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeLogApi - functional programming interface
 * @export
 */
export const ChangeLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Changelog
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {number} [userId] 
         * @param {SortOrderEnum} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeLog(articleId: number, page?: number | null, perPage?: number, userId?: number, timestamp?: SortOrderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangelogPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeLog(articleId, page, perPage, userId, timestamp, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChangeLogApi.getChangeLog']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChangeLogApi - factory interface
 * @export
 */
export const ChangeLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Changelog
         * @param {number} articleId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {number} [userId] 
         * @param {SortOrderEnum} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeLog(articleId: number, page?: number | null, perPage?: number, userId?: number, timestamp?: SortOrderEnum, options?: any): AxiosPromise<ChangelogPage> {
            return localVarFp.getChangeLog(articleId, page, perPage, userId, timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangeLogApi - object-oriented interface
 * @export
 * @class ChangeLogApi
 * @extends {BaseAPI}
 */
export class ChangeLogApi extends BaseAPI {
    /**
     * 
     * @summary Get Changelog
     * @param {number} articleId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {number} [userId] 
     * @param {SortOrderEnum} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeLogApi
     */
    public getChangeLog(articleId: number, page?: number | null, perPage?: number, userId?: number, timestamp?: SortOrderEnum, options?: AxiosRequestConfig) {
        return ChangeLogApiFp(this.configuration).getChangeLog(articleId, page, perPage, userId, timestamp, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChannelApi - axios parameter creator
 * @export
 */
export const ChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create channel
         * @param {NewChannel} [newChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewChannel: async (newChannel?: NewChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete single channel
         * @param {number} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannel: async (channelId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('deleteChannel', 'channelId', channelId)
            const localVarPath = `/channel/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get channel
         * @param {number} channelId 
         * @param {GetChannelLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel: async (channelId: number, live?: GetChannelLiveEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getChannel', 'channelId', channelId)
            const localVarPath = `/channel/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get channels
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {GetChannelPageShownAsEnum} [shownAs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPage: async (page?: number | null, perPage?: number, ranking?: SortOrderEnum, shownAs?: GetChannelPageShownAsEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (ranking !== undefined) {
                localVarQueryParameter['ranking'] = ranking;
            }

            if (shownAs !== undefined) {
                localVarQueryParameter['shownAs'] = shownAs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update channel
         * @param {UpdatedChannel} [updatedChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel: async (updatedChannel?: UpdatedChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedChannel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelApi - functional programming interface
 * @export
 */
export const ChannelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChannelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create channel
         * @param {NewChannel} [newChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewChannel(newChannel?: NewChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Channel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewChannel(newChannel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.createNewChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete single channel
         * @param {number} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannel(channelId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChannel(channelId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.deleteChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get channel
         * @param {number} channelId 
         * @param {GetChannelLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannel(channelId: number, live?: GetChannelLiveEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Channel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannel(channelId, live, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.getChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get channels
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {GetChannelPageShownAsEnum} [shownAs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelPage(page?: number | null, perPage?: number, ranking?: SortOrderEnum, shownAs?: GetChannelPageShownAsEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelPage(page, perPage, ranking, shownAs, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.getChannelPage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update channel
         * @param {UpdatedChannel} [updatedChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChannel(updatedChannel?: UpdatedChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Channel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChannel(updatedChannel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.updateChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChannelApi - factory interface
 * @export
 */
export const ChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChannelApiFp(configuration)
    return {
        /**
         * 
         * @summary Create channel
         * @param {NewChannel} [newChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewChannel(newChannel?: NewChannel, options?: any): AxiosPromise<Channel> {
            return localVarFp.createNewChannel(newChannel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete single channel
         * @param {number} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannel(channelId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteChannel(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get channel
         * @param {number} channelId 
         * @param {GetChannelLiveEnum} [live] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel(channelId: number, live?: GetChannelLiveEnum, options?: any): AxiosPromise<Channel> {
            return localVarFp.getChannel(channelId, live, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get channels
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {GetChannelPageShownAsEnum} [shownAs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPage(page?: number | null, perPage?: number, ranking?: SortOrderEnum, shownAs?: GetChannelPageShownAsEnum, options?: any): AxiosPromise<ChannelPage> {
            return localVarFp.getChannelPage(page, perPage, ranking, shownAs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update channel
         * @param {UpdatedChannel} [updatedChannel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel(updatedChannel?: UpdatedChannel, options?: any): AxiosPromise<Channel> {
            return localVarFp.updateChannel(updatedChannel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelApi - object-oriented interface
 * @export
 * @class ChannelApi
 * @extends {BaseAPI}
 */
export class ChannelApi extends BaseAPI {
    /**
     * 
     * @summary Create channel
     * @param {NewChannel} [newChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public createNewChannel(newChannel?: NewChannel, options?: AxiosRequestConfig) {
        return ChannelApiFp(this.configuration).createNewChannel(newChannel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete single channel
     * @param {number} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public deleteChannel(channelId: number, options?: AxiosRequestConfig) {
        return ChannelApiFp(this.configuration).deleteChannel(channelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get channel
     * @param {number} channelId 
     * @param {GetChannelLiveEnum} [live] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public getChannel(channelId: number, live?: GetChannelLiveEnum, options?: AxiosRequestConfig) {
        return ChannelApiFp(this.configuration).getChannel(channelId, live, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get channels
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {SortOrderEnum} [ranking] 
     * @param {GetChannelPageShownAsEnum} [shownAs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public getChannelPage(page?: number | null, perPage?: number, ranking?: SortOrderEnum, shownAs?: GetChannelPageShownAsEnum, options?: AxiosRequestConfig) {
        return ChannelApiFp(this.configuration).getChannelPage(page, perPage, ranking, shownAs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update channel
     * @param {UpdatedChannel} [updatedChannel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public updateChannel(updatedChannel?: UpdatedChannel, options?: AxiosRequestConfig) {
        return ChannelApiFp(this.configuration).updateChannel(updatedChannel, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetChannelLiveEnum = {
    _1: '1'
} as const;
export type GetChannelLiveEnum = typeof GetChannelLiveEnum[keyof typeof GetChannelLiveEnum];
/**
 * @export
 */
export const GetChannelPageShownAsEnum = {
    Feed: 'feed',
    Cards: 'cards'
} as const;
export type GetChannelPageShownAsEnum = typeof GetChannelPageShownAsEnum[keyof typeof GetChannelPageShownAsEnum];


/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create comment
         * @param {NewComment} [newComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (newComment?: NewComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete comment
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/comment/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comment by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById: async (commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentById', 'commentId', commentId)
            const localVarPath = `/comment/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comments
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: async (objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, createdAt?: SortOrderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('getComments', 'objectType', objectType)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getComments', 'objectId', objectId)
            const localVarPath = `/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['objectId'] = objectId;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update comment
         * @param {number} commentId 
         * @param {UpdatedComment} [updatedComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (commentId: number, updatedComment?: UpdatedComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            const localVarPath = `/comment/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatedComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create comment
         * @param {NewComment} [newComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(newComment?: NewComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(newComment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentApi.createComment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete comment
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentApi.deleteComment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get comment by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentById(commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentById(commentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentApi.getCommentById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get comments
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComments(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, createdAt?: SortOrderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(objectType, objectId, page, perPage, createdAt, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentApi.getComments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update comment
         * @param {number} commentId 
         * @param {UpdatedComment} [updatedComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(commentId: number, updatedComment?: UpdatedComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(commentId, updatedComment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentApi.updateComment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create comment
         * @param {NewComment} [newComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(newComment?: NewComment, options?: any): AxiosPromise<Comment> {
            return localVarFp.createComment(newComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete comment
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comment by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentById(commentId: number, options?: any): AxiosPromise<ExtendedComment> {
            return localVarFp.getCommentById(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comments
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, createdAt?: SortOrderEnum, options?: any): AxiosPromise<CommentPage> {
            return localVarFp.getComments(objectType, objectId, page, perPage, createdAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update comment
         * @param {number} commentId 
         * @param {UpdatedComment} [updatedComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(commentId: number, updatedComment?: UpdatedComment, options?: any): AxiosPromise<Comment> {
            return localVarFp.updateComment(commentId, updatedComment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @summary Create comment
     * @param {NewComment} [newComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public createComment(newComment?: NewComment, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).createComment(newComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete comment
     * @param {number} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public deleteComment(commentId: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).deleteComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comment by id
     * @param {number} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentById(commentId: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getCommentById(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comments
     * @param {ReactionObjectTypeEnum} objectType 
     * @param {number} objectId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {SortOrderEnum} [createdAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComments(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, createdAt?: SortOrderEnum, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).getComments(objectType, objectId, page, perPage, createdAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update comment
     * @param {number} commentId 
     * @param {UpdatedComment} [updatedComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public updateComment(commentId: number, updatedComment?: UpdatedComment, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).updateComment(commentId, updatedComment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommentAndParentsApi - axios parameter creator
 * @export
 */
export const CommentAndParentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get comment and parents by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentAndParentsById: async (commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getCommentAndParentsById', 'commentId', commentId)
            const localVarPath = `/comment-and-parents/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentAndParentsApi - functional programming interface
 * @export
 */
export const CommentAndParentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentAndParentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get comment and parents by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentAndParentsById(commentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentAndParentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentAndParentsById(commentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommentAndParentsApi.getCommentAndParentsById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommentAndParentsApi - factory interface
 * @export
 */
export const CommentAndParentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentAndParentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get comment and parents by id
         * @param {number} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentAndParentsById(commentId: number, options?: any): AxiosPromise<CommentAndParentsResponse> {
            return localVarFp.getCommentAndParentsById(commentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentAndParentsApi - object-oriented interface
 * @export
 * @class CommentAndParentsApi
 * @extends {BaseAPI}
 */
export class CommentAndParentsApi extends BaseAPI {
    /**
     * 
     * @summary Get comment and parents by id
     * @param {number} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentAndParentsApi
     */
    public getCommentAndParentsById(commentId: number, options?: AxiosRequestConfig) {
        return CommentAndParentsApiFp(this.configuration).getCommentAndParentsById(commentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConvertArticleToHtmlApi - axios parameter creator
 * @export
 */
export const ConvertArticleToHtmlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert Article to HTML
         * @param {ConvertArticleToHtmlRequest} [convertArticleToHtmlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertArticleToHtml: async (convertArticleToHtmlRequest?: ConvertArticleToHtmlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/convert-article-to-html`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertArticleToHtmlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConvertArticleToHtmlApi - functional programming interface
 * @export
 */
export const ConvertArticleToHtmlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConvertArticleToHtmlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Convert Article to HTML
         * @param {ConvertArticleToHtmlRequest} [convertArticleToHtmlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertArticleToHtml(convertArticleToHtmlRequest?: ConvertArticleToHtmlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertArticleToHtml(convertArticleToHtmlRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConvertArticleToHtmlApi.convertArticleToHtml']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConvertArticleToHtmlApi - factory interface
 * @export
 */
export const ConvertArticleToHtmlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConvertArticleToHtmlApiFp(configuration)
    return {
        /**
         * 
         * @summary Convert Article to HTML
         * @param {ConvertArticleToHtmlRequest} [convertArticleToHtmlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertArticleToHtml(convertArticleToHtmlRequest?: ConvertArticleToHtmlRequest, options?: any): AxiosPromise<string> {
            return localVarFp.convertArticleToHtml(convertArticleToHtmlRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConvertArticleToHtmlApi - object-oriented interface
 * @export
 * @class ConvertArticleToHtmlApi
 * @extends {BaseAPI}
 */
export class ConvertArticleToHtmlApi extends BaseAPI {
    /**
     * 
     * @summary Convert Article to HTML
     * @param {ConvertArticleToHtmlRequest} [convertArticleToHtmlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvertArticleToHtmlApi
     */
    public convertArticleToHtml(convertArticleToHtmlRequest?: ConvertArticleToHtmlRequest, options?: AxiosRequestConfig) {
        return ConvertArticleToHtmlApiFp(this.configuration).convertArticleToHtml(convertArticleToHtmlRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConvertHtmlToArticleApi - axios parameter creator
 * @export
 */
export const ConvertHtmlToArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert HTML to Article
         * @param {RequestConvertHtmlToArticleSchema} [requestConvertHtmlToArticleSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertHtmlToArticle: async (requestConvertHtmlToArticleSchema?: RequestConvertHtmlToArticleSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/convert-html-to-article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestConvertHtmlToArticleSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConvertHtmlToArticleApi - functional programming interface
 * @export
 */
export const ConvertHtmlToArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConvertHtmlToArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Convert HTML to Article
         * @param {RequestConvertHtmlToArticleSchema} [requestConvertHtmlToArticleSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertHtmlToArticle(requestConvertHtmlToArticleSchema?: RequestConvertHtmlToArticleSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseConvertHtmlToArticleSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertHtmlToArticle(requestConvertHtmlToArticleSchema, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConvertHtmlToArticleApi.convertHtmlToArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConvertHtmlToArticleApi - factory interface
 * @export
 */
export const ConvertHtmlToArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConvertHtmlToArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Convert HTML to Article
         * @param {RequestConvertHtmlToArticleSchema} [requestConvertHtmlToArticleSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertHtmlToArticle(requestConvertHtmlToArticleSchema?: RequestConvertHtmlToArticleSchema, options?: any): AxiosPromise<ResponseConvertHtmlToArticleSchema> {
            return localVarFp.convertHtmlToArticle(requestConvertHtmlToArticleSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConvertHtmlToArticleApi - object-oriented interface
 * @export
 * @class ConvertHtmlToArticleApi
 * @extends {BaseAPI}
 */
export class ConvertHtmlToArticleApi extends BaseAPI {
    /**
     * 
     * @summary Convert HTML to Article
     * @param {RequestConvertHtmlToArticleSchema} [requestConvertHtmlToArticleSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConvertHtmlToArticleApi
     */
    public convertHtmlToArticle(requestConvertHtmlToArticleSchema?: RequestConvertHtmlToArticleSchema, options?: AxiosRequestConfig) {
        return ConvertHtmlToArticleApiFp(this.configuration).convertHtmlToArticle(requestConvertHtmlToArticleSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CopyArticleApi - axios parameter creator
 * @export
 */
export const CopyArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Copy Article
         * @param {CopyArticleRequest} [copyArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyArticle: async (copyArticleRequest?: CopyArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/copy-article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CopyArticleApi - functional programming interface
 * @export
 */
export const CopyArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CopyArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Copy Article
         * @param {CopyArticleRequest} [copyArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyArticle(copyArticleRequest?: CopyArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyArticle(copyArticleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CopyArticleApi.copyArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CopyArticleApi - factory interface
 * @export
 */
export const CopyArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CopyArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Copy Article
         * @param {CopyArticleRequest} [copyArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyArticle(copyArticleRequest?: CopyArticleRequest, options?: any): AxiosPromise<CopyArticleResponse> {
            return localVarFp.copyArticle(copyArticleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CopyArticleApi - object-oriented interface
 * @export
 * @class CopyArticleApi
 * @extends {BaseAPI}
 */
export class CopyArticleApi extends BaseAPI {
    /**
     * 
     * @summary Copy Article
     * @param {CopyArticleRequest} [copyArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CopyArticleApi
     */
    public copyArticle(copyArticleRequest?: CopyArticleRequest, options?: AxiosRequestConfig) {
        return CopyArticleApiFp(this.configuration).copyArticle(copyArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Submit event
         * @param {NewEvent} [newEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEvent: async (newEvent?: NewEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Submit event
         * @param {NewEvent} [newEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEvent(newEvent?: NewEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEvent(newEvent, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EventApi.postEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary Submit event
         * @param {NewEvent} [newEvent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEvent(newEvent?: NewEvent, options?: any): AxiosPromise<void> {
            return localVarFp.postEvent(newEvent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary Submit event
     * @param {NewEvent} [newEvent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public postEvent(newEvent?: NewEvent, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).postEvent(newEvent, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {GetFileResponseEnum} [response] 
         * @param {string | null} [size] 
         * @param {string | null} [path] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (fileTenant: string, fileId: string, fileName: string, response?: GetFileResponseEnum, size?: string | null, path?: string | null, originalArticleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileTenant' is not null or undefined
            assertParamExists('getFile', 'fileTenant', fileTenant)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFile', 'fileId', fileId)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getFile', 'fileName', fileName)
            const localVarPath = `/file/{fileTenant}/{fileId}/{fileName}`
                .replace(`{${"fileTenant"}}`, encodeURIComponent(String(fileTenant)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (response !== undefined) {
                localVarQueryParameter['response'] = response;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (originalArticleId !== undefined) {
                localVarQueryParameter['originalArticleId'] = originalArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Head a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {string | null} [size] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headFile: async (fileTenant: string, fileId: string, fileName: string, size?: string | null, originalArticleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileTenant' is not null or undefined
            assertParamExists('headFile', 'fileTenant', fileTenant)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('headFile', 'fileId', fileId)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('headFile', 'fileName', fileName)
            const localVarPath = `/file/{fileTenant}/{fileId}/{fileName}`
                .replace(`{${"fileTenant"}}`, encodeURIComponent(String(fileTenant)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (originalArticleId !== undefined) {
                localVarQueryParameter['originalArticleId'] = originalArticleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {GetFileResponseEnum} [response] 
         * @param {string | null} [size] 
         * @param {string | null} [path] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(fileTenant: string, fileId: string, fileName: string, response?: GetFileResponseEnum, size?: string | null, path?: string | null, originalArticleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(fileTenant, fileId, fileName, response, size, path, originalArticleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.getFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Head a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {string | null} [size] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headFile(fileTenant: string, fileId: string, fileName: string, size?: string | null, originalArticleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.headFile(fileTenant, fileId, fileName, size, originalArticleId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.headFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {GetFileResponseEnum} [response] 
         * @param {string | null} [size] 
         * @param {string | null} [path] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(fileTenant: string, fileId: string, fileName: string, response?: GetFileResponseEnum, size?: string | null, path?: string | null, originalArticleId?: number, options?: any): AxiosPromise<GetFile200Response> {
            return localVarFp.getFile(fileTenant, fileId, fileName, response, size, path, originalArticleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Head a file
         * @param {string} fileTenant 
         * @param {string} fileId 
         * @param {string} fileName 
         * @param {string | null} [size] 
         * @param {number} [originalArticleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headFile(fileTenant: string, fileId: string, fileName: string, size?: string | null, originalArticleId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.headFile(fileTenant, fileId, fileName, size, originalArticleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Get a file
     * @param {string} fileTenant 
     * @param {string} fileId 
     * @param {string} fileName 
     * @param {GetFileResponseEnum} [response] 
     * @param {string | null} [size] 
     * @param {string | null} [path] 
     * @param {number} [originalArticleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFile(fileTenant: string, fileId: string, fileName: string, response?: GetFileResponseEnum, size?: string | null, path?: string | null, originalArticleId?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFile(fileTenant, fileId, fileName, response, size, path, originalArticleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Head a file
     * @param {string} fileTenant 
     * @param {string} fileId 
     * @param {string} fileName 
     * @param {string | null} [size] 
     * @param {number} [originalArticleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public headFile(fileTenant: string, fileId: string, fileName: string, size?: string | null, originalArticleId?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).headFile(fileTenant, fileId, fileName, size, originalArticleId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetFileResponseEnum = {
    Redirect: 'redirect',
    Url: 'url'
} as const;
export type GetFileResponseEnum = typeof GetFileResponseEnum[keyof typeof GetFileResponseEnum];


/**
 * FileSlotApi - axios parameter creator
 * @export
 */
export const FileSlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a file slot
         * @param {FileSlot} [fileSlot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileSlot: async (fileSlot?: FileSlot, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/file/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileSlot, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileSlotApi - functional programming interface
 * @export
 */
export const FileSlotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileSlotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a file slot
         * @param {FileSlot} [fileSlot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileSlot(fileSlot?: FileSlot, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedFileSlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileSlot(fileSlot, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileSlotApi.createFileSlot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileSlotApi - factory interface
 * @export
 */
export const FileSlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileSlotApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a file slot
         * @param {FileSlot} [fileSlot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileSlot(fileSlot?: FileSlot, options?: any): AxiosPromise<CreatedFileSlot> {
            return localVarFp.createFileSlot(fileSlot, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileSlotApi - object-oriented interface
 * @export
 * @class FileSlotApi
 * @extends {BaseAPI}
 */
export class FileSlotApi extends BaseAPI {
    /**
     * 
     * @summary Create a file slot
     * @param {FileSlot} [fileSlot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileSlotApi
     */
    public createFileSlot(fileSlot?: FileSlot, options?: AxiosRequestConfig) {
        return FileSlotApiFp(this.configuration).createFileSlot(fileSlot, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HealthcheckApi.checkHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHealth(options?: any): AxiosPromise<string> {
            return localVarFp.checkHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public checkHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).checkHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReactionApi - axios parameter creator
 * @export
 */
export const ReactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create reaction
         * @param {NewReaction} [newReaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReaction: async (newReaction?: NewReaction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newReaction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete reaction by emoji
         * @param {DeleteReactionByEmojiParams} [deleteReactionByEmojiParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReactionByEmoji: async (deleteReactionByEmojiParams?: DeleteReactionByEmojiParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteReactionByEmojiParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete reaction by id
         * @param {number} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReactionById: async (reactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reactionId' is not null or undefined
            assertParamExists('deleteReactionById', 'reactionId', reactionId)
            const localVarPath = `/reaction/{reactionId}`
                .replace(`{${"reactionId"}}`, encodeURIComponent(String(reactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get reactions
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {EmojiEnum} [emoji] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactions: async (objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, emoji?: EmojiEnum, createdAt?: SortOrderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('getReactions', 'objectType', objectType)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getReactions', 'objectId', objectId)
            const localVarPath = `/reaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (objectType !== undefined) {
                localVarQueryParameter['objectType'] = objectType;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['objectId'] = objectId;
            }

            if (emoji !== undefined) {
                localVarQueryParameter['emoji'] = emoji;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = createdAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReactionApi - functional programming interface
 * @export
 */
export const ReactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create reaction
         * @param {NewReaction} [newReaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReaction(newReaction?: NewReaction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReaction(newReaction, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReactionApi.createReaction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete reaction by emoji
         * @param {DeleteReactionByEmojiParams} [deleteReactionByEmojiParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReactionByEmoji(deleteReactionByEmojiParams?: DeleteReactionByEmojiParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReactionByEmoji(deleteReactionByEmojiParams, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReactionApi.deleteReactionByEmoji']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete reaction by id
         * @param {number} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReactionById(reactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReactionById(reactionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReactionApi.deleteReactionById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get reactions
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {EmojiEnum} [emoji] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReactions(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, emoji?: EmojiEnum, createdAt?: SortOrderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReactionPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReactions(objectType, objectId, page, perPage, emoji, createdAt, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReactionApi.getReactions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReactionApi - factory interface
 * @export
 */
export const ReactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create reaction
         * @param {NewReaction} [newReaction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReaction(newReaction?: NewReaction, options?: any): AxiosPromise<ReactionSummary> {
            return localVarFp.createReaction(newReaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete reaction by emoji
         * @param {DeleteReactionByEmojiParams} [deleteReactionByEmojiParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReactionByEmoji(deleteReactionByEmojiParams?: DeleteReactionByEmojiParams, options?: any): AxiosPromise<ReactionSummary> {
            return localVarFp.deleteReactionByEmoji(deleteReactionByEmojiParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete reaction by id
         * @param {number} reactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReactionById(reactionId: number, options?: any): AxiosPromise<ReactionSummary> {
            return localVarFp.deleteReactionById(reactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get reactions
         * @param {ReactionObjectTypeEnum} objectType 
         * @param {number} objectId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {EmojiEnum} [emoji] 
         * @param {SortOrderEnum} [createdAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReactions(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, emoji?: EmojiEnum, createdAt?: SortOrderEnum, options?: any): AxiosPromise<ReactionPage> {
            return localVarFp.getReactions(objectType, objectId, page, perPage, emoji, createdAt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReactionApi - object-oriented interface
 * @export
 * @class ReactionApi
 * @extends {BaseAPI}
 */
export class ReactionApi extends BaseAPI {
    /**
     * 
     * @summary Create reaction
     * @param {NewReaction} [newReaction] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionApi
     */
    public createReaction(newReaction?: NewReaction, options?: AxiosRequestConfig) {
        return ReactionApiFp(this.configuration).createReaction(newReaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete reaction by emoji
     * @param {DeleteReactionByEmojiParams} [deleteReactionByEmojiParams] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionApi
     */
    public deleteReactionByEmoji(deleteReactionByEmojiParams?: DeleteReactionByEmojiParams, options?: AxiosRequestConfig) {
        return ReactionApiFp(this.configuration).deleteReactionByEmoji(deleteReactionByEmojiParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete reaction by id
     * @param {number} reactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionApi
     */
    public deleteReactionById(reactionId: number, options?: AxiosRequestConfig) {
        return ReactionApiFp(this.configuration).deleteReactionById(reactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get reactions
     * @param {ReactionObjectTypeEnum} objectType 
     * @param {number} objectId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {EmojiEnum} [emoji] 
     * @param {SortOrderEnum} [createdAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactionApi
     */
    public getReactions(objectType: ReactionObjectTypeEnum, objectId: number, page?: number | null, perPage?: number, emoji?: EmojiEnum, createdAt?: SortOrderEnum, options?: AxiosRequestConfig) {
        return ReactionApiFp(this.configuration).getReactions(objectType, objectId, page, perPage, emoji, createdAt, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RelevantCategoriesApi - axios parameter creator
 * @export
 */
export const RelevantCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get relevant categories
         * @param {string} channelId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevantCategoriesPage: async (channelId: string, page?: number | null, perPage?: number, timezone?: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getRelevantCategoriesPage', 'channelId', channelId)
            const localVarPath = `/relevant-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelevantCategoriesApi - functional programming interface
 * @export
 */
export const RelevantCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelevantCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get relevant categories
         * @param {string} channelId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelevantCategoriesPage(channelId: string, page?: number | null, perPage?: number, timezone?: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelevantCategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelevantCategoriesPage(channelId, page, perPage, timezone, language, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RelevantCategoriesApi.getRelevantCategoriesPage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RelevantCategoriesApi - factory interface
 * @export
 */
export const RelevantCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelevantCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get relevant categories
         * @param {string} channelId 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {string} [timezone] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevantCategoriesPage(channelId: string, page?: number | null, perPage?: number, timezone?: string, language?: string, options?: any): AxiosPromise<RelevantCategoryPage> {
            return localVarFp.getRelevantCategoriesPage(channelId, page, perPage, timezone, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelevantCategoriesApi - object-oriented interface
 * @export
 * @class RelevantCategoriesApi
 * @extends {BaseAPI}
 */
export class RelevantCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Get relevant categories
     * @param {string} channelId 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {string} [timezone] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelevantCategoriesApi
     */
    public getRelevantCategoriesPage(channelId: string, page?: number | null, perPage?: number, timezone?: string, language?: string, options?: AxiosRequestConfig) {
        return RelevantCategoriesApiFp(this.configuration).getRelevantCategoriesPage(channelId, page, perPage, timezone, language, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RelevantChannelApi - axios parameter creator
 * @export
 */
export const RelevantChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get relevant channels
         * @param {string} timezone 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevantChannelPage: async (timezone: string, page?: number | null, perPage?: number, ranking?: SortOrderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('getRelevantChannelPage', 'timezone', timezone)
            const localVarPath = `/relevant-channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (ranking !== undefined) {
                localVarQueryParameter['ranking'] = ranking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RelevantChannelApi - functional programming interface
 * @export
 */
export const RelevantChannelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RelevantChannelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get relevant channels
         * @param {string} timezone 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelevantChannelPage(timezone: string, page?: number | null, perPage?: number, ranking?: SortOrderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelevantChannelPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelevantChannelPage(timezone, page, perPage, ranking, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RelevantChannelApi.getRelevantChannelPage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RelevantChannelApi - factory interface
 * @export
 */
export const RelevantChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RelevantChannelApiFp(configuration)
    return {
        /**
         * 
         * @summary Get relevant channels
         * @param {string} timezone 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {SortOrderEnum} [ranking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelevantChannelPage(timezone: string, page?: number | null, perPage?: number, ranking?: SortOrderEnum, options?: any): AxiosPromise<RelevantChannelPage> {
            return localVarFp.getRelevantChannelPage(timezone, page, perPage, ranking, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RelevantChannelApi - object-oriented interface
 * @export
 * @class RelevantChannelApi
 * @extends {BaseAPI}
 */
export class RelevantChannelApi extends BaseAPI {
    /**
     * 
     * @summary Get relevant channels
     * @param {string} timezone 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {SortOrderEnum} [ranking] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelevantChannelApi
     */
    public getRelevantChannelPage(timezone: string, page?: number | null, perPage?: number, ranking?: SortOrderEnum, options?: AxiosRequestConfig) {
        return RelevantChannelApiFp(this.configuration).getRelevantChannelPage(timezone, page, perPage, ranking, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RequiredScopeApi - axios parameter creator
 * @export
 */
export const RequiredScopeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary RequiredScope
         * @param {string} [articleId] 
         * @param {string} [channelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requiredScope: async (articleId?: string, channelId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/required-scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (articleId !== undefined) {
                localVarQueryParameter['articleId'] = articleId;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequiredScopeApi - functional programming interface
 * @export
 */
export const RequiredScopeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequiredScopeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary RequiredScope
         * @param {string} [articleId] 
         * @param {string} [channelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requiredScope(articleId?: string, channelId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequiredScopeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requiredScope(articleId, channelId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RequiredScopeApi.requiredScope']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RequiredScopeApi - factory interface
 * @export
 */
export const RequiredScopeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequiredScopeApiFp(configuration)
    return {
        /**
         * 
         * @summary RequiredScope
         * @param {string} [articleId] 
         * @param {string} [channelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requiredScope(articleId?: string, channelId?: string, options?: any): AxiosPromise<RequiredScopeResponse> {
            return localVarFp.requiredScope(articleId, channelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequiredScopeApi - object-oriented interface
 * @export
 * @class RequiredScopeApi
 * @extends {BaseAPI}
 */
export class RequiredScopeApi extends BaseAPI {
    /**
     * 
     * @summary RequiredScope
     * @param {string} [articleId] 
     * @param {string} [channelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequiredScopeApi
     */
    public requiredScope(articleId?: string, channelId?: string, options?: AxiosRequestConfig) {
        return RequiredScopeApiFp(this.configuration).requiredScope(articleId, channelId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RevertAnswerApi - axios parameter creator
 * @export
 */
export const RevertAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Revert answer
         * @param {RevertAnswer} [revertAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertAnswer: async (revertAnswer?: RevertAnswer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/revert-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revertAnswer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RevertAnswerApi - functional programming interface
 * @export
 */
export const RevertAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RevertAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Revert answer
         * @param {RevertAnswer} [revertAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revertAnswer(revertAnswer?: RevertAnswer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revertAnswer(revertAnswer, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RevertAnswerApi.revertAnswer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RevertAnswerApi - factory interface
 * @export
 */
export const RevertAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RevertAnswerApiFp(configuration)
    return {
        /**
         * 
         * @summary Revert answer
         * @param {RevertAnswer} [revertAnswer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertAnswer(revertAnswer?: RevertAnswer, options?: any): AxiosPromise<string> {
            return localVarFp.revertAnswer(revertAnswer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RevertAnswerApi - object-oriented interface
 * @export
 * @class RevertAnswerApi
 * @extends {BaseAPI}
 */
export class RevertAnswerApi extends BaseAPI {
    /**
     * 
     * @summary Revert answer
     * @param {RevertAnswer} [revertAnswer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RevertAnswerApi
     */
    public revertAnswer(revertAnswer?: RevertAnswer, options?: AxiosRequestConfig) {
        return RevertAnswerApiFp(this.configuration).revertAnswer(revertAnswer, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RusticiCourseScoreWebhookApi - axios parameter creator
 * @export
 */
export const RusticiCourseScoreWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Rustici Set Course Score Webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRusticiCourseScore: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rustici-course-score-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RusticiCourseScoreWebhookApi - functional programming interface
 * @export
 */
export const RusticiCourseScoreWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RusticiCourseScoreWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Rustici Set Course Score Webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRusticiCourseScore(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setRusticiCourseScore(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RusticiCourseScoreWebhookApi.setRusticiCourseScore']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RusticiCourseScoreWebhookApi - factory interface
 * @export
 */
export const RusticiCourseScoreWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RusticiCourseScoreWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary Rustici Set Course Score Webhook
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRusticiCourseScore(options?: any): AxiosPromise<string> {
            return localVarFp.setRusticiCourseScore(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RusticiCourseScoreWebhookApi - object-oriented interface
 * @export
 * @class RusticiCourseScoreWebhookApi
 * @extends {BaseAPI}
 */
export class RusticiCourseScoreWebhookApi extends BaseAPI {
    /**
     * 
     * @summary Rustici Set Course Score Webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RusticiCourseScoreWebhookApi
     */
    public setRusticiCourseScore(options?: AxiosRequestConfig) {
        return RusticiCourseScoreWebhookApiFp(this.configuration).setRusticiCourseScore(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RusticiImportStatusWebhookApi - axios parameter creator
 * @export
 */
export const RusticiImportStatusWebhookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update import course status webhook
         * @param {ImportJobStatusResponse} [importJobStatusResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rusticiImportStatusWebhook: async (importJobStatusResponse?: ImportJobStatusResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rustici-import-status-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importJobStatusResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RusticiImportStatusWebhookApi - functional programming interface
 * @export
 */
export const RusticiImportStatusWebhookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RusticiImportStatusWebhookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update import course status webhook
         * @param {ImportJobStatusResponse} [importJobStatusResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rusticiImportStatusWebhook(importJobStatusResponse?: ImportJobStatusResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rusticiImportStatusWebhook(importJobStatusResponse, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RusticiImportStatusWebhookApi.rusticiImportStatusWebhook']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RusticiImportStatusWebhookApi - factory interface
 * @export
 */
export const RusticiImportStatusWebhookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RusticiImportStatusWebhookApiFp(configuration)
    return {
        /**
         * 
         * @summary Update import course status webhook
         * @param {ImportJobStatusResponse} [importJobStatusResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rusticiImportStatusWebhook(importJobStatusResponse?: ImportJobStatusResponse, options?: any): AxiosPromise<string> {
            return localVarFp.rusticiImportStatusWebhook(importJobStatusResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RusticiImportStatusWebhookApi - object-oriented interface
 * @export
 * @class RusticiImportStatusWebhookApi
 * @extends {BaseAPI}
 */
export class RusticiImportStatusWebhookApi extends BaseAPI {
    /**
     * 
     * @summary Update import course status webhook
     * @param {ImportJobStatusResponse} [importJobStatusResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RusticiImportStatusWebhookApi
     */
    public rusticiImportStatusWebhook(importJobStatusResponse?: ImportJobStatusResponse, options?: AxiosRequestConfig) {
        return RusticiImportStatusWebhookApiFp(this.configuration).rusticiImportStatusWebhook(importJobStatusResponse, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RusticiRegistrationApi - axios parameter creator
 * @export
 */
export const RusticiRegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add scorm registration
         * @param {ScormRegistrationRequest} [scormRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegistration: async (scormRegistrationRequest?: ScormRegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rustici-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scormRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RusticiRegistrationApi - functional programming interface
 * @export
 */
export const RusticiRegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RusticiRegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add scorm registration
         * @param {ScormRegistrationRequest} [scormRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRegistration(scormRegistrationRequest?: ScormRegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScormRegistrationConflict>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRegistration(scormRegistrationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RusticiRegistrationApi.addRegistration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RusticiRegistrationApi - factory interface
 * @export
 */
export const RusticiRegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RusticiRegistrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Add scorm registration
         * @param {ScormRegistrationRequest} [scormRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegistration(scormRegistrationRequest?: ScormRegistrationRequest, options?: any): AxiosPromise<ScormRegistrationConflict> {
            return localVarFp.addRegistration(scormRegistrationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RusticiRegistrationApi - object-oriented interface
 * @export
 * @class RusticiRegistrationApi
 * @extends {BaseAPI}
 */
export class RusticiRegistrationApi extends BaseAPI {
    /**
     * 
     * @summary Add scorm registration
     * @param {ScormRegistrationRequest} [scormRegistrationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RusticiRegistrationApi
     */
    public addRegistration(scormRegistrationRequest?: ScormRegistrationRequest, options?: AxiosRequestConfig) {
        return RusticiRegistrationApiFp(this.configuration).addRegistration(scormRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SharedContentApi - axios parameter creator
 * @export
 */
export const SharedContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stop sharing article
         * @param {DeleteSharedArticleRequest} [deleteSharedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharedArticle: async (deleteSharedArticleRequest?: DeleteSharedArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content/sharing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSharedArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get article sharing status overview
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleSharingStatusOverview: async (articleId: number, include?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticleSharingStatusOverview', 'articleId', articleId)
            const localVarPath = `/content/sharing/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share article
         * @param {ShareArticleRequest} [shareArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareArticle: async (shareArticleRequest?: ShareArticleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content/sharing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedContentApi - functional programming interface
 * @export
 */
export const SharedContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Stop sharing article
         * @param {DeleteSharedArticleRequest} [deleteSharedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSharedArticle(deleteSharedArticleRequest?: DeleteSharedArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteSharedArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSharedArticle(deleteSharedArticleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharedContentApi.deleteSharedArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get article sharing status overview
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleSharingStatusOverview(articleId: number, include?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharingStatusOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleSharingStatusOverview(articleId, include, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharedContentApi.getArticleSharingStatusOverview']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Share article
         * @param {ShareArticleRequest} [shareArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareArticle(shareArticleRequest?: ShareArticleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareArticle(shareArticleRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharedContentApi.shareArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SharedContentApi - factory interface
 * @export
 */
export const SharedContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedContentApiFp(configuration)
    return {
        /**
         * 
         * @summary Stop sharing article
         * @param {DeleteSharedArticleRequest} [deleteSharedArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharedArticle(deleteSharedArticleRequest?: DeleteSharedArticleRequest, options?: any): AxiosPromise<DeleteSharedArticleResponse> {
            return localVarFp.deleteSharedArticle(deleteSharedArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get article sharing status overview
         * @param {number} articleId 
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleSharingStatusOverview(articleId: number, include?: string, options?: any): AxiosPromise<SharingStatusOverviewResponse> {
            return localVarFp.getArticleSharingStatusOverview(articleId, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share article
         * @param {ShareArticleRequest} [shareArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareArticle(shareArticleRequest?: ShareArticleRequest, options?: any): AxiosPromise<ShareArticleResponse> {
            return localVarFp.shareArticle(shareArticleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharedContentApi - object-oriented interface
 * @export
 * @class SharedContentApi
 * @extends {BaseAPI}
 */
export class SharedContentApi extends BaseAPI {
    /**
     * 
     * @summary Stop sharing article
     * @param {DeleteSharedArticleRequest} [deleteSharedArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedContentApi
     */
    public deleteSharedArticle(deleteSharedArticleRequest?: DeleteSharedArticleRequest, options?: AxiosRequestConfig) {
        return SharedContentApiFp(this.configuration).deleteSharedArticle(deleteSharedArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get article sharing status overview
     * @param {number} articleId 
     * @param {string} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedContentApi
     */
    public getArticleSharingStatusOverview(articleId: number, include?: string, options?: AxiosRequestConfig) {
        return SharedContentApiFp(this.configuration).getArticleSharingStatusOverview(articleId, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share article
     * @param {ShareArticleRequest} [shareArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedContentApi
     */
    public shareArticle(shareArticleRequest?: ShareArticleRequest, options?: AxiosRequestConfig) {
        return SharedContentApiFp(this.configuration).shareArticle(shareArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SharingConnectionApi - axios parameter creator
 * @export
 */
export const SharingConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete single sharing connection
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharingConnection: async (connectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('deleteSharingConnection', 'connectionId', connectionId)
            const localVarPath = `/sharing/connection/{connectionId}`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sharing connections
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharingConnection: async (include?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sharing/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include !== undefined) {
                localVarQueryParameter['include'] = include;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create sharing connection
         * @param {NewSharingConnection} [newSharingConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSharingConnection: async (newSharingConnection?: NewSharingConnection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sharing/connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSharingConnection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharingConnectionApi - functional programming interface
 * @export
 */
export const SharingConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharingConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete single sharing connection
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSharingConnection(connectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSharingConnection(connectionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharingConnectionApi.deleteSharingConnection']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get sharing connections
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharingConnection(include?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedTenants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharingConnection(include, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharingConnectionApi.getSharingConnection']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create sharing connection
         * @param {NewSharingConnection} [newSharingConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSharingConnection(newSharingConnection?: NewSharingConnection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatedSharingConnection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSharingConnection(newSharingConnection, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SharingConnectionApi.postSharingConnection']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SharingConnectionApi - factory interface
 * @export
 */
export const SharingConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharingConnectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete single sharing connection
         * @param {number} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSharingConnection(connectionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSharingConnection(connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sharing connections
         * @param {string} [include] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharingConnection(include?: string, options?: any): AxiosPromise<SharedTenants> {
            return localVarFp.getSharingConnection(include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create sharing connection
         * @param {NewSharingConnection} [newSharingConnection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSharingConnection(newSharingConnection?: NewSharingConnection, options?: any): AxiosPromise<Array<CreatedSharingConnection>> {
            return localVarFp.postSharingConnection(newSharingConnection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharingConnectionApi - object-oriented interface
 * @export
 * @class SharingConnectionApi
 * @extends {BaseAPI}
 */
export class SharingConnectionApi extends BaseAPI {
    /**
     * 
     * @summary Delete single sharing connection
     * @param {number} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingConnectionApi
     */
    public deleteSharingConnection(connectionId: number, options?: AxiosRequestConfig) {
        return SharingConnectionApiFp(this.configuration).deleteSharingConnection(connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sharing connections
     * @param {string} [include] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingConnectionApi
     */
    public getSharingConnection(include?: string, options?: AxiosRequestConfig) {
        return SharingConnectionApiFp(this.configuration).getSharingConnection(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create sharing connection
     * @param {NewSharingConnection} [newSharingConnection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingConnectionApi
     */
    public postSharingConnection(newSharingConnection?: NewSharingConnection, options?: AxiosRequestConfig) {
        return SharingConnectionApiFp(this.configuration).postSharingConnection(newSharingConnection, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SynchronizeTranslationGlossariesApi - axios parameter creator
 * @export
 */
export const SynchronizeTranslationGlossariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Synchronize translation glossaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeTranslationGlossaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync-translation-glossaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SynchronizeTranslationGlossariesApi - functional programming interface
 * @export
 */
export const SynchronizeTranslationGlossariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SynchronizeTranslationGlossariesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Synchronize translation glossaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async synchronizeTranslationGlossaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlosarySyncResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.synchronizeTranslationGlossaries(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SynchronizeTranslationGlossariesApi.synchronizeTranslationGlossaries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SynchronizeTranslationGlossariesApi - factory interface
 * @export
 */
export const SynchronizeTranslationGlossariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SynchronizeTranslationGlossariesApiFp(configuration)
    return {
        /**
         * 
         * @summary Synchronize translation glossaries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        synchronizeTranslationGlossaries(options?: any): AxiosPromise<Array<GlosarySyncResult>> {
            return localVarFp.synchronizeTranslationGlossaries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SynchronizeTranslationGlossariesApi - object-oriented interface
 * @export
 * @class SynchronizeTranslationGlossariesApi
 * @extends {BaseAPI}
 */
export class SynchronizeTranslationGlossariesApi extends BaseAPI {
    /**
     * 
     * @summary Synchronize translation glossaries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SynchronizeTranslationGlossariesApi
     */
    public synchronizeTranslationGlossaries(options?: AxiosRequestConfig) {
        return SynchronizeTranslationGlossariesApiFp(this.configuration).synchronizeTranslationGlossaries(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TranslateApi - axios parameter creator
 * @export
 */
export const TranslateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Translate
         * @param {Translate} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslate: async (translate?: Translate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslateApi - functional programming interface
 * @export
 */
export const TranslateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Translate
         * @param {Translate} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTranslate(translate?: Translate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTranslate(translate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslateApi.postTranslate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TranslateApi - factory interface
 * @export
 */
export const TranslateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslateApiFp(configuration)
    return {
        /**
         * 
         * @summary Translate
         * @param {Translate} [translate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslate(translate?: Translate, options?: any): AxiosPromise<Article> {
            return localVarFp.postTranslate(translate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslateApi - object-oriented interface
 * @export
 * @class TranslateApi
 * @extends {BaseAPI}
 */
export class TranslateApi extends BaseAPI {
    /**
     * 
     * @summary Translate
     * @param {Translate} [translate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslateApi
     */
    public postTranslate(translate?: Translate, options?: AxiosRequestConfig) {
        return TranslateApiFp(this.configuration).postTranslate(translate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TranslationGlossaryApi - axios parameter creator
 * @export
 */
export const TranslationGlossaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslationGlossaryEntity: async (sourceLanguage: string, term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceLanguage' is not null or undefined
            assertParamExists('deleteTranslationGlossaryEntity', 'sourceLanguage', sourceLanguage)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('deleteTranslationGlossaryEntity', 'term', term)
            const localVarPath = `/translation-glossary/{sourceLanguage}/{term}`
                .replace(`{${"sourceLanguage"}}`, encodeURIComponent(String(sourceLanguage)))
                .replace(`{${"term"}}`, encodeURIComponent(String(term)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all translation glossary entities
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTranslationGlossaryEntities: async (continuationToken?: string, perPage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translation-glossary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (continuationToken !== undefined) {
                localVarQueryParameter['continuationToken'] = continuationToken;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get translation glossary entities by source language
         * @param {string} sourceLanguage 
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationGlossaryEntities: async (sourceLanguage: string, continuationToken?: string, perPage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceLanguage' is not null or undefined
            assertParamExists('getTranslationGlossaryEntities', 'sourceLanguage', sourceLanguage)
            const localVarPath = `/translation-glossary/{sourceLanguage}`
                .replace(`{${"sourceLanguage"}}`, encodeURIComponent(String(sourceLanguage)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (continuationToken !== undefined) {
                localVarQueryParameter['continuationToken'] = continuationToken;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationGlossaryEntity: async (sourceLanguage: string, term: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceLanguage' is not null or undefined
            assertParamExists('getTranslationGlossaryEntity', 'sourceLanguage', sourceLanguage)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('getTranslationGlossaryEntity', 'term', term)
            const localVarPath = `/translation-glossary/{sourceLanguage}/{term}`
                .replace(`{${"sourceLanguage"}}`, encodeURIComponent(String(sourceLanguage)))
                .replace(`{${"term"}}`, encodeURIComponent(String(term)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post translation glossary entity
         * @param {PostGlossaryEntitySchema} [postGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslationGlossaryEntity: async (postGlossaryEntitySchema?: PostGlossaryEntitySchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translation-glossary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postGlossaryEntitySchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update translation glossary entity
         * @param {PutGlossaryEntitySchema} [putGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTranslationGlossaryEntity: async (putGlossaryEntitySchema?: PutGlossaryEntitySchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translation-glossary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putGlossaryEntitySchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationGlossaryApi - functional programming interface
 * @export
 */
export const TranslationGlossaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationGlossaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTranslationGlossaryEntity(sourceLanguage, term, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.deleteTranslationGlossaryEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all translation glossary entities
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTranslationGlossaryEntities(continuationToken?: string, perPage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListedTranslationGlossaryEntities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTranslationGlossaryEntities(continuationToken, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.getAllTranslationGlossaryEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get translation glossary entities by source language
         * @param {string} sourceLanguage 
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationGlossaryEntities(sourceLanguage: string, continuationToken?: string, perPage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListedTranslationGlossaryEntities>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslationGlossaryEntities(sourceLanguage, continuationToken, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.getTranslationGlossaryEntities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrievedGlossaryEntitySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslationGlossaryEntity(sourceLanguage, term, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.getTranslationGlossaryEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Post translation glossary entity
         * @param {PostGlossaryEntitySchema} [postGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTranslationGlossaryEntity(postGlossaryEntitySchema?: PostGlossaryEntitySchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTranslationGlossaryEntity(postGlossaryEntitySchema, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.postTranslationGlossaryEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update translation glossary entity
         * @param {PutGlossaryEntitySchema} [putGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTranslationGlossaryEntity(putGlossaryEntitySchema?: PutGlossaryEntitySchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTranslationGlossaryEntity(putGlossaryEntitySchema, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TranslationGlossaryApi.updateTranslationGlossaryEntity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TranslationGlossaryApi - factory interface
 * @export
 */
export const TranslationGlossaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationGlossaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteTranslationGlossaryEntity(sourceLanguage, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all translation glossary entities
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTranslationGlossaryEntities(continuationToken?: string, perPage?: string, options?: any): AxiosPromise<ListedTranslationGlossaryEntities> {
            return localVarFp.getAllTranslationGlossaryEntities(continuationToken, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get translation glossary entities by source language
         * @param {string} sourceLanguage 
         * @param {string} [continuationToken] 
         * @param {string} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationGlossaryEntities(sourceLanguage: string, continuationToken?: string, perPage?: string, options?: any): AxiosPromise<ListedTranslationGlossaryEntities> {
            return localVarFp.getTranslationGlossaryEntities(sourceLanguage, continuationToken, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get translation glossary entity
         * @param {string} sourceLanguage 
         * @param {string} term 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: any): AxiosPromise<RetrievedGlossaryEntitySchema> {
            return localVarFp.getTranslationGlossaryEntity(sourceLanguage, term, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post translation glossary entity
         * @param {PostGlossaryEntitySchema} [postGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslationGlossaryEntity(postGlossaryEntitySchema?: PostGlossaryEntitySchema, options?: any): AxiosPromise<any> {
            return localVarFp.postTranslationGlossaryEntity(postGlossaryEntitySchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update translation glossary entity
         * @param {PutGlossaryEntitySchema} [putGlossaryEntitySchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTranslationGlossaryEntity(putGlossaryEntitySchema?: PutGlossaryEntitySchema, options?: any): AxiosPromise<any> {
            return localVarFp.updateTranslationGlossaryEntity(putGlossaryEntitySchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationGlossaryApi - object-oriented interface
 * @export
 * @class TranslationGlossaryApi
 * @extends {BaseAPI}
 */
export class TranslationGlossaryApi extends BaseAPI {
    /**
     * 
     * @summary Delete translation glossary entity
     * @param {string} sourceLanguage 
     * @param {string} term 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public deleteTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).deleteTranslationGlossaryEntity(sourceLanguage, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all translation glossary entities
     * @param {string} [continuationToken] 
     * @param {string} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public getAllTranslationGlossaryEntities(continuationToken?: string, perPage?: string, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).getAllTranslationGlossaryEntities(continuationToken, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get translation glossary entities by source language
     * @param {string} sourceLanguage 
     * @param {string} [continuationToken] 
     * @param {string} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public getTranslationGlossaryEntities(sourceLanguage: string, continuationToken?: string, perPage?: string, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).getTranslationGlossaryEntities(sourceLanguage, continuationToken, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get translation glossary entity
     * @param {string} sourceLanguage 
     * @param {string} term 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public getTranslationGlossaryEntity(sourceLanguage: string, term: string, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).getTranslationGlossaryEntity(sourceLanguage, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post translation glossary entity
     * @param {PostGlossaryEntitySchema} [postGlossaryEntitySchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public postTranslationGlossaryEntity(postGlossaryEntitySchema?: PostGlossaryEntitySchema, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).postTranslationGlossaryEntity(postGlossaryEntitySchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update translation glossary entity
     * @param {PutGlossaryEntitySchema} [putGlossaryEntitySchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationGlossaryApi
     */
    public updateTranslationGlossaryEntity(putGlossaryEntitySchema?: PutGlossaryEntitySchema, options?: AxiosRequestConfig) {
        return TranslationGlossaryApiFp(this.configuration).updateTranslationGlossaryEntity(putGlossaryEntitySchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UnarchiveArticleApi - axios parameter creator
 * @export
 */
export const UnarchiveArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unarchive articles
         * @param {UnarchiveArticlesRequest} [unarchiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveArticle: async (unarchiveArticlesRequest?: UnarchiveArticlesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/unarchive-article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unarchiveArticlesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnarchiveArticleApi - functional programming interface
 * @export
 */
export const UnarchiveArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnarchiveArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unarchive articles
         * @param {UnarchiveArticlesRequest} [unarchiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unarchiveArticle(unarchiveArticlesRequest?: UnarchiveArticlesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnarchiveArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unarchiveArticle(unarchiveArticlesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UnarchiveArticleApi.unarchiveArticle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UnarchiveArticleApi - factory interface
 * @export
 */
export const UnarchiveArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnarchiveArticleApiFp(configuration)
    return {
        /**
         * 
         * @summary Unarchive articles
         * @param {UnarchiveArticlesRequest} [unarchiveArticlesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unarchiveArticle(unarchiveArticlesRequest?: UnarchiveArticlesRequest, options?: any): AxiosPromise<UnarchiveArticlesResponse> {
            return localVarFp.unarchiveArticle(unarchiveArticlesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnarchiveArticleApi - object-oriented interface
 * @export
 * @class UnarchiveArticleApi
 * @extends {BaseAPI}
 */
export class UnarchiveArticleApi extends BaseAPI {
    /**
     * 
     * @summary Unarchive articles
     * @param {UnarchiveArticlesRequest} [unarchiveArticlesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnarchiveArticleApi
     */
    public unarchiveArticle(unarchiveArticlesRequest?: UnarchiveArticlesRequest, options?: AxiosRequestConfig) {
        return UnarchiveArticleApiFp(this.configuration).unarchiveArticle(unarchiveArticlesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UncompletedActionsApi - axios parameter creator
 * @export
 */
export const UncompletedActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get uncompleted actions for current user
         * @param {string | null} [deadlineFrom] 
         * @param {string | null} [deadlineTo] 
         * @param {GetUncompletedActionsNoDeadlineEnum} [noDeadline] 
         * @param {number | null} [required] 
         * @param {string} [timezone] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUncompletedActions: async (deadlineFrom?: string | null, deadlineTo?: string | null, noDeadline?: GetUncompletedActionsNoDeadlineEnum, required?: number | null, timezone?: string, page?: number | null, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uncompleted-actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deadlineFrom !== undefined) {
                localVarQueryParameter['deadlineFrom'] = deadlineFrom;
            }

            if (deadlineTo !== undefined) {
                localVarQueryParameter['deadlineTo'] = deadlineTo;
            }

            if (noDeadline !== undefined) {
                localVarQueryParameter['noDeadline'] = noDeadline;
            }

            if (required !== undefined) {
                localVarQueryParameter['required'] = required;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UncompletedActionsApi - functional programming interface
 * @export
 */
export const UncompletedActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UncompletedActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get uncompleted actions for current user
         * @param {string | null} [deadlineFrom] 
         * @param {string | null} [deadlineTo] 
         * @param {GetUncompletedActionsNoDeadlineEnum} [noDeadline] 
         * @param {number | null} [required] 
         * @param {string} [timezone] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUncompletedActions(deadlineFrom?: string | null, deadlineTo?: string | null, noDeadline?: GetUncompletedActionsNoDeadlineEnum, required?: number | null, timezone?: string, page?: number | null, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UncompletedActionsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUncompletedActions(deadlineFrom, deadlineTo, noDeadline, required, timezone, page, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UncompletedActionsApi.getUncompletedActions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UncompletedActionsApi - factory interface
 * @export
 */
export const UncompletedActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UncompletedActionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get uncompleted actions for current user
         * @param {string | null} [deadlineFrom] 
         * @param {string | null} [deadlineTo] 
         * @param {GetUncompletedActionsNoDeadlineEnum} [noDeadline] 
         * @param {number | null} [required] 
         * @param {string} [timezone] 
         * @param {number | null} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUncompletedActions(deadlineFrom?: string | null, deadlineTo?: string | null, noDeadline?: GetUncompletedActionsNoDeadlineEnum, required?: number | null, timezone?: string, page?: number | null, perPage?: number, options?: any): AxiosPromise<UncompletedActionsPage> {
            return localVarFp.getUncompletedActions(deadlineFrom, deadlineTo, noDeadline, required, timezone, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UncompletedActionsApi - object-oriented interface
 * @export
 * @class UncompletedActionsApi
 * @extends {BaseAPI}
 */
export class UncompletedActionsApi extends BaseAPI {
    /**
     * 
     * @summary Get uncompleted actions for current user
     * @param {string | null} [deadlineFrom] 
     * @param {string | null} [deadlineTo] 
     * @param {GetUncompletedActionsNoDeadlineEnum} [noDeadline] 
     * @param {number | null} [required] 
     * @param {string} [timezone] 
     * @param {number | null} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UncompletedActionsApi
     */
    public getUncompletedActions(deadlineFrom?: string | null, deadlineTo?: string | null, noDeadline?: GetUncompletedActionsNoDeadlineEnum, required?: number | null, timezone?: string, page?: number | null, perPage?: number, options?: AxiosRequestConfig) {
        return UncompletedActionsApiFp(this.configuration).getUncompletedActions(deadlineFrom, deadlineTo, noDeadline, required, timezone, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetUncompletedActionsNoDeadlineEnum = {
    _1: '1'
} as const;
export type GetUncompletedActionsNoDeadlineEnum = typeof GetUncompletedActionsNoDeadlineEnum[keyof typeof GetUncompletedActionsNoDeadlineEnum];


