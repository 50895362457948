/* tslint:disable */
/* eslint-disable */
/**
 * AI Serice
 * OpenAI integration to make summaries
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface GenerateBlockRequest
 */
export interface GenerateBlockRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateBlockRequest
     */
    'prompt': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateBlockRequest
     */
    'blockType': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GenerateBlockRequest
     */
    'article': { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof GenerateBlockRequest
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface GenerateSummaryResponseSchema
 */
export interface GenerateSummaryResponseSchema {
    /**
     * 
     * @type {number}
     * @memberof GenerateSummaryResponseSchema
     */
    'articleId': number;
    /**
     * 
     * @type {number}
     * @memberof GenerateSummaryResponseSchema
     */
    'actionId': number;
    /**
     * 
     * @type {string}
     * @memberof GenerateSummaryResponseSchema
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateSummaryResponseSchema
     */
    'includeShared'?: GenerateSummaryResponseSchemaIncludeSharedEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateSummaryResponseSchema
     */
    'sharedTenants'?: string;
}

export const GenerateSummaryResponseSchemaIncludeSharedEnum = {
    _1: '1'
} as const;

export type GenerateSummaryResponseSchemaIncludeSharedEnum = typeof GenerateSummaryResponseSchemaIncludeSharedEnum[keyof typeof GenerateSummaryResponseSchemaIncludeSharedEnum];

/**
 * 
 * @export
 * @interface SummarySchema
 */
export interface SummarySchema {
    /**
     * 
     * @type {string}
     * @memberof SummarySchema
     */
    'tenant': string;
    /**
     * 
     * @type {number}
     * @memberof SummarySchema
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof SummarySchema
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof SummarySchema
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof SummarySchema
     */
    'answersCount': number;
    /**
     * 
     * @type {string}
     * @memberof SummarySchema
     */
    'language': string;
    /**
     * 
     * @type {number}
     * @memberof SummarySchema
     */
    'attempts': number;
    /**
     * 
     * @type {string}
     * @memberof SummarySchema
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ValidationIssue
 */
export interface ValidationIssue {
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'message': string;
    /**
     * 
     * @type {Array<ValidationIssuePathInner>}
     * @memberof ValidationIssue
     */
    'path': Array<ValidationIssuePathInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'code': string;
}
/**
 * @type ValidationIssuePathInner
 * @export
 */
export type ValidationIssuePathInner = number | string;

/**
 * 
 * @export
 * @interface ValidationIssues
 */
export interface ValidationIssues extends Array<ValidationIssue> {
}

/**
 * GenerateBlockApi - axios parameter creator
 * @export
 */
export const GenerateBlockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate new block
         * @param {GenerateBlockRequest} [generateBlockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBlock: async (generateBlockRequest?: GenerateBlockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/generate-block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateBlockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenerateBlockApi - functional programming interface
 * @export
 */
export const GenerateBlockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenerateBlockApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate new block
         * @param {GenerateBlockRequest} [generateBlockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBlock(generateBlockRequest?: GenerateBlockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBlock(generateBlockRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GenerateBlockApi.generateBlock']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GenerateBlockApi - factory interface
 * @export
 */
export const GenerateBlockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenerateBlockApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate new block
         * @param {GenerateBlockRequest} [generateBlockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBlock(generateBlockRequest?: GenerateBlockRequest, options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.generateBlock(generateBlockRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenerateBlockApi - object-oriented interface
 * @export
 * @class GenerateBlockApi
 * @extends {BaseAPI}
 */
export class GenerateBlockApi extends BaseAPI {
    /**
     * 
     * @summary Generate new block
     * @param {GenerateBlockRequest} [generateBlockRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateBlockApi
     */
    public generateBlock(generateBlockRequest?: GenerateBlockRequest, options?: AxiosRequestConfig) {
        return GenerateBlockApiFp(this.configuration).generateBlock(generateBlockRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkHealth(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HealthcheckApi.checkHealth']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @summary Healthcheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHealth(options?: any): AxiosPromise<string> {
            return localVarFp.checkHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public checkHealth(options?: AxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).checkHealth(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SummaryApi - axios parameter creator
 * @export
 */
export const SummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new summary
         * @param {GenerateSummaryResponseSchema} [generateSummaryResponseSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSummary: async (generateSummaryResponseSchema?: GenerateSummaryResponseSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateSummaryResponseSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get summary
         * @param {number} articleId 
         * @param {number} actionId 
         * @param {string} language 
         * @param {GetSummaryIncludeSharedEnum} [includeShared] 
         * @param {string} [sharedTenants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (articleId: number, actionId: number, language: string, includeShared?: GetSummaryIncludeSharedEnum, sharedTenants?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getSummary', 'articleId', articleId)
            // verify required parameter 'actionId' is not null or undefined
            assertParamExists('getSummary', 'actionId', actionId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getSummary', 'language', language)
            const localVarPath = `/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (articleId !== undefined) {
                localVarQueryParameter['articleId'] = articleId;
            }

            if (actionId !== undefined) {
                localVarQueryParameter['actionId'] = actionId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (includeShared !== undefined) {
                localVarQueryParameter['includeShared'] = includeShared;
            }

            if (sharedTenants !== undefined) {
                localVarQueryParameter['sharedTenants'] = sharedTenants;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummaryApi - functional programming interface
 * @export
 */
export const SummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new summary
         * @param {GenerateSummaryResponseSchema} [generateSummaryResponseSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateSummary(generateSummaryResponseSchema?: GenerateSummaryResponseSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummarySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateSummary(generateSummaryResponseSchema, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SummaryApi.generateSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get summary
         * @param {number} articleId 
         * @param {number} actionId 
         * @param {string} language 
         * @param {GetSummaryIncludeSharedEnum} [includeShared] 
         * @param {string} [sharedTenants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(articleId: number, actionId: number, language: string, includeShared?: GetSummaryIncludeSharedEnum, sharedTenants?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SummarySchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(articleId, actionId, language, includeShared, sharedTenants, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SummaryApi.getSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SummaryApi - factory interface
 * @export
 */
export const SummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SummaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new summary
         * @param {GenerateSummaryResponseSchema} [generateSummaryResponseSchema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSummary(generateSummaryResponseSchema?: GenerateSummaryResponseSchema, options?: any): AxiosPromise<SummarySchema> {
            return localVarFp.generateSummary(generateSummaryResponseSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get summary
         * @param {number} articleId 
         * @param {number} actionId 
         * @param {string} language 
         * @param {GetSummaryIncludeSharedEnum} [includeShared] 
         * @param {string} [sharedTenants] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(articleId: number, actionId: number, language: string, includeShared?: GetSummaryIncludeSharedEnum, sharedTenants?: string, options?: any): AxiosPromise<SummarySchema> {
            return localVarFp.getSummary(articleId, actionId, language, includeShared, sharedTenants, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SummaryApi - object-oriented interface
 * @export
 * @class SummaryApi
 * @extends {BaseAPI}
 */
export class SummaryApi extends BaseAPI {
    /**
     * 
     * @summary Create new summary
     * @param {GenerateSummaryResponseSchema} [generateSummaryResponseSchema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public generateSummary(generateSummaryResponseSchema?: GenerateSummaryResponseSchema, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).generateSummary(generateSummaryResponseSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get summary
     * @param {number} articleId 
     * @param {number} actionId 
     * @param {string} language 
     * @param {GetSummaryIncludeSharedEnum} [includeShared] 
     * @param {string} [sharedTenants] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public getSummary(articleId: number, actionId: number, language: string, includeShared?: GetSummaryIncludeSharedEnum, sharedTenants?: string, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).getSummary(articleId, actionId, language, includeShared, sharedTenants, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetSummaryIncludeSharedEnum = {
    _1: '1'
} as const;
export type GetSummaryIncludeSharedEnum = typeof GetSummaryIncludeSharedEnum[keyof typeof GetSummaryIncludeSharedEnum];


