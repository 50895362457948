/* tslint:disable */
/* eslint-disable */
/**
 * Subdomain configuration API
 * Subdomain configuration Service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ConfigurationRequest
 */
export interface ConfigurationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfigurationRequest
     */
    'allowedIframeUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'description': string;
    /**
     * 
     * @type {Favicons}
     * @memberof ConfigurationRequest
     */
    'favicons'?: Favicons;
    /**
     * 
     * @type {Array<Font>}
     * @memberof ConfigurationRequest
     */
    'fonts'?: Array<Font>;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'helpEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'helpUrl'?: string;
    /**
     * 
     * @type {PwaIcons}
     * @memberof ConfigurationRequest
     */
    'icons'?: PwaIcons;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'subdomain': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationRequest
     */
    'themeColor': string;
    /**
     * 
     * @type {Array<SubdomainTenant>}
     * @memberof ConfigurationRequest
     */
    'tenants'?: Array<SubdomainTenant>;
}
/**
 * 
 * @export
 * @interface CreatedFileSlot
 */
export interface CreatedFileSlot {
    /**
     * 
     * @type {string}
     * @memberof CreatedFileSlot
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CreatedFileSlot
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface DeleteSubdomainConfigurationRequest
 */
export interface DeleteSubdomainConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteSubdomainConfigurationRequest
     */
    'subdomain': string;
}
/**
 * 
 * @export
 * @interface Favicons
 */
export interface Favicons {
    /**
     * 
     * @type {string}
     * @memberof Favicons
     */
    'originalId'?: string;
    /**
     * 
     * @type {Array<FaviconsSizesInner>}
     * @memberof Favicons
     */
    'sizes': Array<FaviconsSizesInner>;
}
/**
 * 
 * @export
 * @interface FaviconsResponse
 */
export interface FaviconsResponse {
    /**
     * 
     * @type {FaviconsResponseOriginal}
     * @memberof FaviconsResponse
     */
    'original': FaviconsResponseOriginal;
    /**
     * 
     * @type {Array<FaviconsResponseSizesInner>}
     * @memberof FaviconsResponse
     */
    'sizes': Array<FaviconsResponseSizesInner>;
}
/**
 * 
 * @export
 * @interface FaviconsResponseOriginal
 */
export interface FaviconsResponseOriginal {
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseOriginal
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseOriginal
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseOriginal
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface FaviconsResponseSizesInner
 */
export interface FaviconsResponseSizesInner {
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseSizesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseSizesInner
     */
    'rel': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseSizesInner
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseSizesInner
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsResponseSizesInner
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface FaviconsSizesInner
 */
export interface FaviconsSizesInner {
    /**
     * 
     * @type {string}
     * @memberof FaviconsSizesInner
     */
    'rel': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsSizesInner
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof FaviconsSizesInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Font
 */
export interface Font {
    /**
     * 
     * @type {string}
     * @memberof Font
     */
    'fileId': string;
    /**
     * 
     * @type {FontStyle}
     * @memberof Font
     */
    'style': FontStyle;
    /**
     * 
     * @type {FontWeight}
     * @memberof Font
     */
    'weight': FontWeight;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FontStyle = {
    Italic: 'italic',
    Normal: 'normal'
} as const;

export type FontStyle = typeof FontStyle[keyof typeof FontStyle];


/**
 * 
 * @export
 * @enum {string}
 */

export const FontWeight = {
    NUMBER_100: 100,
    NUMBER_300: 300,
    NUMBER_400: 400,
    NUMBER_700: 700,
    NUMBER_900: 900
} as const;

export type FontWeight = typeof FontWeight[keyof typeof FontWeight];


/**
 * @type GenerateImages400Response
 * @export
 */
export type GenerateImages400Response = Array<GetServerSubdomainConfiguration400ResponseInner> | ValidationIssues;

/**
 * 
 * @export
 * @interface GenerateImagesRequest
 */
export interface GenerateImagesRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateImagesRequest
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateImagesRequest
     */
    'target': GenerateImagesRequestTargetEnum;
}

export const GenerateImagesRequestTargetEnum = {
    Icons: 'icons',
    Favicons: 'favicons'
} as const;

export type GenerateImagesRequestTargetEnum = typeof GenerateImagesRequestTargetEnum[keyof typeof GenerateImagesRequestTargetEnum];

/**
 * 
 * @export
 * @interface GeneratedImages
 */
export interface GeneratedImages extends Array<GeneratedImagesInner> {
}
/**
 * 
 * @export
 * @interface GeneratedImagesInner
 */
export interface GeneratedImagesInner {
    /**
     * 
     * @type {string}
     * @memberof GeneratedImagesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedImagesInner
     */
    'rel'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedImagesInner
     */
    'size': string;
}
/**
 * @type GetLink400Response
 * @export
 */
export type GetLink400Response = Array<GetServerSubdomainConfiguration400ResponseInner>;

/**
 * 
 * @export
 * @interface GetServerSubdomainConfiguration400ResponseInner
 */
export interface GetServerSubdomainConfiguration400ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetServerSubdomainConfiguration400ResponseInner
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface IconsResponse
 */
export interface IconsResponse {
    /**
     * 
     * @type {FaviconsResponseOriginal}
     * @memberof IconsResponse
     */
    'original': FaviconsResponseOriginal;
    /**
     * 
     * @type {Array<IconsResponseSizesInner>}
     * @memberof IconsResponse
     */
    'sizes': Array<IconsResponseSizesInner>;
}
/**
 * 
 * @export
 * @interface IconsResponseSizesInner
 */
export interface IconsResponseSizesInner {
    /**
     * 
     * @type {string}
     * @memberof IconsResponseSizesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IconsResponseSizesInner
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof IconsResponseSizesInner
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof IconsResponseSizesInner
     */
    'contentType': string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {LinkIcon}
     * @memberof Link
     */
    'icon'?: LinkIcon;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'opensIn': LinkOpensInEnum;
    /**
     * 
     * @type {number}
     * @memberof Link
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'subdomain': string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'tenant': string;
}

export const LinkOpensInEnum = {
    Modal: 'modal',
    NewWindow: 'new_window'
} as const;

export type LinkOpensInEnum = typeof LinkOpensInEnum[keyof typeof LinkOpensInEnum];

/**
 * 
 * @export
 * @interface LinkIcon
 */
export interface LinkIcon {
    /**
     * 
     * @type {string}
     * @memberof LinkIcon
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LinkIcon
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Manifest
 */
export interface Manifest {
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'short_name': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'start_url': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'display': ManifestDisplayEnum;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'background_color': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'lang': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'scope': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Manifest
     */
    'theme_color': string;
    /**
     * 
     * @type {Array<ManifestIconsInner>}
     * @memberof Manifest
     */
    'icons'?: Array<ManifestIconsInner>;
}

export const ManifestDisplayEnum = {
    Standalone: 'standalone'
} as const;

export type ManifestDisplayEnum = typeof ManifestDisplayEnum[keyof typeof ManifestDisplayEnum];

/**
 * 
 * @export
 * @interface ManifestIconsInner
 */
export interface ManifestIconsInner {
    /**
     * 
     * @type {string}
     * @memberof ManifestIconsInner
     */
    'src': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestIconsInner
     */
    'sizes': string;
    /**
     * 
     * @type {string}
     * @memberof ManifestIconsInner
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PostLinkRequest
 */
export interface PostLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof PostLinkRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostLinkRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PostLinkRequest
     */
    'opensIn': PostLinkRequestOpensInEnum;
    /**
     * 
     * @type {number}
     * @memberof PostLinkRequest
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof PostLinkRequest
     */
    'url': string;
}

export const PostLinkRequestOpensInEnum = {
    Modal: 'modal',
    NewWindow: 'new_window'
} as const;

export type PostLinkRequestOpensInEnum = typeof PostLinkRequestOpensInEnum[keyof typeof PostLinkRequestOpensInEnum];

/**
 * @type PutLink400Response
 * @export
 */
export type PutLink400Response = Array<GetServerSubdomainConfiguration400ResponseInner> | ValidationIssues;

/**
 * 
 * @export
 * @interface PwaIcons
 */
export interface PwaIcons {
    /**
     * 
     * @type {string}
     * @memberof PwaIcons
     */
    'originalId'?: string;
    /**
     * 
     * @type {Array<PwaIconsSizesInner>}
     * @memberof PwaIcons
     */
    'sizes': Array<PwaIconsSizesInner>;
}
/**
 * 
 * @export
 * @interface PwaIconsSizesInner
 */
export interface PwaIconsSizesInner {
    /**
     * 
     * @type {string}
     * @memberof PwaIconsSizesInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PwaIconsSizesInner
     */
    'size': string;
}
/**
 * 
 * @export
 * @interface ReorderLinksRequestInner
 */
export interface ReorderLinksRequestInner {
    /**
     * 
     * @type {string}
     * @memberof ReorderLinksRequestInner
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ReorderLinksRequestInner
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {Array<string>}
     * @memberof Setting
     */
    'allowedIframeUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'helpEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'helpUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'logoUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'tenant': string;
    /**
     * 
     * @type {Array<SubdomainTenant>}
     * @memberof Setting
     */
    'tenants': Array<SubdomainTenant>;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'themeColor': string;
    /**
     * 
     * @type {Array<SettingFaviconsInner>}
     * @memberof Setting
     */
    'favicons': Array<SettingFaviconsInner>;
}
/**
 * 
 * @export
 * @interface SettingFaviconsInner
 */
export interface SettingFaviconsInner {
    /**
     * 
     * @type {string}
     * @memberof SettingFaviconsInner
     */
    'rel': string;
    /**
     * 
     * @type {string}
     * @memberof SettingFaviconsInner
     */
    'size': string;
    /**
     * 
     * @type {string}
     * @memberof SettingFaviconsInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof SettingFaviconsInner
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface SubdomainConfigurationResponse
 */
export interface SubdomainConfigurationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubdomainConfigurationResponse
     */
    'allowedIframeUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'description': string;
    /**
     * 
     * @type {FaviconsResponse}
     * @memberof SubdomainConfigurationResponse
     */
    'favicons'?: FaviconsResponse;
    /**
     * 
     * @type {Array<Font>}
     * @memberof SubdomainConfigurationResponse
     */
    'fonts'?: Array<Font>;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'helpEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'helpUrl'?: string;
    /**
     * 
     * @type {IconsResponse}
     * @memberof SubdomainConfigurationResponse
     */
    'icons'?: IconsResponse;
    /**
     * 
     * @type {FaviconsResponseOriginal}
     * @memberof SubdomainConfigurationResponse
     */
    'logo'?: FaviconsResponseOriginal;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'shortName': string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'subdomain': string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'themeColor'?: string;
    /**
     * 
     * @type {Array<SubdomainTenant>}
     * @memberof SubdomainConfigurationResponse
     */
    'tenants'?: Array<SubdomainTenant>;
    /**
     * 
     * @type {number}
     * @memberof SubdomainConfigurationResponse
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubdomainConfigurationResponse
     */
    'tenant': string;
}
/**
 * 
 * @export
 * @interface SubdomainTenant
 */
export interface SubdomainTenant {
    /**
     * 
     * @type {string}
     * @memberof SubdomainTenant
     */
    'tenant': string;
    /**
     * 
     * @type {boolean}
     * @memberof SubdomainTenant
     */
    'easyAccess': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubdomainTenant
     */
    'openRegistration': boolean;
}
/**
 * 
 * @export
 * @interface ValidationIssue
 */
export interface ValidationIssue {
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'message': string;
    /**
     * 
     * @type {Array<ValidationIssuePathInner>}
     * @memberof ValidationIssue
     */
    'path': Array<ValidationIssuePathInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationIssue
     */
    'code': string;
}
/**
 * @type ValidationIssuePathInner
 * @export
 */
export type ValidationIssuePathInner = number | string;

/**
 * 
 * @export
 * @interface ValidationIssues
 */
export interface ValidationIssues extends Array<ValidationIssue> {
}

/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create file slot
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileSlot: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('createFileSlot', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/slot`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create file slot
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileSlot(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedFileSlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileSlot(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileApi.createFileSlot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Create file slot
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileSlot(subdomain: string, options?: any): AxiosPromise<CreatedFileSlot> {
            return localVarFp.createFileSlot(subdomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Create file slot
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFileSlot(subdomain: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).createFileSlot(subdomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FontsApi - axios parameter creator
 * @export
 */
export const FontsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get fonts file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFonts: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getFonts', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/fonts`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FontsApi - functional programming interface
 * @export
 */
export const FontsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FontsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get fonts file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFonts(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFonts(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FontsApi.getFonts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FontsApi - factory interface
 * @export
 */
export const FontsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FontsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get fonts file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFonts(subdomain: string, options?: any): AxiosPromise<string> {
            return localVarFp.getFonts(subdomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FontsApi - object-oriented interface
 * @export
 * @class FontsApi
 * @extends {BaseAPI}
 */
export class FontsApi extends BaseAPI {
    /**
     * 
     * @summary Get fonts file
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FontsApi
     */
    public getFonts(subdomain: string, options?: AxiosRequestConfig) {
        return FontsApiFp(this.configuration).getFonts(subdomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImageGenerateApi - axios parameter creator
 * @export
 */
export const ImageGenerateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate image
         * @param {string} subdomain 
         * @param {GenerateImagesRequest} [generateImagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateImages: async (subdomain: string, generateImagesRequest?: GenerateImagesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('generateImages', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/image-generate`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateImagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageGenerateApi - functional programming interface
 * @export
 */
export const ImageGenerateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageGenerateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate image
         * @param {string} subdomain 
         * @param {GenerateImagesRequest} [generateImagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateImages(subdomain: string, generateImagesRequest?: GenerateImagesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedImages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateImages(subdomain, generateImagesRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ImageGenerateApi.generateImages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ImageGenerateApi - factory interface
 * @export
 */
export const ImageGenerateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageGenerateApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate image
         * @param {string} subdomain 
         * @param {GenerateImagesRequest} [generateImagesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateImages(subdomain: string, generateImagesRequest?: GenerateImagesRequest, options?: any): AxiosPromise<GeneratedImages> {
            return localVarFp.generateImages(subdomain, generateImagesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageGenerateApi - object-oriented interface
 * @export
 * @class ImageGenerateApi
 * @extends {BaseAPI}
 */
export class ImageGenerateApi extends BaseAPI {
    /**
     * 
     * @summary Generate image
     * @param {string} subdomain 
     * @param {GenerateImagesRequest} [generateImagesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageGenerateApi
     */
    public generateImages(subdomain: string, generateImagesRequest?: GenerateImagesRequest, options?: AxiosRequestConfig) {
        return ImageGenerateApiFp(this.configuration).generateImages(subdomain, generateImagesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (subdomain: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('deleteLink', 'subdomain', subdomain)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLink', 'id', id)
            const localVarPath = `/{subdomain}/links/{id}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLink: async (subdomain: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getLink', 'subdomain', subdomain)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLink', 'id', id)
            const localVarPath = `/{subdomain}/links/{id}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get links
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getLinks', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/links`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create link
         * @param {string} subdomain 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLink: async (subdomain: string, postLinkRequest?: PostLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('postLink', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/links`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLink: async (subdomain: string, id: string, postLinkRequest?: PostLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('putLink', 'subdomain', subdomain)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putLink', 'id', id)
            const localVarPath = `/{subdomain}/links/{id}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(subdomain: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLink(subdomain, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksApi.deleteLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get single link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLink(subdomain: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Link>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLink(subdomain, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksApi.getLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get links
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinks(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Link>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinks(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksApi.getLinks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create link
         * @param {string} subdomain 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLink(subdomain: string, postLinkRequest?: PostLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Link>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLink(subdomain, postLinkRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksApi.postLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLink(subdomain: string, id: string, postLinkRequest?: PostLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Link>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLink(subdomain, id, postLinkRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksApi.putLink']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinksApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(subdomain: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteLink(subdomain, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLink(subdomain: string, id: string, options?: any): AxiosPromise<Link> {
            return localVarFp.getLink(subdomain, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get links
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks(subdomain: string, options?: any): AxiosPromise<Array<Link>> {
            return localVarFp.getLinks(subdomain, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create link
         * @param {string} subdomain 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLink(subdomain: string, postLinkRequest?: PostLinkRequest, options?: any): AxiosPromise<Link> {
            return localVarFp.postLink(subdomain, postLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update link
         * @param {string} subdomain 
         * @param {string} id 
         * @param {PostLinkRequest} [postLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLink(subdomain: string, id: string, postLinkRequest?: PostLinkRequest, options?: any): AxiosPromise<Link> {
            return localVarFp.putLink(subdomain, id, postLinkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
    /**
     * 
     * @summary Delete link
     * @param {string} subdomain 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public deleteLink(subdomain: string, id: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).deleteLink(subdomain, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single link
     * @param {string} subdomain 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public getLink(subdomain: string, id: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).getLink(subdomain, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get links
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public getLinks(subdomain: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).getLinks(subdomain, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create link
     * @param {string} subdomain 
     * @param {PostLinkRequest} [postLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public postLink(subdomain: string, postLinkRequest?: PostLinkRequest, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).postLink(subdomain, postLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update link
     * @param {string} subdomain 
     * @param {string} id 
     * @param {PostLinkRequest} [postLinkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public putLink(subdomain: string, id: string, postLinkRequest?: PostLinkRequest, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).putLink(subdomain, id, postLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinksReorderApi - axios parameter creator
 * @export
 */
export const LinksReorderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reorder links
         * @param {string} subdomain 
         * @param {Array<ReorderLinksRequestInner>} [reorderLinksRequestInner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderLinks: async (subdomain: string, reorderLinksRequestInner?: Array<ReorderLinksRequestInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('reorderLinks', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/links-reorder`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderLinksRequestInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinksReorderApi - functional programming interface
 * @export
 */
export const LinksReorderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinksReorderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reorder links
         * @param {string} subdomain 
         * @param {Array<ReorderLinksRequestInner>} [reorderLinksRequestInner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderLinks(subdomain: string, reorderLinksRequestInner?: Array<ReorderLinksRequestInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderLinks(subdomain, reorderLinksRequestInner, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LinksReorderApi.reorderLinks']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LinksReorderApi - factory interface
 * @export
 */
export const LinksReorderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinksReorderApiFp(configuration)
    return {
        /**
         * 
         * @summary Reorder links
         * @param {string} subdomain 
         * @param {Array<ReorderLinksRequestInner>} [reorderLinksRequestInner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderLinks(subdomain: string, reorderLinksRequestInner?: Array<ReorderLinksRequestInner>, options?: any): AxiosPromise<string> {
            return localVarFp.reorderLinks(subdomain, reorderLinksRequestInner, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinksReorderApi - object-oriented interface
 * @export
 * @class LinksReorderApi
 * @extends {BaseAPI}
 */
export class LinksReorderApi extends BaseAPI {
    /**
     * 
     * @summary Reorder links
     * @param {string} subdomain 
     * @param {Array<ReorderLinksRequestInner>} [reorderLinksRequestInner] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksReorderApi
     */
    public reorderLinks(subdomain: string, reorderLinksRequestInner?: Array<ReorderLinksRequestInner>, options?: AxiosRequestConfig) {
        return LinksReorderApiFp(this.configuration).reorderLinks(subdomain, reorderLinksRequestInner, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManifestApi - axios parameter creator
 * @export
 */
export const ManifestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get manifest file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifest: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getManifest', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/manifest`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManifestApi - functional programming interface
 * @export
 */
export const ManifestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManifestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get manifest file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManifest(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Manifest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManifest(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ManifestApi.getManifest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ManifestApi - factory interface
 * @export
 */
export const ManifestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManifestApiFp(configuration)
    return {
        /**
         * 
         * @summary Get manifest file
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManifest(subdomain: string, options?: any): AxiosPromise<Manifest> {
            return localVarFp.getManifest(subdomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManifestApi - object-oriented interface
 * @export
 * @class ManifestApi
 * @extends {BaseAPI}
 */
export class ManifestApi extends BaseAPI {
    /**
     * 
     * @summary Get manifest file
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManifestApi
     */
    public getManifest(subdomain: string, options?: AxiosRequestConfig) {
        return ManifestApiFp(this.configuration).getManifest(subdomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServerSubdomainConfigurationApi - axios parameter creator
 * @export
 */
export const ServerSubdomainConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get subdomain configuration
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSubdomainConfiguration: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getServerSubdomainConfiguration', 'subdomain', subdomain)
            const localVarPath = `/server/configuration/{subdomain}`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServerSubdomainConfigurationApi - functional programming interface
 * @export
 */
export const ServerSubdomainConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServerSubdomainConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get subdomain configuration
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServerSubdomainConfiguration(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdomainConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServerSubdomainConfiguration(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServerSubdomainConfigurationApi.getServerSubdomainConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ServerSubdomainConfigurationApi - factory interface
 * @export
 */
export const ServerSubdomainConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServerSubdomainConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get subdomain configuration
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerSubdomainConfiguration(subdomain: string, options?: any): AxiosPromise<SubdomainConfigurationResponse> {
            return localVarFp.getServerSubdomainConfiguration(subdomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServerSubdomainConfigurationApi - object-oriented interface
 * @export
 * @class ServerSubdomainConfigurationApi
 * @extends {BaseAPI}
 */
export class ServerSubdomainConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Get subdomain configuration
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerSubdomainConfigurationApi
     */
    public getServerSubdomainConfiguration(subdomain: string, options?: AxiosRequestConfig) {
        return ServerSubdomainConfigurationApiFp(this.configuration).getServerSubdomainConfiguration(subdomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get settings
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (subdomain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subdomain' is not null or undefined
            assertParamExists('getSettings', 'subdomain', subdomain)
            const localVarPath = `/{subdomain}/settings`
                .replace(`{${"subdomain"}}`, encodeURIComponent(String(subdomain)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get settings
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(subdomain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(subdomain, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.getSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get settings
         * @param {string} subdomain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(subdomain: string, options?: any): AxiosPromise<Setting> {
            return localVarFp.getSettings(subdomain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get settings
     * @param {string} subdomain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettings(subdomain: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettings(subdomain, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubdomainConfigurationApi - axios parameter creator
 * @export
 */
export const SubdomainConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete configuration
         * @param {DeleteSubdomainConfigurationRequest} [deleteSubdomainConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubdomainConfiguration: async (deleteSubdomainConfigurationRequest?: DeleteSubdomainConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSubdomainConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subdomain configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubdomainConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubdomainConfiguration: async (configurationRequest?: ConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSubdomainConfiguration: async (configurationRequest?: ConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuthentication required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubdomainConfigurationApi - functional programming interface
 * @export
 */
export const SubdomainConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubdomainConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete configuration
         * @param {DeleteSubdomainConfigurationRequest} [deleteSubdomainConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest?: DeleteSubdomainConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubdomainConfigurationApi.deleteSubdomainConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get subdomain configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubdomainConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubdomainConfigurationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubdomainConfiguration(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubdomainConfigurationApi.getSubdomainConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdomainConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSubdomainConfiguration(configurationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubdomainConfigurationApi.postSubdomainConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubdomainConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSubdomainConfiguration(configurationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubdomainConfigurationApi.putSubdomainConfiguration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SubdomainConfigurationApi - factory interface
 * @export
 */
export const SubdomainConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubdomainConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete configuration
         * @param {DeleteSubdomainConfigurationRequest} [deleteSubdomainConfigurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest?: DeleteSubdomainConfigurationRequest, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subdomain configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubdomainConfiguration(options?: any): AxiosPromise<Array<SubdomainConfigurationResponse>> {
            return localVarFp.getSubdomainConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: any): AxiosPromise<SubdomainConfigurationResponse> {
            return localVarFp.postSubdomainConfiguration(configurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create subdomain configuration
         * @param {ConfigurationRequest} [configurationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: any): AxiosPromise<SubdomainConfigurationResponse> {
            return localVarFp.putSubdomainConfiguration(configurationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubdomainConfigurationApi - object-oriented interface
 * @export
 * @class SubdomainConfigurationApi
 * @extends {BaseAPI}
 */
export class SubdomainConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Delete configuration
     * @param {DeleteSubdomainConfigurationRequest} [deleteSubdomainConfigurationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdomainConfigurationApi
     */
    public deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest?: DeleteSubdomainConfigurationRequest, options?: AxiosRequestConfig) {
        return SubdomainConfigurationApiFp(this.configuration).deleteSubdomainConfiguration(deleteSubdomainConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subdomain configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdomainConfigurationApi
     */
    public getSubdomainConfiguration(options?: AxiosRequestConfig) {
        return SubdomainConfigurationApiFp(this.configuration).getSubdomainConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create subdomain configuration
     * @param {ConfigurationRequest} [configurationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdomainConfigurationApi
     */
    public postSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: AxiosRequestConfig) {
        return SubdomainConfigurationApiFp(this.configuration).postSubdomainConfiguration(configurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create subdomain configuration
     * @param {ConfigurationRequest} [configurationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubdomainConfigurationApi
     */
    public putSubdomainConfiguration(configurationRequest?: ConfigurationRequest, options?: AxiosRequestConfig) {
        return SubdomainConfigurationApiFp(this.configuration).putSubdomainConfiguration(configurationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



